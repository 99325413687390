import React from "react";
import Image from "material-ui-image";
import clsx from "clsx";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import Box from "@material-ui/core/Box";
import { excerpt } from "../../../utils/text";

const useStyles = makeStyles((theme) => ({
  date: {
    fontWeight: 400,
    color: theme.palette.common.white,
  },
  title: {
    fontWeight: 700,
    color: theme.palette.common.white,
  },
  fisica: {
    backgroundColor: "#55b3c9",
  },
  quimica: {
    backgroundColor: "#8a4077",
  },
  biologia: {
    backgroundColor: "#579078",
  },
  ciencias: {
    backgroundColor: "#d96c64",
  },
  fisicaText: {
    color: "#48438a",
  },
  quimicaText: {
    color: "#8a4077",
  },
  biologiaText: {
    color: "#72cbde",
  },
  cienciasText: {
    color: "#48438a",
  },
  card: {
    minWidth: "268px",
    minHeigh: "106px",
    borderRadius: "10px",
  },
}));

const formatDate = (date) => {
  return moment(date).format("dddd - DD/MM");
};

const CarouselCard = (props) => {
  const { item } = props;
  const classes = useStyles();
  return (
    <>
      {item && (
        <Box
          className={classes.card}
          display="flex"
          flexDirection="row"
          p={3}
          classes={{
            root: clsx({
              [classes.fisica]: item.subject === "Física",
              [classes.quimica]: item.subject === "Química",
              [classes.biologia]: item.subject === "Biologia",
              [classes.ciencias]: item.subject === "Ciências",
            }),
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            flexWrap="nowrap"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Typography
              gutterBottom
              variant="subtitle2"
              className={classes.date}
            >
              {formatDate(item.createdAt)}
            </Typography>

            <Typography
              gutterBottom
              variant="subtitle1"
              className={classes.title}
              noWrap
            >
              {excerpt(item.lectureName, 40)}
            </Typography>
            <Typography
              variant="body1"
              classes={{
                root: clsx({
                  [classes.fisicaText]: item.discipline === "Física",
                  [classes.quimicaText]: item.discipline === "Química",
                  [classes.biologiaText]: item.discipline === "Biologia",
                  [classes.cienciasText]: item.discipline === "Ciências",
                }),
              }}
            >
              {item.discipline}
            </Typography>
          </Box>
          <Box>
            <div style={{ width: "100px" }}>
              <Image
                cover={true}
                aspectRatio={1 / 1}
                style={{ borderRadius: "10px" }}
                imageStyle={{ borderRadius: "10px" }}
                alt={item.lectureName}
                src="/images/marker.png"
              />
            </div>
          </Box>
        </Box>
      )}
    </>
  );
};

CarouselCard.propTypes = {
  item: PropTypes.object.isRequired,
};

export default CarouselCard;
