import { intersection } from "lodash";
import { store } from "../index";

export function isLoggedIn() {
  const userLogin = store.getState().userLogin;
  const { userInfo } = userLogin;
  return !!userInfo;
}

export function isArrayWithLength(arr) {
  return Array.isArray(arr) && arr.length;
}

export function getAllowedRoutes(routes) {
  let roles = {};
  const userInfo = localStorage.getItem("userInfo");
  if (userInfo) roles = JSON.parse(localStorage.getItem("userInfo")).role;

  return routes.filter(({ permission }) => {
    if (!permission) return true;
    else if (!isArrayWithLength(permission)) return true;
    else return intersection(permission, roles).length;
  });
}
