import axios from "axios";

// Action types
const PERFORMANCE_LIST_REQUEST = "PERFORMANCE_LIST_REQUEST";
const PERFORMANCE_LIST_SUCCESS = "PERFORMANCE_LIST_SUCCESS";
const PERFORMANCE_LIST_FAIL = "PERFORMANCE_LIST_FAIL";

// Reducer
export const reportReducer = (state = { performanceData: [] }, action) => {
  switch (action.type) {
    case PERFORMANCE_LIST_REQUEST:
      return { loading: true, performanceData: [] };
    case PERFORMANCE_LIST_SUCCESS:
      return { loading: false, performanceData: action.payload };
    case PERFORMANCE_LIST_FAIL:
      return { loading: false, performanceData: [], error: action.payload };

    default:
      return state;
  }
};

// Action Creators
export const listPerformance = () => async (dispatch) => {
  try {
    dispatch({ type: PERFORMANCE_LIST_REQUEST });
    //const {data} = await axios.get(`${apiUrl}/admin?page=${page}&limit=${limit}&filter=${keyword}`);

    const userInfo = localStorage.getItem("userInfo");
    const { jwt_token } = JSON.parse(userInfo);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${jwt_token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_API}/content/analytics`,
      config
    );
    dispatch({
      type: PERFORMANCE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PERFORMANCE_LIST_FAIL,
      payload: error,
    });
  }
};
