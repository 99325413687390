import React from "react";
import { useTranslation } from "react-i18next";
import NotFound from "../components/common/notFound/NotFound";

const NotFoundScreen = () => {
  const { t } = useTranslation("translation");
  const formConfigI18n = {
    title: t("common.notFoundPage.title"),
    message: t("common.notFoundPage.message"),
    homeButton: t("common.notFoundPage.homeButton"),
  };

  return (
    <NotFound
      title={formConfigI18n.title}
      message={formConfigI18n.message}
      image="/images/notfound.png"
      homeButton={formConfigI18n.homeButton}
    />
  );
};

export { NotFoundScreen };
