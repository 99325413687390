import ApiService from "../../../services/ApiService";
import { showSnackbar } from "../uiReducer/uiReducer";
import * as XLSX from "xlsx";
// Action types
const STUDENT_LIST_REQUEST = "STUDENT_LIST_REQUEST";
const STUDENT_LIST_SUCCESS = "STUDENT_LIST_SUCCESS";
const STUDENT_LIST_FAIL = "STUDENT_LIST_FAIL";

const STUDENT_ADD_REQUEST = "STUDENT_ADD_REQUEST";
const STUDENT_ADD_SUCCESS = "STUDENT_ADD_SUCCESS";
const STUDENT_ADD_FAIL = "STUDENT_ADD_FAIL";

const STUDENT_UPDATE_REQUEST = "STUDENT_UPDATE_REQUEST";
const STUDENT_UPDATE_SUCCESS = "STUDENT_UPDATE_SUCCESS";
const STUDENT_UPDATE_FAIL = "STUDENT_UPDATE_FAIL";

const STUDENT_DELETE_REQUEST = "STUDENT_DELETE_REQUEST";
const STUDENT_DELETE_SUCCESS = "STUDENT_DELETE_SUCCESS";
const STUDENT_DELETE_FAIL = "STUDENT_DELETE_FAIL";

const STUDENT_MULTIPLE_DELETE_REQUEST = "STUDENT_MULTIPLE_REQUEST";
const STUDENT_MULTIPLE_SUCCESS = "STUDENT_MULTIPLE_SUCCESS";
const STUDENT_MULTIPLE_FAIL = "STUDENT_MULTIPLE_FAIL";

const STUDENT_ADD_BATCH_REQUEST = "STUDENT_ADD_BATCH_REQUEST";
const STUDENT_ADD_BATCH_SUCCESS = "STUDENT_ADD_BATCH_SUCCESS";
const STUDENT_ADD_BATCH_FAIL = "STUDENT_ADD_BATCH_FAIL";

// Reducer
export const studentReducer = (state = { student: [] }, action) => {
  switch (action.type) {
    case STUDENT_LIST_REQUEST:
      return { loading: true, student: [...state.student] };
    case STUDENT_LIST_SUCCESS:
      return { loading: false, student: [...action.payload] };
    case STUDENT_LIST_FAIL:
      return {
        loading: false,
        student: state.student,
        error: action.payload,
      };
    case STUDENT_ADD_REQUEST:
      return { loading: true, student: state.student };
    case STUDENT_ADD_SUCCESS:
      return { loading: false, student: state.student };
    case STUDENT_ADD_FAIL:
      return {
        loading: false,
        student: state.student,
        error: action.payload,
      };
    case STUDENT_UPDATE_REQUEST:
      return { loading: true, student: state.student };
    case STUDENT_UPDATE_SUCCESS:
      return { loading: false, student: state.student };
    case STUDENT_UPDATE_FAIL:
      return {
        loading: false,
        student: state.student,
        error: action.payload,
      };
    case STUDENT_DELETE_REQUEST:
      return { loading: true, student: state.student };
    case STUDENT_DELETE_SUCCESS:
      return { loading: false, student: [...state.student, ...action.payload] };
    case STUDENT_DELETE_FAIL:
      return {
        loading: false,
        student: [...state.student],
        error: action.payload,
      };
    default:
      return state;
  }
};

// Action Creators
export const listStudent = () => async (dispatch) => {
  try {
    dispatch({ type: STUDENT_LIST_REQUEST });

    const { data } = await ApiService.get(`/users/students`);

    dispatch({
      type: STUDENT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STUDENT_LIST_FAIL,
      payload: error,
    });
  }
};

export const addStudent =
  (name, registrationCode, schoolClass) => async (dispatch) => {
    try {
      dispatch({ type: STUDENT_ADD_REQUEST });
      const classData = schoolClass.split("-");
      const ano = classData[1];
      const idRoom = classData[classData.length - 1];
      const user = {
        idRoom,
        nameStudent: name,
        enrollmentStudent: registrationCode,
        serie: ano,
      };
      const { data } = await ApiService.post(`/users/student`, user);
      dispatch({
        type: STUDENT_ADD_SUCCESS,
        payload: data,
      });
      dispatch(
        showSnackbar({
          message: "Estudante adicionado com sucesso!",
          severity: "success",
          autoHideDuration: 3000,
        })
      );
    } catch (error) {
      dispatch({
        type: STUDENT_ADD_FAIL,
        payload: error,
      });
      dispatch(
        showSnackbar({
          message: "Ocorreu um erro ao adicionar o estudante!",
          severity: "error",
          autoHideDuration: 3000,
        })
      );
    }
  };

export const updateStudent =
  (id, idRoom, name, registrationCode, schoolClass, resetUniqueCode) =>
  async (dispatch) => {
    try {
      dispatch({ type: STUDENT_UPDATE_REQUEST });

      const classData = schoolClass.split("-");
      const tempIdRoom = classData.length >= 4 ? classData[3] : idRoom;

      const user = {
        idRoom: tempIdRoom,
        nameStudent: name,
        enrollmentStudent: registrationCode,
        uniqueCode: resetUniqueCode, // Use 1 para solicitar um novo unique code
      };

      const { data } = await ApiService.put(`/users/student/${id}`, user);
      dispatch({
        type: STUDENT_UPDATE_SUCCESS,
        payload: data,
      });
      dispatch(
        showSnackbar({
          message: "Estudante atualizado com sucesso!",
          severity: "success",
          autoHideDuration: 3000,
        })
      );
    } catch (error) {
      dispatch({
        type: STUDENT_UPDATE_FAIL,
        payload: error,
      });
      dispatch(
        showSnackbar({
          message: "Ocorreu um erro ao atualizar o estudante!",
          severity: "error",
          autoHideDuration: 3000,
        })
      );
    }
  };

export const deleteStudent = (student) => async (dispatch) => {
  try {
    dispatch({ type: STUDENT_DELETE_REQUEST });

    const { data } = await ApiService.delete(`/users/student/${student.id}`);

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteMultipleStudent = (students) => async (dispatch) => {
  const ids = students.map((student) => student.id);

  try {
    dispatch({ type: STUDENT_MULTIPLE_DELETE_REQUEST });

    const { data } = await ApiService.delete(
      `users/students/${ids.toString()}`
    );

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addBatchStudent =
  (spreadsheet, schoolClass) => async (dispatch) => {
    try {
      dispatch({ type: STUDENT_ADD_REQUEST });
      const classData = schoolClass.split("-");
      const idRoom = classData[classData.length - 1];
      const idClient = JSON.parse(localStorage.getItem("userInfo")).id;
      const formData = new FormData();
      formData.append("file", spreadsheet[0]);
      formData.append("idRoom", idRoom);
      formData.append("idClient", idClient);

      const userInfo = localStorage.getItem("userInfo");
      const { jwt_token } = JSON.parse(userInfo);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${jwt_token}`,
        },
      };

      const data = await ApiService.post(
        `${process.env.REACT_APP_BASE_API}/users/students/upload`,
        formData,
        config
      );

      let finalHeaders = ["code", "name", "status"];
      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.json_to_sheet(data.data, {
        header: finalHeaders,
      });

      XLSX.utils.book_append_sheet(wb, ws, `SheetJS`);
      XLSX.writeFile(wb, "result.xlsx");

      dispatch({
        type: STUDENT_ADD_SUCCESS,
        payload: data.data,
      });

      dispatch(
        showSnackbar({
          message: "Estudantes adicionados, verifique a planilha de status!",
          severity: "success",
          autoHideDuration: 5000,
        })
      );
    } catch (error) {
      dispatch({
        type: STUDENT_ADD_FAIL,
        payload: error,
      });

      dispatch(
        showSnackbar({
          message:
            "Ocorreu um erro ao adicionar alunos, utilize a planilha de modelo!",
          severity: "error",
          autoHideDuration: 5000,
        })
      );
    }
  };
