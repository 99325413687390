import ApiService from "./../services/ApiService";

// Action types
const PERFORMANCE_INDICATORS_REQUEST = "PERFORMANCE_INDICATORS_REQUEST";
const PERFORMANCE_INDICATORS_SUCCESS = "PERFORMANCE_INDICATORS_SUCCESS";
const PERFORMANCE_INDICATORS_FAIL = "PERFORMANCE_INDICATORS_FAIL";

// Reducer
export const performanceIndicatorsReducer = (state = {}, action) => {
  switch (action.type) {
    case PERFORMANCE_INDICATORS_REQUEST:
      return { loading: true, performance: [] };
    case PERFORMANCE_INDICATORS_SUCCESS:
      return { loading: false, performance: action.payload };
    case PERFORMANCE_INDICATORS_FAIL:
      return { loading: false, performance: [], error: action.payload };
    default:
      return state;
  }
};
// Action Creators

export const listInteractionsPerformance =
  (filter = "isFinished") =>
  async (dispatch) => {
    try {
      dispatch({ type: PERFORMANCE_INDICATORS_REQUEST });
      //const {data} = await axios.get(`${apiUrl}/admin?page=${page}&limit=${limit}&filter=${keyword}`);
      const { data } = await ApiService.get(
        //`${process.env.REACT_APP_BASE_API}/users/professors`,
        `https://61535a013f4c4300171592fb.mockapi.io/performance1?filter=${filter}`
      );
      dispatch({
        type: PERFORMANCE_INDICATORS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PERFORMANCE_INDICATORS_FAIL,
        payload: error,
      });
    }

    console.log(
      `https://61535a013f4c4300171592fb.mockapi.io/performance1?filter=${filter}`
    );
  };
