import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PersonIcon from "@material-ui/icons/Person";
import SchoolIcon from "@material-ui/icons/School";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { PlayIcon } from "../../../icons/CustomIcons";
import { studentsActive, teachersActive } from "../../../store";
import Carousel from "./Carousel";
import ShortCut from "./ShortCut";
import DisplayNumber from "./DisplayNumber";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  title: {
    fontWeight: 700,
  },
  carousel: {
    marginTop: theme.spacing(3),
  },
  carouselButton: {
    background: theme.palette.custom.color_1,
  },
}));

const Admin = ({ match }) => {
  const dispatch = useDispatch();
  const students = useSelector((state) => state.studentsActive);
  const teachers = useSelector((state) => state.teachersActive);
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const shortCutItens = [
    {
      idContent: 1,
      title: t("dashboard.client.shortcut_1"),
      icon: <SchoolIcon color="primary" fontSize="large" />,
      link: "/app/schoolClass",
      boxColor: theme.palette.custom.color_2,
      textColor: theme.palette.common.white,
      avatarColor: theme.palette.custom.color_1,
    },
    {
      idContent: 2,
      title: t("dashboard.client.shortcut_2"),
      icon: <PersonAddIcon color="primary" fontSize="large" />,
      link: "/app/teacher",
      boxColor: theme.palette.custom.color_1,
      textColor: theme.palette.common.white,
      avatarColor: theme.palette.custom.color_3,
    },
    {
      idContent: 3,
      title: t("dashboard.client.shortcut_3"),
      icon: <PersonAddIcon color="primary" fontSize="large" />,
      link: "/app/student",
      boxColor: theme.palette.custom.color_3,
      textColor: theme.palette.common.white,
      avatarColor: theme.palette.custom.color_1,
    },
    {
      idContent: 4,
      title: t("dashboard.client.shortcut_4"),
      icon: <PlayIcon color="primary" fontSize="large" />,
      link: "/app/faq",
      boxColor: theme.palette.custom.color_2,
      textColor: theme.palette.common.white,
      avatarColor: theme.palette.custom.color_1,
    },
  ];

  const displayNumbersItens = [
    {
      idContent: 1,
      title: t("dashboard.admin.teachers"),
      value: !teachers.loading && teachers.teachersActive,
      icon: <SchoolIcon color="primary" fontSize="large" />,
      boxColor: theme.palette.custom.color_2,
      textColor: theme.palette.common.white,
      avatarColor: theme.palette.custom.color_1,
    },
    {
      idContent: 2,
      title: t("dashboard.admin.students"),
      value: !students.loading && students.studentsActive,
      icon: <PersonAddIcon color="primary" fontSize="large" />,
      boxColor: theme.palette.custom.color_2,
      textColor: theme.palette.common.white,
      avatarColor: theme.palette.custom.color_1,
    },
  ];

  useEffect(() => {
    dispatch(studentsActive());
    dispatch(teachersActive());
  }, [dispatch]);

  return (
    <Box className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {!isMobile ? (
            <Carousel
              // setCarouselIndex={setCarouselIndexOne}
              // slideIndex={carouselIndexOne}
              items={shortCutItens}
              cellSpacing={20}
              cardType="ShortCut"
              externalControl={false}
            />
          ) : (
            <Grid item xs={12} sm={12}>
              <Box
                display="flex"
                flexDirection="row"
                width="100%"
                flexWrap="nowrap"
              >
                <Box
                  maxWidth="268px"
                  width="25%"
                  style={{
                    height: "8.875rem",
                    marginRight: 10,
                  }}
                >
                  <ShortCut
                    title={t("dashboard.admin.shortcut_1")}
                    icon={<SchoolIcon color="primary" fontSize="large" />}
                    link="/app/contractor"
                    boxColor={theme.palette.custom.color_2}
                    textColor={theme.palette.common.white}
                    avatarColor={theme.palette.custom.color_1}
                  />
                </Box>
                <Box
                  maxWidth="268px"
                  width="25%"
                  style={{
                    height: "8.875rem",
                    marginRight: 10,
                  }}
                >
                  <ShortCut
                    title={t("dashboard.admin.shortcut_2")}
                    icon={<PersonAddIcon color="primary" fontSize="large" />}
                    link="/app/register-content"
                    boxColor={theme.palette.custom.color_1}
                    textColor={theme.palette.common.white}
                    avatarColor={theme.palette.custom.color_3}
                  />
                </Box>
                <Box
                  maxWidth="268px"
                  width="25%"
                  style={{
                    height: "8.875rem",
                    marginRight: 10,
                  }}
                >
                  <ShortCut
                    title={t("dashboard.admin.shortcut_3")}
                    icon={<PersonAddIcon color="primary" fontSize="large" />}
                    link="/app/my-content"
                    boxColor={theme.palette.custom.color_3}
                    textColor={theme.palette.common.white}
                    avatarColor={theme.palette.custom.color_1}
                  />
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          {!isMobile ? (
            <Carousel
              // setCarouselIndex={setCarouselIndexOne}
              // slideIndex={carouselIndexOne}
              items={displayNumbersItens}
              cellSpacing={20}
              cardType="DisplayNumber"
              externalControl={false}
            />
          ) : (
            <Grid item xs={12} sm={12}>
              <Box
                display="flex"
                flexDirection="row"
                width="100%"
                flexWrap="nowrap"
              >
                <Box
                  maxWidth="268px"
                  width="25%"
                  style={{
                    height: "8.875rem",
                    marginRight: 10,
                  }}
                >
                  <DisplayNumber
                    title={t("dashboard.admin.teachers")}
                    value={!teachers.loading && teachers.teachersActive}
                    icon={<SchoolIcon color="primary" fontSize="large" />}
                    boxColor={theme.palette.custom.color_2}
                    textColor={theme.palette.common.white}
                    avatarColor={theme.palette.custom.color_1}
                  />
                </Box>
                <Box
                  maxWidth="268px"
                  width="25%"
                  style={{
                    height: "8.875rem",
                    marginRight: 10,
                  }}
                >
                  <DisplayNumber
                    title={t("dashboard.admin.students")}
                    value={!students.loading && students.studentsActive}
                    icon={<PersonIcon color="primary" fontSize="large" />}
                    boxColor={theme.palette.custom.color_2}
                    textColor={theme.palette.common.white}
                    avatarColor={theme.palette.custom.color_1}
                  />
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Admin;
