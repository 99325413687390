import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { DatePicker } from "@material-ui/pickers";
import ReactHookFormRadio from "../../../components/common/form/ReactHookFormRadio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { clientSchema } from "./schema/client";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  buttonAdd: {
    marginLeft: theme.spacing(1),
  },
}));

const Add = (props) => {
  const classes = useStyles();
  const {
    isEnabledFormAddClient,
    addHandler,
    loading,
    show,
    translateValidation,
  } = props;
  const { register, handleSubmit, errors, reset, control } = useForm({
    resolver: yupResolver(clientSchema(translateValidation)),
  });
  const [expireContract, setExpireContract] = React.useState(new Date());

  const onsubmit = (data) => {
    reset();
    addHandler(data);
  };
  return (
    <Box component="span" display={isEnabledFormAddClient ? "none" : "block"}>
      <Paper elevation={1}>
        <Container maxWidth={false} className={classes.container}>
          <Typography
            className={classes.title}
            variant="h5"
            component="h1"
            gutterBottom
          >
            {translateValidation.title}
          </Typography>

          <form fullWidth onSubmit={handleSubmit(onsubmit)} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} lg={4}>
                <TextField
                  inputRef={register}
                  error={errors.name && true}
                  helperText={errors.name && errors.name.message}
                  id="name"
                  name="name"
                  label={translateValidation.name.label}
                  placeholder={translateValidation.name.placeholder}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={12} lg={4}>
                <TextField
                  inputRef={register}
                  error={errors.email && true}
                  helperText={errors.email && errors.email.message}
                  id="email"
                  name="email"
                  label={translateValidation.email.label}
                  placeholder={translateValidation.email.placeholder}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={12} lg={4}>
                <TextField
                  inputRef={register}
                  error={errors.fantasyName && true}
                  helperText={errors.fantasyName && errors.fantasyName.message}
                  id="fantasyName"
                  name="fantasyName"
                  label={translateValidation.fantasyName.label}
                  placeholder={translateValidation.fantasyName.placeholder}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={12} lg={4}>
                <TextField
                  inputRef={register}
                  error={errors.cnpj && true}
                  helperText={errors.cnpj && errors.cnpj.message}
                  id="cnpj"
                  name="cnpj"
                  label={translateValidation.cnpj.label}
                  placeholder={translateValidation.cnpj.placeholder}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <DatePicker
                  inputRef={register}
                  id="expireContract"
                  name="expireContract"
                  views={["year", "month", "date"]}
                  label={translateValidation.expireContract.label}
                  helperText={
                    translateValidation.expireContract.label.requiredErrorMsg
                  }
                  disablePast
                  openTo="year"
                  format="DD/MM/YYYY"
                  value={expireContract}
                  onChange={setExpireContract}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={4}>
                <ReactHookFormRadio
                  inputRef={register}
                  error={errors.contractType && true}
                  helperText={
                    errors.contractType && errors.contractType.message
                  }
                  id="contractType"
                  name="contractType"
                  label={translateValidation.contractType.label}
                  style={{ width: "100%" }}
                  control={control}
                  defaultValue=" P1 - Plano MENSAL Pro (B2B XPAR)"
                >
                  <FormControlLabel
                    value=" P1 - Plano MENSAL Pro (B2B XPAR)"
                    control={<Radio />}
                    label=" P1 - Plano MENSAL Pro (B2B XPAR)"
                  />
                  <FormControlLabel
                    value="P2 - Plano SEMESTRAL (B2B XPAR)"
                    control={<Radio />}
                    label="P2 - Plano SEMESTRAL (B2B XPAR)"
                  />
                  <FormControlLabel
                    value=" P3 - Plano ANUAL Pro (B2B XPAR)"
                    control={<Radio />}
                    label=" P3 - Plano ANUAL Pro (B2B XPAR)"
                  />
                </ReactHookFormRadio>
              </Grid>

              <Grid item xs={12} sm={12} lg={12}>
                <div className={classes.buttons}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    disabled={loading}
                    onClick={show}
                  >
                    {translateValidation.cancelButton}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.buttonAdd}
                    disabled={loading}
                  >
                    {translateValidation.confirmButton}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Paper>
    </Box>
  );
};

export default Add;
