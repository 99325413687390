import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ToggleYear from "./ToggleYear";
import TabPanel from "./TabPanel";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    flexGrow: 1,
  },
}));

const TabPanelYear = ({
  index,
  anos,
  register,
  control,
  errors,
  handleToggle,
  checked,
}) => {
  const classes = useStyles();
  return (
    <>
      <TabPanel
        //value={currentTab}
        index={index}
        //dir={theme.direction}
      >
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.root}
        >
          {anos &&
            anos?.map((ano, i) => (
              <ToggleYear
                ano={ano.ano}
                turmas={ano.turmas}
                checked={checked}
                handleToggle={handleToggle}
                key={i}
              />
            ))}
        </List>
      </TabPanel>
    </>
  );
};

export default TabPanelYear;
