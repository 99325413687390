import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { Controller } from "react-hook-form";
import React from "react";
import { FormHelperText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  icon: {
    fill: theme.palette.secondary.main,
  },
}));

const ReactHookFormSelect = ({
  name,
  label,
  control,
  defaultValue,
  inputRef,
  error,
  requiredErrorMsg,
  helperText,
  children,
  ...props
}) => {
  const labelId = `${name}-label`;
  const classes = useStyles();
  return (
    <FormControl {...props}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        as={
          <Select
            className={classes.icon}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
            inputRef={inputRef}
            labelId={labelId}
            label={label}
            error={error}
          >
            {children}
          </Select>
        }
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
export default ReactHookFormSelect;
