import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import List from "@material-ui/core/List";

const ClassList = ({ turmas, ano }) => {
  return (
    <List component="div" disablePadding>
      {turmas.map((turma) => {
        const key = `${turma.periodo}-${ano}-${turma.turma}-${turma.idRoom}`;
        return (
          <FormControlLabel
            key={key}
            value={key}
            control={<Radio />}
            label={turma.turma}
          />
        );
      })}
    </List>
  );
};

export default ClassList;
