import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

function init() {
  if (process.env.NODE_ENV === "production") {
    Sentry.init({
      dsn: "https://a1db818c383d447dadd8d1d2bbd3ecd4@o609497.ingest.sentry.io/5747058",
      integrations: [new Integrations.BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }
}

export default {
  init,
};
