import * as yup from "yup";

const teacherSchema = () => {
  const schema = yup.object().shape({
    name: yup
      .string()
      //.required(`${contentName.requiredErrorMsg}`),
      .required(),
    email: yup
      .string()
      .email()
      //.required(`${contentDescription.requiredErrorMsg}`),
      .required(),
    registerCode: yup
      .string()
      //.required(`${contentDescription.requiredErrorMsg}`),
      .required(),
    contentCreator: yup.bool().required(),
  });
  return schema;
};

export { teacherSchema };
