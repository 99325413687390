import axios from "axios";

const singleton = Symbol();
const singletonEnforcer = Symbol();

function readJWT() {
  const userInfo = localStorage.getItem("userInfo");
  const { jwt_token } = JSON.parse(userInfo);
  return jwt_token;
}

function readCurrentLanguage() {
  return localStorage.getItem("i18nextLng");
}

function isPublicRoute(route) {
  const publicRoutes = ["login", "sso", "user", "pass", "password"];

  return publicRoutes.includes(route);
}

class ApiService {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw new Error("Cannot construct singleton");
    }

    this.session = axios.create({
      baseURL: process.env.REACT_APP_BASE_API,
      timeout: 0,
      headers: {
        "Content-Type": "application/json",
        lang: readCurrentLanguage(),
      },
    });

    this.session.interceptors.request.use(
      function (config) {
        const path = config.url.split("/").pop();
        // Do something before request is sent
        if (!isPublicRoute(path))
          config.headers.Authorization = `Bearer ${readJWT()}`;

        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    this.session.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        console.log(error);
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        const path = error.response.config.url.split("/").pop();
        if (error.response.status === 401 && !isPublicRoute(path)) {
          localStorage.clear();
          window.location.reload();
        }
        return Promise.reject(error);
      }
    );
  }

  static get instance() {
    // Try to get an efficient singleton
    if (!this[singleton]) {
      this[singleton] = new ApiService(singletonEnforcer);
    }

    return this[singleton];
  }

  get = (...params) => this.session.get(...params);
  post = (...params) => this.session.post(...params);
  put = (...params) => this.session.put(...params);
  patch = (...params) => this.session.patch(...params);
  delete = (...params) => this.session.delete(...params);
  adapter = (...params) => this.session.adapter(...params);
  defaults = (...params) => this.session.defaults(...params);
}

export default ApiService.instance;
