import ApiService from "../services/ApiService";

// Action types
const CONTENT_MANAGER_LIST_REQUEST = "CONTENT_MANAGER_LIST_REQUEST";
const CONTENT_MANAGER_LIST_SUCCESS = "CONTENT_MANAGER_LIST_SUCCESS";
const CONTENT_MANAGER_LIST_FAIL = "CONTENT_MANAGER_LIST_FAIL";

const CONTENT_MANAGER_ADD_REQUEST = "CONTENT_MANAGER_ADD_REQUEST";
const CONTENT_MANAGER_ADD_SUCCESS = "CONTENT_MANAGER_ADD_SUCCESS";
const CONTENT_MANAGER_ADD_FAIL = "CONTENT_MANAGER_ADD_FAIL";

const CONTENT_MANAGER_UPDATE_REQUEST = "CONTENT_MANAGER_UPDATE_REQUEST";
const CONTENT_MANAGER_UPDATE_SUCCESS = "CONTENT_MANAGER_UPDATE_SUCCESS";
const CONTENT_MANAGER_UPDATE_FAIL = "CONTENT_MANAGER_UPDATE_FAIL";

const CONTENT_MANAGER_DELETE_REQUEST = "CONTENT_MANAGER_DELETE_REQUEST";
const CONTENT_MANAGER_DELETE_SUCCESS = "CONTENT_MANAGER_DELETE_SUCCESS";
const CONTENT_MANAGER_DELETE_FAIL = "CONTENT_MANAGER_DELETE_FAIL";

// Reducer
export const contentManagerReducer = (
  state = { contentManagers: [] },
  action
) => {
  switch (action.type) {
    case CONTENT_MANAGER_LIST_REQUEST:
      return { loading: true, contentManagers: [] };
    case CONTENT_MANAGER_LIST_SUCCESS:
      return { loading: false, contentManagers: action.payload };
    case CONTENT_MANAGER_LIST_FAIL:
      return { loading: false, contentManagers: [], error: action.payload };
    case CONTENT_MANAGER_ADD_REQUEST:
      return { loading: true, contentManagers: [] };
    case CONTENT_MANAGER_ADD_SUCCESS:
      return { loading: false, contentManagers: [action.payload] };
    case CONTENT_MANAGER_ADD_FAIL:
      return { loading: false, contentManagers: [], error: action.payload };
    case CONTENT_MANAGER_UPDATE_REQUEST:
      return { loading: true, contentManagers: [] };
    case CONTENT_MANAGER_UPDATE_SUCCESS:
      return { loading: false, contentManagers: [action.payload] };
    case CONTENT_MANAGER_UPDATE_FAIL:
      return { loading: false, contentManagers: [], error: action.payload };
    case CONTENT_MANAGER_DELETE_REQUEST:
      return { loading: true, contentManagers: [] };
    case CONTENT_MANAGER_DELETE_SUCCESS:
      return { loading: false, contentManagers: action.payload };
    case CONTENT_MANAGER_DELETE_FAIL:
      return { loading: false, contentManagers: [], error: action.payload };
    default:
      return state;
  }
};

// Action Creators
export const listContentManager = () => async (dispatch) => {
  try {
    dispatch({ type: CONTENT_MANAGER_LIST_REQUEST });
    //const {data} = await axios.get(`${apiUrl}/admin?page=${page}&limit=${limit}&filter=${keyword}`);
    const { data } = await ApiService.get(
      "https://600605a13698a80017de12be.mockapi.io/api/v1/ContentManager"
    );
    // const { data } = await axios.get(
    //   `${process.env.REACT_APP_BASE_API}/admin`
    // )
    dispatch({
      type: CONTENT_MANAGER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTENT_MANAGER_LIST_FAIL,
      payload: error,
    });
  }
};

export const addContentManager = (name, email, role) => async (dispatch) => {
  try {
    dispatch({ type: CONTENT_MANAGER_ADD_REQUEST });

    const user = {
      name,
      email,
      role,
    };

    //TODO After created the api change this
    const { data } = await ApiService.post(
      //`${process.env.REACT_APP_BASE_API}/admin/${id}`
      "https://600605a13698a80017de12be.mockapi.io/api/v1/ContentManager",
      user
    );
    dispatch({
      type: CONTENT_MANAGER_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTENT_MANAGER_ADD_FAIL,
      payload: error,
    });
  }
};

export const updateContentManager =
  (id, name, email, role) => async (dispatch) => {
    try {
      dispatch({ type: CONTENT_MANAGER_UPDATE_REQUEST });

      const user = {
        name,
        email,
        role,
      };

      //TODO After created the api change this
      const { data } = await ApiService.put(
        //`${process.env.REACT_APP_BASE_API}/admin/${id}`
        `https://600605a13698a80017de12be.mockapi.io/api/v1/ContentManager/${id}`,
        user
      );
      dispatch({
        type: CONTENT_MANAGER_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CONTENT_MANAGER_UPDATE_FAIL,
        payload: error,
      });
    }
  };

export const deleteContentManager = (admin) => async (dispatch) => {
  try {
    dispatch({ type: CONTENT_MANAGER_DELETE_REQUEST });

    //TODO After created the api change this
    const { data } = await ApiService.delete(
      //`${process.env.REACT_APP_BASE_API}/admin/${id}`
      `https://600605a13698a80017de12be.mockapi.io/api/v1/ContentManager/${admin.id}`
    );
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
