import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";

const CustomIconButton = withStyles((theme) => ({
  root: {
    borderRadius: "10px",
    "&& .MuiTouchRipple-child": {
      borderRadius: "10px",
      backgroundColor: theme.palette.secondary.main,
    },
  },
}))(IconButton);

export default CustomIconButton;
