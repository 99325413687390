import * as yup from "yup";

const contentFormSchema = yup.object().shape({
  schoolClass: yup.array().required().min(1).max(7),
  contentName: yup.string().required(),
  schoolGrade: yup.string().nullable().required(),
  // tags: yup.array().required().min(1).max(7),
  //discipline: yup.array().required().min(1).max(7),
});

export { contentFormSchema };
