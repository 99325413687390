import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Layout from "../../components/common/hoc/Layout";
import { UserRole } from "./../../config/userRole";
import Teacher from "./components/teacher";
import Client from "./components/client";
import Admin from "./components/admin";

const Dashboard = ({ match }) => {
  const { t } = useTranslation();
  const userLogin = useSelector((state) => state.userLogin);

  const getUserDashboard = (userRole) => {
    switch (userRole) {
      case UserRole.PROFESSOR:
        return <Teacher />;
      case UserRole.ADMINISTRADOR_CLIENTE:
        return <Client />;
      case UserRole.ADMINISTRADOR_MASTER:
        return <Admin />;
      default:
        return "";
    }
  };
  return (
    <Layout title={t("dashboard.title")}>
      {userLogin.userInfo && getUserDashboard(userLogin.userInfo.role[0])}
    </Layout>
  );
};

export { Dashboard };
