import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SchoolIcon from "@material-ui/icons/School";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { PlayIcon } from "../../../icons/CustomIcons";
import Carousel from "./Carousel";

import ShortCut from "./ShortCut";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  title: {
    fontWeight: 700,
  },
  carousel: {
    marginTop: theme.spacing(3),
  },
  carouselButton: {
    background: theme.palette.custom.color_1,
  },
}));

const Client = ({ match }) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const shortCutItens = [
    {
      title: t("dashboard.client.shortcut_1"),
      icon: <SchoolIcon color="primary" fontSize="large" />,
      link: "/app/schoolClass",
      boxColor: theme.palette.custom.color_2,
      textColor: theme.palette.common.white,
      avatarColor: theme.palette.custom.color_1,
    },
    {
      title: t("dashboard.client.shortcut_2"),
      icon: <PersonAddIcon color="primary" fontSize="large" />,
      link: "/app/teacher",
      boxColor: theme.palette.custom.color_1,
      textColor: theme.palette.common.white,
      avatarColor: theme.palette.custom.color_3,
    },
    {
      title: t("dashboard.client.shortcut_3"),
      icon: <PersonAddIcon color="primary" fontSize="large" />,
      link: "/app/student",
      boxColor: theme.palette.custom.color_3,
      textColor: theme.palette.common.white,
      avatarColor: theme.palette.custom.color_1,
    },
    {
      title: t("dashboard.client.shortcut_4"),
      icon: <PlayIcon color="primary" fontSize="large" />,
      link: "/app/faq",
      boxColor: theme.palette.custom.color_2,
      textColor: theme.palette.common.white,
      avatarColor: theme.palette.custom.color_1,
    },
  ];

  return (
    <Box className={classes.root}>
      <Grid container spacing={3}>
        {!isMobile ? (
          <Carousel
            // setCarouselIndex={setCarouselIndexOne}
            // slideIndex={carouselIndexOne}
            items={shortCutItens}
            cellSpacing={20}
            cardType="ShortCut"
            externalControl={false}
          />
        ) : (
          <Grid item xs={12} sm={12}>
            <Box
              display="flex"
              flexDirection="row"
              width="100%"
              flexWrap="nowrap"
            >
              <Box
                maxWidth="268px"
                width="25%"
                style={{
                  height: "8.875rem",
                  marginRight: 10,
                }}
              >
                <ShortCut
                  title={t("dashboard.client.shortcut_1")}
                  icon={<SchoolIcon color="primary" fontSize="large" />}
                  link="/app/schoolClass"
                  boxColor={theme.palette.custom.color_2}
                  textColor={theme.palette.common.white}
                  avatarColor={theme.palette.custom.color_1}
                />
              </Box>
              <Box
                maxWidth="268px"
                width="25%"
                style={{
                  height: "8.875rem",
                  marginRight: 10,
                }}
              >
                <ShortCut
                  title={t("dashboard.client.shortcut_2")}
                  icon={<PersonAddIcon color="primary" fontSize="large" />}
                  link="/app/teacher"
                  boxColor={theme.palette.custom.color_1}
                  textColor={theme.palette.common.white}
                  avatarColor={theme.palette.custom.color_3}
                />
              </Box>
              <Box
                maxWidth="268px"
                width="25%"
                style={{
                  height: "8.875rem",
                  marginRight: 10,
                }}
              >
                <ShortCut
                  title={t("dashboard.client.shortcut_3")}
                  icon={<PersonAddIcon color="primary" fontSize="large" />}
                  link="/app/student"
                  boxColor={theme.palette.custom.color_3}
                  textColor={theme.palette.common.white}
                  avatarColor={theme.palette.custom.color_1}
                />
              </Box>
              <Box
                maxWidth="268px"
                width="25%"
                style={{
                  height: "8.875rem",
                  marginRight: 10,
                }}
              >
                <ShortCut
                  title={t("dashboard.client.shortcut_4")}
                  icon={<PlayIcon color="primary" fontSize="large" />}
                  link="/app/faq"
                  boxColor={theme.palette.custom.color_2}
                  textColor={theme.palette.common.white}
                  avatarColor={theme.palette.custom.color_1}
                />
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

Client.propTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string,
  link: PropTypes.string.isRequired,
};

export default Client;
