import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Image from "material-ui-image";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import BarChartIcon from "@material-ui/icons/BarChart";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import Layout from "../components/common/hoc/Layout";
import { listInteractionDetails } from "../store/interactions";
import Paper from "@material-ui/core/Paper";
import CustomSpinner from "../components/common/Spinner/CustomSpinner";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { cartAdd, cartDelete } from "../store/duck/publish/cart";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    flexGrow: 1,
  },
  media: {
    height: 140,
  },
  container: {
    marginTop: "12px",
    marginLeft: "12px",
  },
  actionButtons: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const InteractionDetailsScreen = ({ match }) => {
  const classes = useStyles();
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const { items } = cart;
  const interactionDetails = useSelector((state) => state.interactionDetails);
  const { loading, error, interaction } = interactionDetails;
  const { id } = useParams();

  useEffect(() => {
    dispatch(listInteractionDetails(id));
  }, [dispatch, id, match]);

  const addToCart = (interaction) => {
    dispatch(cartAdd(interaction));
  };

  const removeItemCart = (interaction) => {
    dispatch(cartDelete(interaction));
  };

  const isItemExistIntheCart = (itemToFindId) => {
    return items.find(
      (old_item) => old_item.idContent === itemToFindId.idContent
    );
  };

  const downloadMarker = (imageUrl) => {
    if (imageUrl) window.location.href = imageUrl;
  };

  return (
    <>
      {loading ? (
        <CustomSpinner />
      ) : error ? (
        <h1>Error</h1>
      ) : (
        <Layout title={t("interactionDetails.title")}>
          <Container maxWidth={false} style={{ padding: "0" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} xl={4}>
                <Image
                  cover={true}
                  aspectRatio={4 / 6}
                  alt={interaction.contentName}
                  src={interaction.st_marker}
                />

                <video width="320" height="240" controls>
                  <source src={interaction.st_gif} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>

                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box p={1}>
                    <Typography
                      align="center"
                      variant="subtitle2"
                      component="h2"
                      color="textPrimary"
                      gutterBottom
                    >
                      {t("interactionDetails.markerInfo")}:
                    </Typography>
                  </Box>
                  <Box p={1}>
                    <IconButton
                      aria-label="download"
                      onClick={() => downloadMarker(interaction.st_marker)}
                    >
                      <CloudDownloadIcon aria-label="download" />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={7} xl={8}>
                <Paper elevation={1} style={{ padding: "12px" }}>
                  <Typography
                    variant="h4"
                    component="h1"
                    color="textPrimary"
                    gutterBottom
                  >
                    {interaction.contentName}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="h2"
                    color="textPrimary"
                    gutterBottom
                  >
                    {t("interactionDetails.goal")}:
                  </Typography>
                  <Typography
                    className={classes.description}
                    align="left"
                    variant="body1"
                    color="textSecondary"
                    component="p"
                  >
                    {interaction.contentDescription}
                  </Typography>
                  <Typography
                    variant="h6"
                    component="h4"
                    color="textPrimary"
                    gutterBottom
                  >
                    {t("interactionDetails.schoolLevel")}:
                  </Typography>
                  <List className={classes.root}>
                    <ListItem>
                      <ListItemAvatar>
                        <BarChartIcon />
                      </ListItemAvatar>
                      <ListItemText primary={interaction.schoolLevel} />
                    </ListItem>
                  </List>

                  {!isItemExistIntheCart(interaction) ? (
                    <Button
                      className={classes.actionButtons}
                      onClick={() => addToCart(interaction)}
                      size="small"
                      variant="outlined"
                      color="primary"
                      startIcon={<AddIcon />}
                    >
                      {t("interactionDetails.addButtom")}
                    </Button>
                  ) : (
                    <Button
                      className={classes.actionButtons}
                      onClick={() => {
                        removeItemCart(interaction);
                      }}
                      size="small"
                      variant="outlined"
                      color="secondary"
                      startIcon={<DeleteIcon />}
                    >
                      {t("interactionDetails.removeButton")}
                    </Button>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Layout>
      )}
    </>
  );
};

export { InteractionDetailsScreen };
