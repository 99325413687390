import ApiService from "../../../services/ApiService";

// Action types
const SCHOOL_CLASS_LIST_REQUEST = "SCHOOL_CLASS_LIST_REQUEST";
const SCHOOL_CLASS_LIST_SUCCESS = "SCHOOL_CLASS_LIST_SUCCESS";
const SCHOOL_CLASS_LIST_FAIL = "SCHOOL_CLASS_LIST_FAIL";

const SCHOOL_CLASS_ADD_REQUEST = "SCHOOL_CLASS_ADD_REQUEST";
const SCHOOL_CLASS_ADD_SUCCESS = "SCHOOL_CLASS_ADD_SUCCESS";
const SCHOOL_CLASS_ADD_FAIL = "SCHOOL_CLASS_ADD_FAIL";

const SCHOOL_CLASS_UPDATE_REQUEST = "SCHOOL_CLASS_UPDATE_REQUEST";
const SCHOOL_CLASS_UPDATE_SUCCESS = "SCHOOL_CLASS_UPDATE_SUCCESS";
const SCHOOL_CLASS_UPDATE_FAIL = "SCHOOL_CLASS_UPDATE_FAIL";

const SCHOOL_CLASS_DELETE_REQUEST = "SCHOOL_CLASS_DELETE_REQUEST";
const SCHOOL_CLASS_DELETE_SUCCESS = "SCHOOL_CLASS_DELETE_SUCCESS";
const SCHOOL_CLASS_DELETE_FAIL = "SCHOOL_CLASS_DELETE_FAIL";

// Reducer
export const schoolClassReducer = (state = { schoolClass: [] }, action) => {
  switch (action.type) {
    case SCHOOL_CLASS_LIST_REQUEST:
      return {
        loading: true,
        schoolClass: [],
      };
    case SCHOOL_CLASS_LIST_SUCCESS:
      return {
        loading: false,
        schoolClass: [...state.schoolClass, ...action.payload],
      };
    case SCHOOL_CLASS_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case SCHOOL_CLASS_ADD_REQUEST:
      return { loading: true, schoolClass: [...state.schoolClass] };
    case SCHOOL_CLASS_ADD_SUCCESS:
      return {
        loading: false,
        schoolClass: [...state.schoolClass, ...action.payload],
      };
    case SCHOOL_CLASS_ADD_FAIL:
      return { loading: false, error: action.payload };
    case SCHOOL_CLASS_UPDATE_REQUEST:
      return { loading: true, schoolClass: [...state.schoolClass] };
    case SCHOOL_CLASS_UPDATE_SUCCESS:
      return {
        loading: false,
        schoolClass: [...state.schoolClass, ...action.payload],
      };
    case SCHOOL_CLASS_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
        schoolClass: [...state.schoolClass],
      };
    case SCHOOL_CLASS_DELETE_REQUEST:
      return { loading: true, schoolClass: [...state.schoolClass] };
    case SCHOOL_CLASS_DELETE_SUCCESS:
      return {
        loading: false,
        schoolClass: [...state.schoolClass, ...action.payload],
      };
    case SCHOOL_CLASS_DELETE_FAIL:
      return {
        loading: false,
        error: action.payload,
        schoolClass: [...state.schoolClass],
      };

    default:
      return state;
  }
};

// Action Creators
export const listSchoolClass = () => async (dispatch) => {
  try {
    dispatch({ type: SCHOOL_CLASS_LIST_REQUEST });

    const userInfo = localStorage.getItem("userInfo");
    const { jwt_token } = JSON.parse(userInfo);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`,
      },
    };

    const { data } = await ApiService.get(
      `${process.env.REACT_APP_BASE_API}/room/client`,
      config
    );

    dispatch({
      type: SCHOOL_CLASS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SCHOOL_CLASS_LIST_FAIL,
      payload: error,
    });
  }
};

export const addSchoolClass =
  (period, schoolLevel, schoolGrade, schoolClass) => async (dispatch) => {
    try {
      dispatch({ type: SCHOOL_CLASS_ADD_REQUEST });

      const user = {
        turma: schoolClass,
        serie: parseInt(schoolGrade),
        periodo: period,
        nivelEscolar: schoolLevel,
      };

      const userInfo = localStorage.getItem("userInfo");
      const { jwt_token } = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt_token}`,
        },
      };

      const { data } = await ApiService.post(
        `${process.env.REACT_APP_BASE_API}/room`,
        user,
        config
      );

      dispatch({
        type: SCHOOL_CLASS_ADD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SCHOOL_CLASS_ADD_FAIL,
        payload: error,
      });
    }
  };

export const updateSchoolClass =
  (id, period, schoolLevel, schoolGrade, schoolClass) => async (dispatch) => {
    try {
      dispatch({ type: SCHOOL_CLASS_UPDATE_REQUEST });

      const currentSchoolClass = {
        turma: schoolClass,
        serie: schoolGrade,
        periodo: period,
        nivelEscolar: schoolLevel,
      };

      const userInfo = localStorage.getItem("userInfo");
      const { jwt_token } = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt_token}`,
        },
      };

      const { data } = await ApiService.put(
        `${process.env.REACT_APP_BASE_API}/users/room/${id}`,
        currentSchoolClass,
        config
      );
      dispatch({
        type: SCHOOL_CLASS_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SCHOOL_CLASS_UPDATE_FAIL,
        payload: error,
      });
    }
  };

export const deleteSchoolClass = (schoolClass) => async (dispatch) => {
  try {
    dispatch({ type: SCHOOL_CLASS_DELETE_REQUEST });

    const userInfo = localStorage.getItem("userInfo");
    const { jwt_token } = JSON.parse(userInfo);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`,
      },
    };
    const { data } = await ApiService.delete(
      `${process.env.REACT_APP_BASE_API}/room/${schoolClass.id}`,
      config
    );

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
