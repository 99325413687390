import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
  buttom: {
    margin: theme.spacing(1),
  },
}));

const RecordChangeButtons = ({
  itensSelected,
  handlerSingle,
  handlerMultiple,
  icon,
  singleDeleteButton,
  singleDeleteMultipleButton,
}) => {
  const classes = useToolbarStyles();
  const [adminLenght, setAdminLenght] = useState(itensSelected.length);

  const deleteSingle = (rows) => {
    handlerSingle(rows[0]);
  };

  const deleteMultiple = (rows) => {
    handlerMultiple(rows);
  };

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevVisibility = usePrevious(itensSelected);

  useEffect(() => {
    if (prevVisibility?.length !== itensSelected) {
      setAdminLenght(itensSelected.length);
    }
  }, [adminLenght, itensSelected, prevVisibility]);

  return (
    <div className={classes.root}>
      {adminLenght > 0 && (
        <Button
          className={classes.buttom}
          variant="contained"
          color="secondary"
          onClick={
            adminLenght > 1
              ? () => deleteMultiple(itensSelected)
              : () => deleteSingle(itensSelected)
          }
          startIcon={icon}
        >
          {adminLenght > 1 ? singleDeleteMultipleButton : singleDeleteButton}
        </Button>
      )}
    </div>
  );
};

RecordChangeButtons.propTypes = {
  itensSelected: PropTypes.array.isRequired,
  deleteHandler: PropTypes.func.isRequired,
  singleDeleteButton: PropTypes.string.isRequired,
  singleDeleteMultipleButton: PropTypes.string.isRequired,
};

export default RecordChangeButtons;
