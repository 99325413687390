import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Layout from "../components/common/hoc/Layout";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const Tutorial = () => {
  const classes = useStyles();
  const { t } = useTranslation("translation");
  return (
    <React.Fragment>
      <Layout title={t("tutorial.title")}>
        <Paper
          className={classes.paper}
          elevation={1}
          style={{ margin: "0 auto" }}
        >
          <Container component="main" style={{ margin: "auto" }}></Container>
        </Paper>
      </Layout>
    </React.Fragment>
  );
};

export { Tutorial };
