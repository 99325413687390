import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.secondary,
    color: theme.palette.primary.text,
    fontSize: theme.typography.pxToRem(12),
  },
}))(Tooltip);

export default CustomTooltip;
