// Reducer
export const customConfirmationDialogReducer = (state = {}, action) => {
  switch (action.type) {
    case "CUSTOM_CONFIRMATION_DIALOG_SUCCESS":
      return {
        ...state,
        customConfirmationDialogConfig: action,
      };
    case "CUSTOM_CONFIRMATION_DIALOG_CLOSED":
      return {
        ...state,
        customConfirmationDialogConfig: action,
      };
    default:
      return state;
  }
};

// Action Creators

/*
  title: string,
   message: string
 */
export const showCustomConfirmationDialog = (data) => {
  const config = {
    title: data.title ? data.title : "",
    message: data.message ? data.message : "",
    cancel: data.buttons.cancel,
    confirm: data.buttons.confirm,
    onSubmit: data.onSubmit,
    close: data.close,
  };

  return (dispatch) => {
    dispatch({ type: "CUSTOM_CONFIRMATION_DIALOG_SUCCESS", ...config });
  };
};

export const closeCustomConfirmationDialog = (data) => {
  const config = {
    title: data.title ? data.title : "",
    message: data.message ? data.message : "",
    onSubmit: undefined,
    close: undefined,
  };

  return (dispatch) => {
    dispatch({ type: "CUSTOM_CONFIRMATION_DIALOG_CLOSED", ...config });
  };
};
