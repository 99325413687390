import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Layout from "../components/common/hoc/Layout";
import NotFound from "../components/common/notFound/NotFound";
import { useTranslation } from "react-i18next";
import YoutubePlayer from "../components/common/youTubePlayer/YouTubePlayer";
import { UserRole } from "../config/userRole";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: 800,
    },
    marginTop: 0,
    marginBottom: "1rem",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  icon: {
    margin: 10,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    width: "100%",
    fontWeight: theme.typography.fontWeightRegular,
  },
  accordion: {
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  video: {
    width: "100%",
    overflow: "hidden",
    position: "relative",
  },
}));

const Faq = () => {
  const classes = useStyles();
  const { t } = useTranslation("translation");
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const faq = [
    {
      id: "1",
      question: t("faq.faq.question_1.question"),
      answer: t("faq.faq.question_1.answer"),
      videoId: "5a9YnsGrApk ",
      role: UserRole.ADMINISTRADOR_CLIENTE,
    },
    {
      id: "2",
      question: t("faq.faq.question_2.question"),
      answer: t("faq.faq.question_2.answer"),
      videoId: "Zwfmk16YvLg",
      role: UserRole.ADMINISTRADOR_CLIENTE,
    },
    {
      id: "3",
      question: t("faq.faq.question_3.question"),
      answer: t("faq.faq.question_3.answer"),
      videoId: "1oC29pCqF_o",
      role: UserRole.ADMINISTRADOR_CLIENTE,
    },
    {
      id: "4",
      question: t("faq.faq.question_4.question"),
      answer: t("faq.faq.question_4.answer"),
      videoId: "rcFUq4sTz38 ",
      role: UserRole.ADMINISTRADOR_CONTRATANTE,
    },
    {
      id: "5",
      question: t("faq.faq.question_5.question"),
      answer: t("faq.faq.question_5.answer"),
      videoId: "41WkGsqjvl4",
      role: UserRole.ADMINISTRADOR_CLIENTE,
    },
    {
      id: "6",
      question: t("faq.faq.question_6.question"),
      answer: t("faq.faq.question_6.answer"),
      videoId: "8ciy_pPvuAU",
      role: UserRole.PROFESSOR,
    },
  ];
  const [result, setResult] = useState(faq);

  const search = (e) => {
    e.preventDefault();
    const keyword = e.target.value ? e.target.value.toLowerCase().trim() : "";

    if (keyword !== "") {
      const tempResult = result.filter((faq) =>
        faq.question.toLowerCase().includes(keyword)
      );

      if (tempResult.length > 0) {
        setResult(tempResult);
      } else {
        setResult([]);
      }
    } else {
      setResult(faq);
    }
  };
  return (
    <Layout title={t("faq.title")}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={10} lg={6}>
          <Typography variant="h5" component="h2" gutterBottom>
            {t("faq.subtitle")}
          </Typography>
          <Paper component="form" onSubmit={search} className={classes.root}>
            <InputBase
              id="search"
              onChange={(event) => search(event)}
              className={classes.input}
              placeholder={t("faq.search.placeholder")}
              inputProps={{ "aria-label": "search" }}
            />

            <SearchIcon className={classes.icon} />
          </Paper>
        </Grid>

        {result?.length > 0 ? (
          result.map((faq) =>
            userInfo && userInfo.role[0] === faq.role ? (
              <Grid
                key={faq.id}
                item
                xs={12}
                sm={10}
                lg={6}
                className={classes.accordion}
              >
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      {faq.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{faq.answer}</Typography>
                  </AccordionDetails>
                  <YoutubePlayer embedId={faq.videoId} />
                </Accordion>
              </Grid>
            ) : (
              ""
            )
          )
        ) : (
          <NotFound
            title="Ops..."
            message="Dúvida não encontrada"
            image="/images/notfound.png"
            redirect="/app/tutorial"
            buttonText="Ver tutorial"
          />
        )}
      </Grid>
    </Layout>
  );
};

export { Faq };
