import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Carousel from "nuka-carousel";
import Carouselcard from "../components/CarouselCard";
import InteractionCard from "../../InteractionsLibrary/components/card";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ShortCut from "./ShortCut";
import DisplayNumber from "./DisplayNumber";

const CustomCarousel = (props) => {
  const {
    items,
    slideIndex,
    setCarouselIndex,
    cardType,
    cellSpacing,
    showInteractionDetails,
    externalControl,
  } = props;
  const xs = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const md = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [slidesToShowInCarousel, setSlidesToShowInCarousel] = useState(3);

  useEffect(() => {
    if (xs) {
      setSlidesToShowInCarousel(1.25);
    } else if (sm) {
      setSlidesToShowInCarousel(1);
    } else if (md) {
      setSlidesToShowInCarousel(2);
    } else {
      setSlidesToShowInCarousel(3);
    }
  }, [xs, sm, md]);

  const slidesToShowResponsive = () => {
    if (xs) {
      return slidesToShowInCarousel;
    } else {
      if (cardType === "InteractionCard") {
        return slidesToShowInCarousel + 2;
      } else if (cardType === "Carouselcard") {
        return slidesToShowInCarousel;
      }
    }
  };

  const configExternalControls = {
    slideIndex,
    afterSlide: (index) => setCarouselIndex(index),
    slidesToShow: slidesToShowResponsive(),
  };

  const externalControls = (externalControl) => {
    if (externalControl) {
      return configExternalControls;
    } else {
      return { slidesToShow: 1.25 };
    }
  };

  return (
    <>
      {items.length && (
        <Carousel
          {...externalControls(externalControl)}
          scrollMode="page"
          renderCenterLeftControls={null}
          renderCenterRightControls={null}
          dragging={true}
          heightMode="max"
          transitionMode="scroll"
          cellSpacing={cellSpacing}
          //cellAlign={1}
        >
          {items.map((item) => {
            if (cardType === "InteractionCard") {
              return (
                <InteractionCard
                  key={item.idContent}
                  interaction={item}
                  showInteractionDetails={showInteractionDetails}
                />
              );
            }
            if (cardType === "ShortCut") {
              return (
                <ShortCut
                  key={item.idContent}
                  title={item.title}
                  icon={item.icon}
                  link={item.link}
                  boxColor={item.boxColor}
                  textColor={item.textColor}
                  avatarColor={item.avatarColor}
                />
              );
            } else if (cardType === "DisplayNumber") {
              return (
                <DisplayNumber
                  key={item.idContent}
                  title={item.title}
                  value={item.value}
                  icon={item.icon}
                  boxColor={item.boxColor}
                  textColor={item.textColor}
                  avatarColor={item.avatarColor}
                />
              );
            } else if (cardType === "Carouselcard") {
              return <Carouselcard key={item.idContent} item={item} />;
            } else {
              return <Carouselcard key={item.idContent} item={item} />;
            }
          })}
        </Carousel>
      )}
    </>
  );
};

CustomCarousel.propTypes = {
  items: PropTypes.array.isRequired,
};

export default CustomCarousel;
