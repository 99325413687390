import ApiService from "../../../services/ApiService";

// Action types
const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
const USER_LOGOUT_FAIL = "USER_LOGOUT_FAIL";

// Reducer
export const userLoginReducer = (
  state = { loading: false, userInfo: {} },
  action
) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT_REQUEST:
      return { loading: true, userInfo: action.payload };
    case USER_LOGOUT_SUCCESS:
      return { loading: false, response: action.payload };
    case USER_LOGOUT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
// Action Creators
export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });

    const userCredentials = {
      email,
      password,
    };

    const { data } = await ApiService.post("/auth/login", userCredentials);
    const { role } = data;
    const datauser = {
      ...data,
      role: [role],
    };

    localStorage.setItem("userInfo", JSON.stringify(datauser));

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: datauser,
    });
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: "Erro", //error && error.message
    });
  }
};

export const googleLogin = (token) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });

    const userCredentials = {
      accessToken: token,
    };

    const { data } = await ApiService.post("/auth/sso", userCredentials);

    const { role } = data;
    const datauser = {
      ...data,
      role: [role],
    };

    localStorage.setItem("userInfo", JSON.stringify(datauser));

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: datauser,
    });
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: "Erro", //error && error.message
    });
  }
};

export const logout = () => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGOUT_REQUEST });

    localStorage.clear();

    dispatch({
      type: USER_LOGOUT_SUCCESS,
      payload: {},
    });
  } catch (error) {
    dispatch({
      type: USER_LOGOUT_FAIL,
      payload: "Erro", //error && error.message
    });
  }

  window.location.reload();
};
