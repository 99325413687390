import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { 
  login, 
  // googleLogin 
} from "../../../store/duck/signIn/login";
import { useTranslation } from "react-i18next";
// import GoogleLogin from "react-google-login";
// import { showSnackbar } from "../../../store/duck/uiReducer/uiReducer";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignInForm = (props) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;
  const { isForgot } = props;
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation("translation");

  useEffect(() => {
    if (userInfo) {
      history.push("/app");
    }
  }, [history, userInfo]);

  const schema = yup.object().shape({
    email: yup.string().email().required(t("signIn.email.requiredErrorMsg")),
    password: yup
      .string()
      .max(100)
      .required(t("signIn.password.requiredErrorMsg")),
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const submitHandler = (formValues) => {
    dispatch(login(formValues.email, formValues.password));
  };

  // const onSuccessGoogle = (response) => {
  //   const { tokenId } = response;
  //   dispatch(googleLogin(tokenId));
  // };

  // const onFailureGoogle = (res) => {
  //   dispatch(
  //     showSnackbar({
  //       message: `Failed to login.`,
  //       severity: "error",
  //     })
  //   );
  // };

  return (
    <>
      <form
        className={classes.form}
        onSubmit={handleSubmit(submitHandler)}
        noValidate
      >
        <TextField
          inputRef={register}
          error={errors.email && true}
          helperText={errors.email && t("signIn.email.requiredErrorMsg")}
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
          label={t("signIn.email.label")}
          placeholder={t("signIn.email.placeholder")}
          name="email"
          autoComplete="email"
          autoFocus
          disabled={loading}
        />
        <TextField
          inputRef={register}
          error={errors.password && true}
          helperText={errors.password && t("signIn.password.requiredErrorMsg")}
          variant="outlined"
          margin="normal"
          fullWidth
          name="password"
          label={t("signIn.password.label")}
          placeholder={t("signIn.password.placeholder")}
          type="password"
          id="password"
          disabled={loading}
        />
        <Grid container>
          <Grid item>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label={t("signIn.rememberAccessDataMsg")}
            />
          </Grid>
          <Grid item style={{ display: "inline-flex", alignItems: "center" }}>
            <Button
              onClick={() => isForgot(true)}
              component={Link}
              color="primary"
            >
              {t("signIn.forgotPasswordMsg")}
            </Button>
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={loading}
        >
          {t("signIn.loginButton")}
        </Button>
        {/* <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          buttonText={t("signIn.GoogleLogin")}
          onSuccess={onSuccessGoogle}
          onFailure={onFailureGoogle}
          cookiePolicy={"single_host_origin"}
          disabled={loading}
        /> */}

        {loading ? (
          <LinearProgress />
        ) : error ? (
          <Typography
            variant="subtitle1"
            color="secondary"
            align="center"
            component="h3"
            gutterBottom
          >
            {t("signIn.loginErrorMsg")}
          </Typography>
        ) : (
          ""
        )}
      </form>
    </>
  );
};

export { SignInForm };
