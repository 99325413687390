import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles({
  boxColor: {
    backgroundColor: (props) => props.boxColor,
  },
  textColor: {
    color: (props) => props.textColor,
  },
  avatarColor: {
    backgroundColor: (props) => props.avatarColor,
  },
});

const ShortCut = ({ icon, title, link, ...props }) => {
  const { boxColor, textColor, avatarColor } = useStyles(props);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      className={boxColor}
      style={{
        borderRadius: "10px",
        height: "8.875rem",
      }}
    >
      <Link to={link} style={{ textDecoration: "none" }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexWrap="nowrap"
        >
          <Avatar
            className={avatarColor}
            style={
              isMobile ? { padding: "30px" } : { width: "60px", height: "60px" }
            }
          >
            {icon}
          </Avatar>
        </Box>

        <Box width="100%" flexWrap="nowrap">
          <Typography
            align="center"
            className={textColor}
            variant={isMobile ? "h6" : "body2"}
            component="h3"
          >
            {title}
          </Typography>
        </Box>
      </Link>
    </Box>
  );
};

ShortCut.propTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string,
  link: PropTypes.string.isRequired,
};

export default ShortCut;
