import * as yup from "yup";

const adminSchema = () => {
  const schema = yup.object().shape({
    name: yup
      .string()
      //.required(`${contentName.requiredErrorMsg}`),
      .required("Falta nome"),
    email: yup
      .string()
      .email()
      //.required(`${contentDescription.requiredErrorMsg}`),
      .required("falta email"),
  });
  return schema;
};

export { adminSchema };
