import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const a11yProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
};

const TabsPeriod = ({ currentTab, handleCurrentTab, periodos }) => {
  return (
    <Tabs
      value={currentTab}
      onChange={handleCurrentTab}
      indicatorColor="primary"
      scrollButtons="auto"
      textColor="primary"
      variant="scrollable"
      aria-label="full width tabs example"
    >
      {periodos.map((periodo, index) => (
        <Tab
          onClick={() => handleCurrentTab(index)}
          label={periodo}
          key={index}
          {...a11yProps(index)}
        />
      ))}
      )}
    </Tabs>
  );
};

export default TabsPeriod;
