import ApiService from "../../../services/ApiService";

// Action types
const MY_INTERACTIONS_LIST_REQUEST = "MY_INTERACTIONS_LIST_REQUEST";
const MY_INTERACTIONS_LIST_SUCCESS = "MY_INTERACTIONS_LIST_SUCCESS";
const MY_INTERACTIONS_LIST_FAIL = "MY_INTERACTIONS_LIST_FAIL";

const MY_INTERACTION_SEARCH_REQUEST = "MY_INTERACTION_SEARCH_REQUEST";
const MY_INTERACTION_SEARCH_SUCCESS = "MY_INTERACTION_SEARCH_SUCCESS";
const MY_INTERACTION_SEARCH_FAIL = "MY_INTERACTION_SEARCH_FAIL";

const MY_INTERACTION_RESET = "MY_INTERACTION_RESET";

// Reducer
export const myContentListReducer = (
  state = { 
    interactions: [],
    lastTypeOfSearch: "default",
    keyword: "", 
  }, 
  action
) => {
  switch (action.type) {
    case MY_INTERACTIONS_LIST_REQUEST:
      return { loading: true, interactions: state.interactions, };
    case MY_INTERACTIONS_LIST_SUCCESS:
      return { loading: false, interactions: action.payload, lastTypeOfSearch: "default", };
    case MY_INTERACTIONS_LIST_FAIL:
      return { loading: false, error: action.payload };
    case MY_INTERACTION_SEARCH_REQUEST:
      return {
        loading: true,
        interactions: state.interactions,
        lastTypeOfSearch: "keyword",
        keyword: action.keyword,
      };

    case MY_INTERACTION_SEARCH_SUCCESS:
      return {
        loading: false,
        interactions: action.payload,
        lastTypeOfSearch: "keyword",
        keyword: action.keyword,
      };
    case MY_INTERACTION_SEARCH_FAIL:
      return { loading: false, error: action.payload };
    case MY_INTERACTION_RESET:
      return { loading: true, interactions: [] };
    default:
      return state;
  }
};

// Action Creators
export const listInteractions =
  (keyword = "") =>
  async (dispatch) => {
    dispatch({ type: MY_INTERACTIONS_LIST_REQUEST });
    try {
      const url = keyword ? `/content/list?keyword=${keyword}&limit=1000` : "/content/list?limit=1000";
      const { data } = await ApiService.get(url);

      dispatch({
        type: MY_INTERACTIONS_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MY_INTERACTIONS_LIST_FAIL,
        payload: error,
      });
    }
  };

  export const searchInteractions =
    (keyword) =>
    async (dispatch) => {
      let result;
      try {
        if (keyword) {
          result = await ApiService.get(
            `/content/library?keyword=${keyword}&limit=1000`
          );
        }
        const { data } = result;

        dispatch({
          type: MY_INTERACTION_SEARCH_SUCCESS,
          payload: data,
          keyword,
        });
      } catch (error) {
        dispatch({
          type: MY_INTERACTION_SEARCH_FAIL,
          payload: error,
        });
      }
    };

export const searchKeywordRequest = (keyword) => async (dispatch) => {
  if (keyword) {
    dispatch({ type: MY_INTERACTION_SEARCH_REQUEST, keyword });
  } else {
    console.log("clearing search");
    dispatch({ type: MY_INTERACTION_SEARCH_REQUEST, keyword: ":clear" });
  }
};

export const resetInteractionsRedux = () => async (dispatch) => {
  dispatch({ type: MY_INTERACTION_RESET });
}