import ApiService from "../services/ApiService";

// Action types
const CONTRACTOR_LIST_REQUEST = "CONTRACTOR_LIST_REQUEST";
const CONTRACTOR_LIST_SUCCESS = "CONTRACTOR_LIST_SUCCESS";
const CONTRACTOR_LIST_FAIL = "CONTRACTOR_LIST_FAIL";

const CONTRACTOR_ADD_REQUEST = "CONTRACTOR_ADD_REQUEST";
const CONTRACTOR_ADD_SUCCESS = "CONTRACTOR_ADD_SUCCESS";
const CONTRACTOR_ADD_FAIL = "CONTRACTOR_ADD_FAIL";

const CONTRACTOR_UPDATE_REQUEST = "CONTRACTOR_UPDATE_REQUEST";
const CONTRACTOR_UPDATE_SUCCESS = "CONTRACTOR_UPDATE_SUCCESS";
const CONTRACTOR_UPDATE_FAIL = "CONTRACTOR_UPDATE_FAIL";

const CONTRACTOR_DELETE_REQUEST = "CONTRACTOR_DELETE_REQUEST";
const CONTRACTOR_DELETE_SUCCESS = "CONTRACTOR_DELETE_SUCCESS";
const CONTRACTOR_DELETE_FAIL = "CONTRACTOR_DELETE_FAIL";

// Reducer
export const contractorReducer = (state = { contractors: [] }, action) => {
  switch (action.type) {
    case CONTRACTOR_LIST_REQUEST:
      return { loading: true, contractors: [] };
    case CONTRACTOR_LIST_SUCCESS:
      return { loading: false, contractors: action.payload };
    case CONTRACTOR_LIST_FAIL:
      return { loading: false, contractors: [], error: action.payload };
    case CONTRACTOR_ADD_REQUEST:
      return { loading: true, contractors: [] };
    case CONTRACTOR_ADD_SUCCESS:
      return { loading: false, contractors: [action.payload] };
    case CONTRACTOR_ADD_FAIL:
      return { loading: false, contractors: [], error: action.payload };
    case CONTRACTOR_UPDATE_REQUEST:
      return { loading: true, contractors: [] };
    case CONTRACTOR_UPDATE_SUCCESS:
      return { loading: false, contractors: [action.payload] };
    case CONTRACTOR_UPDATE_FAIL:
      return { loading: false, contractors: [], error: action.payload };
    case CONTRACTOR_DELETE_REQUEST:
      return { loading: true, contractors: [] };
    case CONTRACTOR_DELETE_SUCCESS:
      return { loading: false, contractors: action.payload };
    case CONTRACTOR_DELETE_FAIL:
      return { loading: false, contractors: [], error: action.payload };
    default:
      return state;
  }
};

// Action Creators
export const listContractor = () => async (dispatch) => {
  try {
    dispatch({ type: CONTRACTOR_LIST_REQUEST });

    const { data } = await ApiService.get("/users/contractors");

    dispatch({
      type: CONTRACTOR_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTRACTOR_LIST_FAIL,
      payload: error,
    });
  }
};

export const addContractor = (name, email, cnpj) => async (dispatch) => {
  try {
    dispatch({ type: CONTRACTOR_ADD_REQUEST });

    const user = {
      nameFantasy: name,
      email,
      cnpjContractor: cnpj,
      statusContract: "ativo",
      dateContractValidity: "27/01/2021",
    };

    const { data } = await ApiService.post("/users/contractor", user);

    dispatch({
      type: CONTRACTOR_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTRACTOR_ADD_FAIL,
      payload: error,
    });
  }
};

export const updateContractor = (id, name, email, cnpj) => async (dispatch) => {
  try {
    dispatch({ type: CONTRACTOR_UPDATE_REQUEST });

    const user = {
      name,
      email,
      cnpj,
    };

    const { data } = await ApiService.put(`/users/contractor/${id}`, user);

    dispatch({
      type: CONTRACTOR_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTRACTOR_UPDATE_FAIL,
      payload: error,
    });
  }
};

export const deleteContractor = (contractor) => async (dispatch) => {
  try {
    dispatch({ type: CONTRACTOR_DELETE_REQUEST });

    //TODO After created the api change this
    const { data } = await ApiService.delete(
      `/users/contractor/${contractor.id}`
    );
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
