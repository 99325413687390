import * as yup from "yup";

const filterSchema = () => {
  const schema = yup.object().shape({
    subject: yup.string().required("Falta nome"),
  });
  return schema;
};

export { filterSchema };
