import FormControl from "@material-ui/core/FormControl";
import { Controller } from "react-hook-form";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme) => ({
  icon: {
    fill: theme.palette.secondary.main,
  },
  helperText: {
    color: theme.palette.error.main,
  },
}));

const ReactHookFormAutocomplete = ({
  options = [],
  renderInput,
  getOptionLabel,
  onChange: ignored,
  control,
  defaultValue,
  name,
  renderOption,
  helperText,
  renderTags,
  limitTags,
  inputRef,
  ...props
}) => {
  const classes = useStyles();
  return (
    <FormControl fullWidth {...props} component="fieldset">
      <Controller
        render={({ onChange, ...props }) => (
          <Autocomplete
            multiple
            limitTags={limitTags}
            options={options}
            getOptionLabel={getOptionLabel}
            renderOption={renderOption}
            renderInput={renderInput}
            renderTags={renderTags}
            onChange={(e, data) => onChange(data)}
            {...props}
          />
        )}
        inputRef={inputRef}
        defaultValue={defaultValue && [defaultValue]}
        name={name}
        control={control}
      />
      <FormHelperText className={classes.helperText}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};
export default ReactHookFormAutocomplete;
