import React from "react";
import { Redirect } from "react-router";
import { Route, Switch } from "react-router-dom";
import { SignIn, UpdatePassword } from "../pages";

const PublicRoutes = () => {
  return (
    <Switch>
      <Route path="/validate" component={UpdatePassword} exact />
      <Route path="/password" component={UpdatePassword} exact />
      <Route path="/" component={SignIn} exact />
      <Route path="*" render={() => <Redirect to="/" />} />
    </Switch>
  );
};

export default PublicRoutes;
