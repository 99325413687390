import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Layout from "../../components/common/hoc/Layout";
import { useTranslation } from "react-i18next";

const BugReport = () => {
  const { t } = useTranslation("translation");

  return (
    <Layout title={t("bugReport.title")}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={12} lg={12}>
          <iframe
            style={{ maxWidth: "100%" }}
            src="https://docs.google.com/forms/d/e/1FAIpQLSed3s-8RS_F7aY6LCBvXll844XWtq7BJmyXVhbvAssOQaYk0g/viewform?embedded=true"
            width="1920"
            height="1285"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
          >
            Loading…
          </iframe>
        </Grid>
      </Grid>
    </Layout>
  );
};

export { BugReport };
