import React, { memo } from "react";
import { Route, Router, Switch } from "react-router-dom";
import history from "../utils/history";
import Auth from "./Auth";
import PrivateRoutes from "./PrivateRoutes";

function Routes() {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/app" component={PrivateRoutes} exact />
        <Route path="" component={Auth} exact />
      </Switch>
    </Router>
  );
}

export default memo(Routes);
