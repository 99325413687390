export function excerpt(text, maxSize) {
  if (text && text.length > maxSize) {
    const shorterText = text.substring(0, maxSize);
    const tempText = shorterText.split(" ");
    const pattern = "[^ ]+ *$";
    const lastWord = shorterText.match(pattern);
    const lastWordIndex = tempText.indexOf(lastWord[0]);
    if (lastWordIndex !== -1) tempText.splice(lastWordIndex, 1);
    return tempText.join(" ") + "...";
  }

  return text;
}
