import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import configureStore from "./store/store";
import logger from "./services/logService";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import i18n from "i18next";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/fr";
import "moment/locale/pt-br";

import "./i18n/i18n";

moment.locale(i18n.language);

export const store = configureStore();

logger.init();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <I18nextProvider i18n={i18next}>
          <MuiPickersUtilsProvider
            libInstance={moment}
            utils={MomentUtils}
            locale={"pt-br"}
          >
            <App />
          </MuiPickersUtilsProvider>
        </I18nextProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
