import * as yup from "yup";

const contentInfoSchema = () => {
  const schema = yup.object().shape({
    contentName: yup.string().required(),
    contentNameEn: yup.string().required(),
    contentNameFr: yup.string().required(),
    contentDescription: yup.string().max(1500).required(),
    contentDescriptionEn: yup.string().max(1500).required(),
    contentDescriptionFr: yup.string().max(1500).required(),
    schoolSubjects: yup.string().required(),
    discipline: yup.string().nullable().required(),
    topic: yup.string().required(),
    bncc: yup.string().required(),
    client: yup.string().nullable().required(),
    schoolGrade: yup.string().nullable().required(),
    schoolLevel: yup.string().nullable().required(),
    companyName: yup.string().nullable().required(),
    author: yup.string().required(),
    effectiveDate: yup.string().required(),
  });
  return schema;
};

export { contentInfoSchema };
