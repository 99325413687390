import ApiService from "./../../../services/ApiService";
import { showSnackbar } from "../uiReducer/uiReducer";

// Action types
const CART_ADD_ITEM_REQUEST = "CART_ADD_ITEM_REQUEST";
const CART_ADD_ITEM_SUCCESS = "CART_ADD_ITEM_SUCCESS";
const CART_ADD_ITEM_FAIL = "CART_ADD_ITEM_FAIL";

const CART_LOAD_ITEM_REQUEST = "CART_LOAD_ITEM_REQUEST";
const CART_LOAD_ITEM_SUCCESS = "CART_LOAD_ITEM_SUCCESS";
const CART_LOAD_ITEM_FAIL = "CART_LOAD_ITEM_FAIL";

const CART_UPDATE_ITEM_REQUEST = "CART_UPDATE_ITEM_REQUEST";
const CART_UPDATE_ITEM_SUCCESS = "CART_UPDATE_ITEM_SUCCESS";
const CART_UPDATE_ITEM_FAIL = "CART_UPDATE_ITEM_FAIL";

const CART_DELETE_ITEM_REQUEST = "CART_DELETE_ITEM_REQUEST";
const CART_DELETE_ITEM_SUCCESS = "CART_DELETE_ITEM_SUCCESS";
const CART_DELETE_ITEM_FAIL = "CART_DELETE_ITEM_FAIL";

const CART_PUBLISH_REQUEST = "CART_PUBLISH_REQUEST";
const CART_PUBLISH_SUCCESS = "CART_PUBLISH_SUCCESS";
const CART_PUBLISH__FAIL = "CART_PUBLISH__FAIL";

// Reducer
export const cartReducer = (state = { items: [] }, action) => {
  switch (action.type) {
    case CART_ADD_ITEM_REQUEST:
      return { loading: true, items: action.payload };
    case CART_ADD_ITEM_SUCCESS:
      return { loading: false, items: action.payload };
    case CART_ADD_ITEM_FAIL:
      return { loading: false, items: action.payload };
    case CART_LOAD_ITEM_REQUEST:
      return { loading: true, items: [] };
    case CART_LOAD_ITEM_SUCCESS:
      return { loading: false, items: action.payload };
    case CART_LOAD_ITEM_FAIL:
      return { loading: false, items: action.payload };
    case CART_UPDATE_ITEM_REQUEST:
      return { loading: true, items: [] };
    case CART_UPDATE_ITEM_SUCCESS:
      return { loading: false, items: action.payload };
    case CART_UPDATE_ITEM_FAIL:
      return { loading: false, items: action.payload };
    case CART_DELETE_ITEM_REQUEST:
      return { loading: true, items: action.payload };
    case CART_DELETE_ITEM_SUCCESS:
      return { loading: false, items: action.payload };
    case CART_DELETE_ITEM_FAIL:
      return { loading: false, items: action.payload };
    case CART_PUBLISH_REQUEST:
      return { loading: true, items: [], status: action.payload };
    case CART_PUBLISH_SUCCESS:
      return { loading: false, items: [], status: action.payload };
    case CART_PUBLISH__FAIL:
      return {
        loading: false,
        items: action.payload.items,
        status: action.payload,
      };
    default:
      return state;
  }
};

// Action Creators
export const cartAdd = (new_item) => async (dispatch) => {
  try {
    const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    const existItem = cartItems.find(
      (old_item) => old_item.idContent === new_item.idContent
    );
    dispatch({ type: CART_ADD_ITEM_REQUEST });
    if (existItem !== undefined) {
      dispatch({
        type: CART_ADD_ITEM_FAIL,
        payload: {
          error: {
            message: "Item already in the cart",
          },
        },
      });
    } else {
      const cartUpdated = [...cartItems, new_item];
      localStorage.setItem("cartItems", JSON.stringify(cartUpdated));
      dispatch({
        type: CART_ADD_ITEM_SUCCESS,
        payload: [...cartUpdated],
      });
      dispatch(
        showSnackbar({
          message: "Interação adicionada!",
          severity: "success",
        })
      );
    }
  } catch (error) {
    dispatch({
      type: CART_ADD_ITEM_FAIL,
      payload: {
        error: {
          message: error,
        },
      },
    });
  }
};

export const cartLoad = () => async (dispatch) => {
  try {
    dispatch({ type: CART_LOAD_ITEM_REQUEST });
    const currentItems = localStorage.getItem("cartItems")
      ? JSON.parse(localStorage.getItem("cartItems"))
      : [];
    dispatch({
      type: CART_LOAD_ITEM_SUCCESS,
      payload: currentItems,
    });
  } catch (error) {
    dispatch({
      type: CART_LOAD_ITEM_FAIL,
      payload: {
        error: {
          message: "Impossible load Items in the from localStorage",
        },
      },
    });
  }
};

export const cartUpdate = (itemToUpdate, params) => async (dispatch) => {
  let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
  const isInteraction = await cartItems.find((old_interaction) =>
    old_interaction !== undefined
      ? old_interaction.idContent === itemToUpdate.idContent
      : old_interaction
  );

  try {
    dispatch({ type: CART_UPDATE_ITEM_REQUEST });

    if (isInteraction !== undefined) {
      const interactionsUpdated = cartItems.map((el) => {
        if (el.idContent === itemToUpdate.idContent) {
          el.params = params;
          return el;
        } else {
          return el;
        }
      });
      localStorage.setItem("cartItems", JSON.stringify(interactionsUpdated));

      dispatch({
        type: CART_UPDATE_ITEM_SUCCESS,
        payload: interactionsUpdated,
      });
      dispatch(
        showSnackbar({
          message: "Interação configurada!",
          severity: "success",
        })
      );
    } else {
      dispatch({
        type: CART_UPDATE_ITEM_FAIL,
        payload: cartItems,
      });
    }
  } catch (error) {
    dispatch({
      type: CART_UPDATE_ITEM_FAIL,
      payload: cartItems,
    });
  }
};

export const cartDelete = (item) => async (dispatch) => {
  let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
  const existItem = cartItems.find(
    (old_item) => old_item.idContent === item.idContent
  );
  try {
    dispatch({ type: CART_DELETE_ITEM_REQUEST });

    if (existItem === undefined) {
      dispatch({
        type: CART_DELETE_ITEM_FAIL,
        payload: cartItems,
      });
    } else {
      const indexItemToRemove = cartItems.indexOf(existItem);
      cartItems.splice(indexItemToRemove, 1);
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
      dispatch({
        type: CART_DELETE_ITEM_SUCCESS,
        payload: cartItems,
      });
      dispatch(
        showSnackbar({
          message: "Interação removida!",
          severity: "warning",
        })
      );
    }
  } catch (error) {
    dispatch({
      type: CART_DELETE_ITEM_FAIL,
      payload: cartItems,
    });
  }
};

export const cartPublish = (itemToPublish) => async (dispatch) => {
  try {
    dispatch({ type: CART_PUBLISH_REQUEST });

    const formData = new FormData();
    const { tags, schoolGrade, contentName, discipline, school, schoolClass } =
      itemToPublish.infoClass;

    const tempSchoolClass = JSON.stringify(schoolClass);
    const tempTags = JSON.stringify(tags);
    const tempDiscipline = JSON.stringify(discipline); //TODO: adicionar array quando for uma única disciplina

    const fullContent = {
      infoClass: {
        competence: tempTags || "", // TODO: change competence to tags
        lectureName: contentName || "",
        discipline: tempDiscipline || "",
        nameSchool: school || "",
        turmas: tempSchoolClass || "", // turmas === schoolClass
        serie: schoolGrade || "", // serie == schoolGrade
      },
      interactions: [],
    };

    const typesOfFiles = ["markerFile", "imageFile", "videoFile", "audioFile"];

    for (const interaction of itemToPublish.interactions) {
      if (interaction.hasOwnProperty("params")) {
        let params = {};
        const identifier = Math.floor(Date.now() + Math.random(0, 50));
        params["idToMatch"] = identifier;
        for (const file in interaction.params) {
          if (Object.keys(file).length !== 0) {
            if (typesOfFiles.includes(file)) {
              formData.append(
                `${identifier}#${file}`,
                interaction.params[file]
              );
            } else {
              params[file] = interaction.params[file];
            }
          }
        }
        fullContent.interactions.push({
          title: interaction.title,
          description: interaction.description,
          ...params,
        });
      } else {
        fullContent.interactions.push({
          ...interaction,
          title: "",
          description: "",
        });
      }
    }

    formData.append("fullLecture", JSON.stringify(fullContent));

    // Display the key/value pairs
    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    await ApiService.post("/lecture", formData);

    dispatch({
      type: CART_PUBLISH_SUCCESS,
      payload: { success: true },
    });
    dispatch(
      showSnackbar({
        message: "Aula publicada com sucesso!",
        severity: "success",
        autoHideDuration: 3000,
      })
    );
    localStorage.setItem("cartItems", "[]");
  } catch (error) {
    dispatch({
      type: CART_PUBLISH__FAIL,
      payload: { success: false, items: [], error },
    });
    dispatch(
      showSnackbar({
        message: "Ocorreu um erro ao publicar a aula!",
        severity: "error",
        autoHideDuration: 3000,
      })
    );
    localStorage.setItem("cartItems", "[]");
  }
};
