import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles({
  boxColor: {
    backgroundColor: (props) => props.boxColor,
  },
  textColor: {
    color: (props) => props.textColor,
  },
  avatarColor: {
    backgroundColor: (props) => props.avatarColor,
  },
});

const DisplayNumber = ({ icon, title, value, ...props }) => {
  const { boxColor, textColor, avatarColor } = useStyles(props);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  return (
    <Box
      p={3}
      className={boxColor}
      style={{
        borderRadius: "10px",
        height: "8.875rem",
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} style={{ padding: 0 }}>
          <Typography
            align="left"
            className={textColor}
            variant="body1"
            component="h3"
            noWrap
          >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={8} sm={7} lg={8}>
          <Typography
            align="left"
            className={textColor}
            variant={isMobile ? "h2" : "h3"}
            component="h3"
            noWrap
          >
            {value}
          </Typography>
        </Grid>
        <Grid item xs={3} sm={5} lg={3}>
          <Avatar
            className={avatarColor}
            style={
              isMobile ? { padding: "30px" } : { width: "60px", height: "60px" }
            }
          >
            {icon}
          </Avatar>
        </Grid>
      </Grid>
    </Box>
  );
};

DisplayNumber.propTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string,
};

export default DisplayNumber;
