import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Layout from "../../components/common/hoc/Layout";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";
import Box from "@material-ui/core/Box";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import CustomSpinner from "../../components/common/Spinner/CustomSpinner";
import EnhancedTableHead from "../../components/common/table/EnhancedTableHead";
import { showCustomConfirmationDialog } from "../../store/customConfirmationDialogReducer";
import Add from "./components/add";
import Edit from "./components/edit";
import RecordChangeButtons from "../../components/common/recordChangeButtons/RecordChangeButtons";
import NotFound from "../../components/common/notFound/NotFound";
import { useTranslation } from "react-i18next";
import { getComparator, stableSort } from "../../utils/tableFilter";
import { useDispatch, useSelector } from "react-redux";
import {
  addTeacher,
  updateTeacher,
  deleteTeacher,
  listTeacher,
} from "../../store/teacher";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    marginTop: "1rem",
    minWidth: 750,
  },
  rowEnable: {
    outlineColor: theme.palette.secondary.main,
    outlineWidth: "2px",
    outlineStyle: "solid",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  buttonAdd: {
    marginLeft: theme.spacing(1),
  },
}));

const RegisterTeacher = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation("translation");
  const teacherReducer = useSelector((state) => state.teacherReducer);
  const { loading, error, teacher } = teacherReducer;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [rowEnable, setRowEnable] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [email, setEmail] = useState("");
  const [isEnabledFormAddAdmin, setIsEnabledFormAddAdmin] = useState(false);
  const [isEnabledFormEditAdmin, setIsEnabledFormEditAdmin] = useState(false);
  const [currentTeacher, setCurrentTeacher] = useState();

  const headCells = [
    {
      id: "nameProfessor",
      numeric: false,
      disablePadding: true,
      label: t("teacher.headCells.name"),
    },
    {
      id: "professorEnrollment",
      numeric: false,
      disablePadding: false,
      label: t("teacher.headCells.registerCode"),
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: t("teacher.headCells.email"),
    },
    {
      id: "isContentCreator",
      numeric: false,
      disablePadding: false,
      label: t("teacher.headCells.contentCreator"),
    },
    {
      id: "isActive",
      numeric: false,
      disablePadding: false,
      label: t("teacher.headCells.isActive"),
    },
  ];
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, teacher.length - page * rowsPerPage);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = teacher.map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const toggleFormAdmin = () => {
    setIsEnabledFormAddAdmin((prev) => !prev);
    setIsEnabledFormEditAdmin(false);
  };

  const toggleFormEdit = (isEnable) => {
    setIsEnabledFormAddAdmin(false);
    setIsEnabledFormEditAdmin(isEnable);
  };

  const focusOnlastItemAdded = () => {
    setRowEnable(true);
    setTimeout(function () {
      setRowEnable(false);
    }, 3000);
  };

  const addHandler = async (formValues) => {
    setEmail(formValues.email);
    await dispatch(
      addTeacher(
        formValues.name,
        formValues.email,
        formValues.registerCode,
        formValues.contentCreator
      )
    );
    await dispatch(listTeacher());
    setSelected([]);
    focusOnlastItemAdded();
  };

  const deleteHandler = (teacher) => {
    const configDialog = {
      title: t("teacher.deleteDialog.title"),
      message: `${t("teacher.deleteDialog.firstMessage")} "${
        teacher.nameProfessor
      }", ${t("teacher.deleteDialog.secondMessage")}`,
      onSubmit: async function () {
        await dispatch(deleteTeacher(teacher))
          .then(async (result) => {
            await dispatch({ type: "CUSTOM_CONFIRMATION_DIALOG_CLOSED" });
            await dispatch(listTeacher());
            setSelected([]);
          })
          .catch((err) => {
            dispatch({
              type: "TEACHER_DELETE_FAIL",
              payload: error,
            });
          });
      },

      close: async function () {
        await dispatch({ type: "CUSTOM_CONFIRMATION_DIALOG_CLOSED" });
      },
      buttons: {
        cancel: t("teacher.deleteDialog.buttons.cancel"),
        confirm: t("teacher.deleteDialog.buttons.confirm"),
      },
    };

    dispatch(
      showCustomConfirmationDialog({
        ...configDialog,
      })
    );
  };

  const editHandler = (teacher) => {
    toggleFormEdit(false);
    setCurrentTeacher(teacher);
    const configDialog = {
      title: t("teacher.editDialog.title"),
      message: `${t("teacher.editDialog.firstMessage")} "${
        teacher.nameProfessor
      }", ${t("teacher.editDialog.secondMessage")}`,
      onSubmit: function () {
        toggleFormEdit(true);
      },
      close: async function () {
        await dispatch({ type: "CUSTOM_CONFIRMATION_DIALOG_CLOSED" });
      },
      buttons: {
        cancel: t("teacher.editDialog.buttons.cancel"),
        confirm: t("teacher.editDialog.buttons.confirm"),
      },
    };
    dispatch(
      showCustomConfirmationDialog({
        ...configDialog,
      })
    );
  };

  const updateHandler = async (formValues) => {
    setEmail(formValues.email);
    await dispatch(
      updateTeacher(
        currentTeacher.id,
        formValues.name,
        formValues.email,
        formValues.registerCode,
        formValues.contentCreator
      )
    );
    await dispatch(listTeacher());
    setSelected([]);
    focusOnlastItemAdded();
  };

  const formConfigI18n = {
    singleDeleteButton: t("teacher.singleDeleteButton"),
    singleDeleteMultipleButton: t("teacher.singleDeleteMultipleButton"),
    addForm: {
      title: t("teacher.addForm.title"),
      cancelButton: t("teacher.addForm.cancelButton"),
      confirmButton: t("teacher.addForm.confirmButton"),
      name: {
        requiredErrorMsg: t("teacher.addForm.name.requiredErrorMsg"),
        label: t("teacher.addForm.name.label"),
        placeholder: t("teacher.addForm.name.placeholder"),
      },
      email: {
        requiredErrorMsg: t("teacher.addForm.email.requiredErrorMsg"),
        label: t("teacher.addForm.email.label"),
        placeholder: t("teacher.addForm.email.placeholder"),
      },
      registerCode: {
        requiredErrorMsg: t("teacher.addForm.registerCode.requiredErrorMsg"),
        label: t("teacher.addForm.registerCode.label"),
        placeholder: t("teacher.addForm.registerCode.placeholder"),
      },
      contentCreator: t("teacher.addForm.contentCreator"),
    },
    editForm: {
      title: t("teacher.editForm.title"),
      cancelButton: t("teacher.editForm.cancelButton"),
      confirmButton: t("teacher.editForm.confirmButton"),
      name: {
        requiredErrorMsg: t("teacher.editForm.name.requiredErrorMsg"),
        label: t("teacher.editForm.name.label"),
        placeholder: t("teacher.editForm.name.placeholder"),
      },
      email: {
        requiredErrorMsg: t("teacher.editForm.email.requiredErrorMsg"),
        label: t("teacher.editForm.email.label"),
        placeholder: t("teacher.editForm.email.placeholder"),
      },
      registerCode: {
        requiredErrorMsg: t("teacher.addForm.registerCode.requiredErrorMsg"),
        label: t("teacher.addForm.registerCode.label"),
        placeholder: t("teacher.addForm.registerCode.placeholder"),
      },
      contentCreator: t("teacher.editForm.contentCreator"),
    },
  };

  useEffect(() => {
    dispatch(listTeacher());
  }, [dispatch]);

  return (
    <Layout title={t("teacher.title")}>
      <Container disableGutters maxWidth={false}>
        <Box
          component="span"
          display={
            isEnabledFormAddAdmin || isEnabledFormEditAdmin ? "none" : "block"
          }
        >
          <Grid item xs={12} sm={12} lg={12}>
            <div className={classes.buttons}>
              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={() => setIsEnabledFormAddAdmin((prev) => !prev)}
              >
                {t("teacher.addButton")}
              </Button>
            </div>
          </Grid>
        </Box>

        <Add
          title={formConfigI18n.addForm.title}
          cancelButton={formConfigI18n.addForm.cancelButton}
          confirmButton={formConfigI18n.addForm.confirmButton}
          name={formConfigI18n.addForm.name}
          email={formConfigI18n.addForm.email}
          registerCode={formConfigI18n.addForm.registerCode}
          contentCreator={formConfigI18n.addForm.contentCreator}
          isEnabledFormAddAdmin={!isEnabledFormAddAdmin}
          loading={loading}
          addHandler={addHandler}
          show={toggleFormAdmin}
        />

        <Edit
          title={formConfigI18n.editForm.title}
          cancelButton={formConfigI18n.editForm.cancelButton}
          confirmButton={formConfigI18n.editForm.confirmButton}
          name={formConfigI18n.editForm.name}
          email={formConfigI18n.editForm.email}
          registerCode={formConfigI18n.editForm.registerCode}
          contentCreator={formConfigI18n.editForm.contentCreator}
          currentTeacher={currentTeacher}
          isEnabledFormEditAdmin={!isEnabledFormEditAdmin}
          loading={loading}
          editHandler={updateHandler}
          show={toggleFormEdit}
        />

        <>
          {loading ? (
            <CustomSpinner />
          ) : error ? (
            <NotFound
              title="Oops!"
              message="Ocorreu um erro, tente recarregar a página"
              image="/images/notfound.png"
            />
          ) : (
            <div>
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={"medium"}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    headCells={headCells}
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={teacher.length}
                  />
                  <TableBody>
                    {stableSort(teacher, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            key={row.professorEnrollment}
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={0}
                            selected={isItemSelected}
                            className={
                              row.email === email && rowEnable
                                ? classes.rowEnable
                                : ""
                            }
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                onClick={(event) => handleClick(event, row)}
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                              <IconButton
                                aria-label="edit"
                                onClick={(event) => editHandler(row)}
                              >
                                <EditIcon color="secondary" />
                              </IconButton>
                            </TableCell>
                            <TableCell padding="none">
                              {row.nameProfessor}
                            </TableCell>
                            <TableCell align="left">
                              {row.professorEnrollment}
                            </TableCell>
                            <TableCell align="left">{row.email}</TableCell>
                            <TableCell align="left">
                              {row.isContentCreator ? (
                                <Switch disabled checked={true} />
                              ) : (
                                <Switch disabled checked={false} />
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {row.active === 0 ? (
                                <CloseIcon color="primary" />
                              ) : (
                                <CheckIcon color="primary" />
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box display="flex" p={1}>
                <Box p={1} flexGrow={1}>
                  <TablePagination
                    rowsPerPageOptions={[10, 25]}
                    component="div"
                    count={teacher.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Box>
                <Box p={1} flexGrow={1}>
                  <RecordChangeButtons
                    singleDeleteButton={formConfigI18n.singleDeleteButton}
                    singleDeleteMultipleButton={
                      formConfigI18n.singleDeleteMultipleButton
                    }
                    itensSelected={selected}
                    handlerSingle={deleteHandler}
                    icon={<DeleteIcon />}
                  />
                </Box>
              </Box>
            </div>
          )}
        </>
      </Container>
    </Layout>
  );
};

export { RegisterTeacher };
