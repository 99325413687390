import { createTheme } from "@material-ui/core/styles";

const darkTheme = createTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "*": {
          "scrollbar-width": "thin",
          "scrollbar-color": "#38383c #222224",
        },
        "*::-webkit-scrollbar-track": {
          background: "#222224",
        },
        "*::-webkit-scrollbar-thumb": {
          background: "#38383c",
          "border-radius": "20px",
          border: "3px solid #222224",
        },
      },
    },
  },
  palette: {
    common: {
      black: "#000",
      white: "#fff",
    },
    background: {
      paper: "#2a2a2a",
      default: "#262626",
    },
    primary: {
      light: "#9fce5e",
      main: "#7FB539",
      dark: "#578d26",
      contrastText: "#fff",
    },
    secondary: {
      light: "#464771",
      main: "#151240",
      dark: "#000000",
      contrastText: "#fff",
    },
    error: {
      light: "#f37b71",
      main: "#F15B4E",
      dark: "#a83f36",
      contrastText: "#fff",
    },
    text: {
      primary: "#9d9d9d",
      secondary: "#7b7b7b",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
    custom: {
      color_1: "#0e0c2c",
      color_2: "#a83f36",
      color_3: "#401d37",
      color_4: "#b9b8c6",
    },
    fisica: {
      backgroundColor: "#55b3c9",
      color: "#48438a",
    },
    quimica: {
      backgroundColor: "#8a4077",
      color: "#FBC124",
    },
    biologia: {
      backgroundColor: "#579078",
      color: "#72cbde",
    },
    ciencias: {
      backgroundColor: "#d96c64",
      color: "#2e2d29",
    },
  },
});

export default darkTheme;
