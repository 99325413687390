import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { forgotPassword } from "../../../store/updatePassword";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  email: {
    display: "flex",
    width: 100,
    margin: "1rem auto",
  },
}));

const ForgotPassword = (props) => {
  const userLogin = useSelector((state) => state.updatePasswordReducer);
  const { userInfo } = userLogin;
  const updatePassword = useSelector((state) => state.updatePasswordReducer);
  const { loading, error, status } = updatePassword;
  const { isForgot } = props;
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation("translation");

  useEffect(() => {
    if (userInfo) {
      history.push("/app");
    }
  }, [history, userInfo]);

  const schema = yup.object().shape({
    email: yup.string().email().required(t("signIn.email.requiredErrorMsg")),
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const submitHandler = (formValues) => {
    dispatch(forgotPassword(formValues.email));
  };

  return (
    <>
      <Typography variant="h5" component="h1" gutterBottom>
        {!loading && status
          ? ""
          : !loading && !status
          ? "Redefinir senha"
          : "Enviando email..."}
      </Typography>

      <form
        className={classes.form}
        onSubmit={handleSubmit(submitHandler)}
        noValidate
      >
        {!status && (
          <>
            <TextField
              inputRef={register}
              error={errors.email && true}
              helperText={errors.email && t("signIn.email.requiredErrorMsg")}
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label={t("signIn.email.label")}
              placeholder={t("signIn.email.placeholder")}
              name="email"
              autoComplete="email"
              autoFocus
              disabled={loading}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loading}
            >
              {t("signIn.loginButton")}
            </Button>
          </>
        )}
        {!loading && status ? (
          <>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              align="center"
              component="h2"
              gutterBottom
            >
              Sucesso! Verifique sua caixa de email, e siga as instruções no
              email.
            </Typography>
            <img
              alt="Email"
              src="/images/email.png"
              className={classes.email}
            />
          </>
        ) : (
          ""
        )}

        <Button
          onClick={() => isForgot(false)}
          component={Link}
          color="primary"
          disabled={loading}
        >
          Voltar
        </Button>

        {loading ? (
          <LinearProgress />
        ) : error ? (
          <Typography
            variant="subtitle1"
            color="secondary"
            align="center"
            component="h3"
            gutterBottom
          >
            {t("signIn.loginErrorMsg")}
          </Typography>
        ) : (
          ""
        )}
      </form>
    </>
  );
};

export { ForgotPassword };
