import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { yupResolver } from "@hookform/resolvers/yup";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Checkbox from "@material-ui/core/Checkbox";
import SchoolClassDialog from "./SchoolClassDialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";
import { studentSchema } from "./schema/student";
import { useForm } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  buttonAdd: {
    marginLeft: theme.spacing(1),
  },
  error: {
    border: "1px solid" + theme.palette.error.main,
  },
}));

const Edit = (props) => {
  const classes = useStyles();
  const {
    isEnabledFormEditAdmin,
    editHandler,
    loading,
    show,
    title,
    saveButtonLabel,
    titleSchoolClassDialog,
    name,
    currentStudent,
    registrationCode,
    resetUniqueCode,
    schoolClassButton,
    cancelButton,
    confirmButton,
  } = props;
  const { register, handleSubmit, errors, setValue, clearErrors } = useForm({
    mode: "onChange",
    resolver: yupResolver(studentSchema()),
  });

  const [openPopUp, setOpenPopUp] = useState(false);

  const [currentSchoolClass, setCurrentSchoolClass] = useState("");

  const [inputState, setInputState] = useState({
    name: currentStudent?.nameStudent,
    registrationCode: currentStudent?.enrollmentStudent,
    schoolClass: currentStudent?.schoolClass,
  });

  const handleChangeInput = (event) => {
    setInputState({
      ...inputState,
      [event.target.name]: event.target.value,
    });
  };

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevVisibility = usePrevious(currentStudent);

  useEffect(() => {
    if (prevVisibility !== currentStudent) {
      const newState = {
        ...inputState,
        name: currentStudent?.nameStudent,
        registrationCode: currentStudent.enrollmentStudent,
        schoolClass: currentStudent?.schoolClass,
      };

      register("schoolClass", currentStudent?.schoolClass);
      setValue("schoolClass", newState.schoolClass);
      setInputState(newState);
      setCurrentSchoolClass(currentStudent.schoolClass);
    }
  }, [currentStudent, inputState, prevVisibility, register, setValue]);

  const onsubmit = (data) => {
    editHandler({ ...data, currentSchoolClass });
  };

  const getDialogData = (data) => {
    const schoolClass = data.schoolClass.split("-");
    const currentClass = `Período ${schoolClass[0]} - Ano ${schoolClass[1]} - Turma ${schoolClass[2]}`;
    register("schoolClass", currentClass);
    setValue("schoolClass", data.schoolClass);
    setCurrentSchoolClass(currentClass);
    clearErrors("schoolClass");
  };

  return (
    <>
      <Box component="span" display={isEnabledFormEditAdmin ? "none" : "block"}>
        <Paper elevation={1}>
          <Container maxWidth={false} className={classes.container}>
            <Typography
              className={classes.title}
              variant="h5"
              component="h1"
              gutterBottom
            >
              {title}
            </Typography>

            <form fullWidth onSubmit={handleSubmit(onsubmit)} noValidate>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} lg={4}>
                  <TextField
                    inputRef={register}
                    error={errors.name && true}
                    helperText={errors.name && name.requiredErrorMsg}
                    id="name"
                    name="name"
                    label={name.label}
                    placeholder={name.placeholder}
                    value={inputState.name}
                    onChange={handleChangeInput}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={4}>
                  <TextField
                    inputRef={register}
                    error={errors.registrationCode && true}
                    helperText={
                      errors.registrationCode &&
                      registrationCode.requiredErrorMsg
                    }
                    id="registrationCode"
                    name="registrationCode"
                    label={registrationCode.label}
                    placeholder={registrationCode.placeholder}
                    value={inputState.registrationCode}
                    onChange={handleChangeInput}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={4}>
                  <Button
                    variant="outlined"
                    endIcon={<ArrowForwardIosIcon />}
                    onClick={() => setOpenPopUp(true)}
                    className={errors.schoolClass ? classes.error : ""}
                  >
                    {currentSchoolClass !== ""
                      ? currentSchoolClass
                      : schoolClassButton}
                  </Button>
                </Grid>

                <Grid item xs={12} sm={12} lg={6}>
                  <Tooltip title={resetUniqueCode.tooltip} arrow>
                    <FormControlLabel
                      control={
                        <Checkbox
                          inputRef={register}
                          id="resetUniqueCode"
                          name="resetUniqueCode"
                        />
                      }
                      label={resetUniqueCode.label}
                    />
                  </Tooltip>
                </Grid>

                <Grid item xs={12} sm={12} lg={12}>
                  <div className={classes.buttons}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      disabled={loading}
                      onClick={() => show()}
                    >
                      {cancelButton}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.buttonAdd}
                      disabled={loading}
                    >
                      {confirmButton}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Paper>
      </Box>
      <SchoolClassDialog
        title={titleSchoolClassDialog}
        saveButtonLabel={saveButtonLabel}
        currentSchoolClassData={inputState.schoolClass}
        onClose={getDialogData}
        openPopUp={openPopUp}
        setOpenPopUp={setOpenPopUp}
      />
    </>
  );
};

export default Edit;
