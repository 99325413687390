import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ReactHookFormSelect from "../../../components/common/form/ReactHookFormSelect";
import ReactHookFormRadio from "../../../components/common/form/ReactHookFormRadio";
import ReactHookFormAutocomplete from "../../../components/common/form/ReactHookFormAutocomplete";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import CustomSpinner from "../../../components/common/Spinner/CustomSpinner";
import NotFound from "../../../components/common/notFound/NotFound";
import { listSchoolClassConfigProfessor } from "../../../store/duck/student/schoolClassConfig";
import { contentFormSchema } from "./schema/contentForm";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import { merge } from "lodash";

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  li: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    listStyle: "none",
    margin: 0,
  },
  icon: {
    fill: theme.palette.secondary.main,
  },
  titleNumber: {
    color: theme.palette.primary.main,
  },
}));

const ContentForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const schoolClassConfigReducer = useSelector(
    (state) => state.schoolClassConfigReducer
  );
  const { loading, error, schoolClassConfig } = schoolClassConfigReducer;
  const { t } = useTranslation("translation");
  const { onClose, enableNextButtom, schoolClassData, formConfigI18n } = props;

  const subject = [
    { title: "Atomística" },
    { title: "Biologia celular" },
    { title: "Botânica" },
    { title: "Cinemática" },
    { title: "Dinâmica" },
    { title: "Ecologia" },
    { title: "Eletricidade" },
    { title: "Eletromagnetismo" },
    { title: "Embriologia" },
    { title: "Energia/Trabalho" },
    { title: "Físico-química" },
    { title: "Fisiologia animal" },
    { title: "Genética" },
    { title: "Geral" },
    { title: "Gravitação" },
    { title: "Inorgânica" },
    { title: "Introdução" },
    { title: "Ondulatória" },
    { title: "Óptica" },
    { title: "Orgânica" },
    { title: "Outros" },
    { title: "Termofísica" },
    { title: "Zoologia" },
  ];

  const disciplines = [
    { title: "Língua Portuguesa" },
    { title: "Língua estrangeira" },
    { title: "Arte" },
    { title: "Educação Física" },
    { title: "Matemática" },
    { title: "Física" },
    { title: "Química" },
    { title: "Biologia" },
    { title: "História" },
    { title: "Geografia" },
    { title: "Filosofia" },
    { title: "Sociologia" },
    { title: "Ciências" },
  ];
  const { register, handleSubmit, errors, control } = useForm({
    resolver: yupResolver(contentFormSchema),
  });

  const { title, schoolGrade, schoolClass, tags, contentName, discipline } =
    formConfigI18n.contentForm;

  const onsubmit = (dialogData) => {
    if (Object.keys(dialogData).length === 0) {
      onClose({});
    } else {
      const data = {
        next: {},
        data: dialogData,
      };
      onClose(data);
    }
  };

  const getSchoolClass = () => {
    const turmas = _(schoolClassConfig)
      .map((x) => x.turmas)
      .value();

    //TODO: temporary bug fix, need refactor
    let temp = [];
    const result = merge(turmas).map((t) => t.map((z) => temp.push(z)));
    return temp;
  };

  const getYears = () => {
    const years = _(getSchoolClass())
      .map((t) => t.ano)
      .uniq()
      .sort((a, b) => a - b)
      .value();

    return years;
  };

  const getSchoolClassByYear = (year) => {
    const schoolClassByYear = _(getSchoolClass())
      .filter((t) => t.ano === year)
      .map((t) => t.turma)
      .sortBy("turma")
      .value();

    return schoolClassByYear;
  };

  const [currentSchoolClass, setCurrentSchoolClass] = useState(() => {
    const year = getYears()[0];
    const initialState = getSchoolClassByYear(year);
    return initialState;
  });

  const handleYear = (year) => {
    if (year === 0) return;
    const schoolClassByYear = getSchoolClassByYear(year);
    setCurrentSchoolClass(schoolClassByYear);
  };

  const translateSchoolGrade = (year, formConfigI18n) => {
    switch (year) {
      case 1:
        return `${year}º ${formConfigI18n.EM}`;
      case 2:
        return `${year}º ${formConfigI18n.EM}`;
      case 3:
        return `${year}º ${formConfigI18n.EM}`;
      case 4:
        return `${year}º ${formConfigI18n.EM1}`;
      case 5:
        return `${year}º ${formConfigI18n.EM1}`;
      case 6:
        return `${year}º ${formConfigI18n.EM2}`;
      case 7:
        return `${year}º ${formConfigI18n.EM2}`;
      case 8:
        return `${year}º ${formConfigI18n.EM2}`;
      case 9:
        return `${year}º ${formConfigI18n.EM2}`;
      default:
        return `${year}`;
    }
  };

  useEffect(() => {
    dispatch(listSchoolClassConfigProfessor());
  }, [dispatch]);

  return (
    <>
      {loading ? (
        <CustomSpinner />
      ) : error ? (
        <NotFound
          title="Oops!"
          message="Ocorreu um erro, tente recarregar a página"
          image="/images/notfound.png"
          redirect="/app/content"
        />
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography align="left" variant="h5" component="h2">
                <span className={classes.titleNumber}>1.</span> {title}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ReactHookFormSelect
                error={errors.schoolGrade && true}
                helperText={errors.schoolGrade && schoolGrade.requiredErrorMsg}
                id="schoolGrade"
                name="schoolGrade"
                label={schoolGrade && schoolGrade.label}
                style={{ width: "100%" }}
                control={control}
                onClick={(event) => handleYear(event.target.value)}
              >
                {!loading &&
                  !error &&
                  getYears().map((ano) => (
                    <MenuItem value={ano}>
                      {translateSchoolGrade(ano, schoolGrade.codeName)}
                    </MenuItem>
                  ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item xs={12}>
              <ReactHookFormAutocomplete
                key={currentSchoolClass}
                inputRef={register}
                control={control}
                id="schoolClass"
                name="schoolClass"
                limitTags={6}
                options={currentSchoolClass}
                helperText={errors.schoolClass && schoolClass.requiredErrorMsg}
                //defaultValue={getSchoolClassByYear(getYears()[0])}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={schoolClass && schoolClass.label}
                    size="small"
                    placeholder={schoolClass && schoolClass.placeholder}
                  />
                )}
                renderTags={(tags, getTagProps) =>
                  tags.map((option, index) => (
                    <Chip
                      variant="outlined"
                      color="primary"
                      label={option}
                      size="small"
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </Grid>
            {/* <Grid item xs={12}>
              <ReactHookFormAutocomplete
                inputRef={register}
                control={control}
                id="tags"
                name="tags"
                limitTags={6}
                options={subject}
                helperText={errors.tags && tags.requiredErrorMsg}
                defaultValue={subject[0]}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={tags && tags.label}
                    size="small"
                    placeholder="tags"
                  />
                )}
                renderTags={(tags, getTagProps) =>
                  tags.map((option, index) => (
                    <Chip
                      variant="outlined"
                      color="primary"
                      label={option.title}
                      size="small"
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </Grid> */}
            <Grid item xs={12}>
              <TextField
                inputRef={register}
                error={errors.contentName && true}
                helperText={
                  errors.contentName &&
                  t("publish.contentForm.contentName.requiredErrorMsg")
                }
                id="contentName"
                name="contentName"
                label={contentName && contentName.label}
                placeholder={contentName && contentName.placeholder}
                fullWidth
              />
            </Grid>
            {/* <Grid item xs={12}>
              <ReactHookFormAutocomplete
                inputRef={register}
                control={control}
                id="discipline"
                name="discipline"
                limitTags={6}
                options={disciplines}
                helperText={errors.discipline && discipline.requiredErrorMsg}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={discipline && discipline.label}
                    size="small"
                    placeholder="Disciplina"
                  />
                )}
                renderTags={(discipline, getTagProps) =>
                  discipline.map((option, index) => (
                    <Chip
                      variant="outlined"
                      color="primary"
                      label={option.title}
                      size="small"
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </Grid> */}
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleSubmit(onsubmit)}
                >
                  {t("publish.contentForm.nextButton")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default ContentForm;
