import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import translationsEN from './en/en.json'
import translationsPTBR from './pt-br/pt-br.json'
import translationsFR from './fr/fr.json'

const resources = {
  en: {
    translation: translationsEN,
  },
  'pt-BR': {
    translation: translationsPTBR,
  },
  fr: {
    translation: translationsFR,
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'en', // default language

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
