import React from "react";
import { useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";

export default function CustomConfirmationDialog() {
  const { customConfirmationDialogConfig } = useSelector(
    (state) => state.dialogReducer
  );

  return (
    <Dialog
      open={
        customConfirmationDialogConfig &&
        Boolean(customConfirmationDialogConfig.onSubmit)
      }
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {customConfirmationDialogConfig && customConfirmationDialogConfig.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {customConfirmationDialogConfig &&
            customConfirmationDialogConfig.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (
              customConfirmationDialogConfig &&
              customConfirmationDialogConfig.close
            ) {
              customConfirmationDialogConfig.close();
            }
          }}
          color="secondary"
        >
          {customConfirmationDialogConfig &&
            customConfirmationDialogConfig.cancel}
        </Button>
        <Button
          onClick={() => {
            if (
              customConfirmationDialogConfig &&
              customConfirmationDialogConfig.onSubmit
            ) {
              customConfirmationDialogConfig.onSubmit();
            }
            customConfirmationDialogConfig.close();
          }}
          variant="contained"
          color="primary"
          autoFocus
        >
          {customConfirmationDialogConfig &&
            customConfirmationDialogConfig.confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
