import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { Badge } from "@material-ui/core";
import Zoom from "@material-ui/core/Zoom";
import { LibraryIcon } from "../../../icons/CustomIcons";
import CustomTooltip from "../../customTooltip/CustomTooltip";
import CustomIconButton from "../../customIconButton/CustomIconButton";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  selected: {
    "&$selected": {
      textDecoration: "none",
      backgroundColor: theme.palette.secondary.main,
      borderRadius: "10px",
    },
  },
}));

const NavBarCart = (props) => {
  const { location } = props;
  const classes = useStyles();
  const { t } = useTranslation("translation");
  const i18nConfig = {
    publish: {
      label: t("menu.navBar.publish.label"),
    },
  };

  return (
    <Link to="/app/publish">
      <CustomTooltip
        title={i18nConfig.publish.label}
        arrow
        placement="bottom"
        TransitionComponent={Zoom}
      >
        <CustomIconButton
          color="primary"
          className={"/app/publish" === location && classes.selected}
        >
          <Badge badgeContent={props.itemsInTheCart} color="error">
            <LibraryIcon color="primary" />
          </Badge>
        </CustomIconButton>
      </CustomTooltip>
    </Link>
  );
};

const mapStateToProps = (state) => {
  const cart = state.cart;

  if (cart) {
    return { itemsInTheCart: cart.items.length > 0 ? cart.items.length : "0" };
  }
};

export default connect(mapStateToProps)(NavBarCart);
