import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import HomeIcon from "@material-ui/icons/Home";
import Image from "material-ui-image";

const NotFound = ({ title, message, buttonText, image, redirect, icon }) => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={12} sm={6}>
        <Typography align="center" variant="h5" gutterBottom>
          {title}
        </Typography>
        <Typography align="center" variant="body1" gutterBottom>
          {message}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container direction="column" justifyContent="space-between">
          {image && <Image cover={false} color alt={message} src={image} />}
          {buttonText && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              component={Link}
              to={redirect ? redirect : "/app"}
              startIcon={icon ? icon : <HomeIcon fontSize="large" />}
            >
              {buttonText}
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NotFound;
