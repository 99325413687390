import { UserRole } from "./userRole";
import AddIcon from "@material-ui/icons/Add";
import UpdateIcon from "@material-ui/icons/Update";
import GolfCourseIcon from "@material-ui/icons/GolfCourse";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import PublishIcon from "@material-ui/icons/Publish";
import BugReportIcon from "@material-ui/icons/BugReport";
import FeedbackIcon from "@material-ui/icons/Feedback";
import SettingsIcon from "@material-ui/icons/Settings";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import SchoolIcon from "@material-ui/icons/School";
import {
  HomeIcon,
  ReportIcon,
  LibraryIcon,
  MyActivitiesIcon,
} from "../icons/CustomIcons";
import {
  InteractionsLibrary,
  MyActivities,
  Dashboard,
  Faq,
  BugReport,
  Feedback,
  InteractionDetailsScreen,
  PerformanceIndicatorsScreen,
  PublishScreen,
  RegisterContentManagerScreen,
  RegisterClient,
  RegisterStudent,
  RegisterSchoolClass,
  RegisterContent,
  MyContent,
  UpdateContent,
  RegisterTeacher,
  Performance,
  Settings,
  Tutorial,
  RegisterAdmin,
  RegisterContractor,
} from "../pages";

const Config = [
  {
    component: Dashboard,
    path: "/",
    title: "Dashboard",
    icon: HomeIcon,
    exact: true,
    permission: [
      UserRole.PROFESSOR,
      UserRole.CRIADOR_DE_CONTEUDO_EXTERNO,
      UserRole.TIME_CONTEUDO_XPERIENCE,
      UserRole.ADMINISTRADOR_CONTRATANTE,
      UserRole.ADMINISTRADOR_CLIENTE,
      UserRole.ADMINISTRADOR_MASTER,
    ],
    display: true,
  },
  {
    component: RegisterAdmin,
    path: "/admin-master",
    title: "Administrador Master",
    icon: SupervisorAccountIcon,
    permission: [UserRole.ADMINISTRADOR_MASTER],
    display: false,
  },
  {
    component: RegisterContractor,
    path: "/contractor",
    title: "contractor",
    icon: SupervisorAccountIcon,
    permission: [UserRole.ADMINISTRADOR_MASTER],
    display: true,
  },
  {
    component: RegisterContentManagerScreen,
    path: "/content-manager",
    title: "contentManager",
    icon: SupervisorAccountIcon,
    permission: [UserRole.ADMINISTRADOR_MASTER],
    display: false,
  },
  {
    component: RegisterClient,
    path: "/client",
    title: "client",
    icon: SchoolIcon,
    permission: [UserRole.ADMINISTRADOR_CONTRATANTE],
    display: true,
  },
  {
    component: RegisterTeacher,
    path: "/teacher",
    title: "teacher",
    icon: SupervisorAccountIcon,
    permission: [UserRole.ADMINISTRADOR_CLIENTE],
    display: true,
  },
  {
    component: RegisterStudent,
    path: "/student",
    title: "student",
    icon: SupervisorAccountIcon,
    permission: [UserRole.ADMINISTRADOR_CLIENTE],
    display: true,
  },
  {
    component: RegisterSchoolClass,
    path: "/schoolClass",
    title: "schoolClass",
    icon: AddIcon,
    permission: [UserRole.ADMINISTRADOR_CLIENTE],
    display: true,
  },
  {
    component: RegisterContent,
    path: "/register-content/",
    title: "Cadastro de Conteúdo",
    icon: AddIcon,
    display: true,
    permission: [
      UserRole.TIME_CONTEUDO_XPERIENCE,
      UserRole.CRIADOR_DE_CONTEUDO_EXTERNO,
      UserRole.ADMINISTRADOR_MASTER,
    ],
  },
  {
    component: MyContent,
    path: "/my-content/",
    title: "myContent",
    icon: UpdateIcon,
    display: true,
    exact: true,
    permission: [
      UserRole.TIME_CONTEUDO_XPERIENCE,
      UserRole.CRIADOR_DE_CONTEUDO_EXTERNO,
      UserRole.ADMINISTRADOR_MASTER,
    ],
  },
  {
    component: UpdateContent,
    path: "/my-content/update-content/:id",
    title: "contentUpdate",
    icon: UpdateIcon,
    display: false,
    exact: true,
    permission: [
      UserRole.TIME_CONTEUDO_XPERIENCE,
      UserRole.CRIADOR_DE_CONTEUDO_EXTERNO,
      UserRole.ADMINISTRADOR_MASTER,
    ],
  },
  {
    component: InteractionsLibrary,
    path: "/content",
    title: "Lista de Conteúdos",
    icon: LibraryIcon,
    permission: [UserRole.PROFESSOR, UserRole.ADMINISTRADOR_MASTER],
    display: true,
    exact: true,
    children: [],
  },
  {
    component: InteractionDetailsScreen,
    path: "/content/:id",
    title: "Detalhes",
    display: false,
    exact: true,
    permission: [UserRole.PROFESSOR, UserRole.ADMINISTRADOR_MASTER],
  },
  {
    component: InteractionsLibrary,
    path: "/content/page/:pageNumber",
    title: "Paginação",
    display: false,
    exact: true,
    permission: [UserRole.PROFESSOR, UserRole.ADMINISTRADOR_MASTER],
  },
  {
    component: InteractionsLibrary,
    path: "/content/search/:keyword",
    title: "Busca",
    display: false,
    exact: true,
    permission: [UserRole.PROFESSOR, UserRole.ADMINISTRADOR_MASTER],
  },
  {
    component: InteractionsLibrary,
    path: "/content/tags/:tags",
    title: "Tags",
    display: false,
    exact: true,
    permission: [UserRole.PROFESSOR, UserRole.ADMINISTRADOR_MASTER],
  },
  {
    component: MyActivities,
    path: "/my-activities",
    title: "myActivities",
    icon: MyActivitiesIcon,
    permission: [UserRole.PROFESSOR],
    display: true,
    exact: true,
    children: [],
  },
  {
    component: PublishScreen,
    path: "/publish/",
    title: "Edição e Publicação",
    icon: PublishIcon,
    display: false,
    permission: [UserRole.PROFESSOR],
  },
  // {
  //   component: PerformanceIndicatorsScreen,
  //   path: '/performance-indicators',
  //   title: 'Indicadores de performance',
  //   icon: BarChartIcon,
  //   display: true,
  //   exact: true,
  //   permission: [
  //     UserRole.PROFESSOR,
  //   ],
  // },
  {
    component: Performance,
    path: "/performance-indicators/reports/performance",
    title: "Relatórios",
    icon: ReportIcon,
    display: true,
    exact: true,
    permission: [UserRole.PROFESSOR],
  },
  // {
  //   component: Performance,
  //   path: '/performance-indicators/reports/performance',
  //   title: 'Relatórios',
  //   icon: ReportIcon,
  //   display: true,
  //   exact: true,
  //   permission: [
  //     UserRole.PROFESSOR,
  //   ],
  // },
  {
    component: Faq,
    path: "/support/faq",
    title: "FAQ",
    icon: LiveHelpIcon,
    display: false,
    permission: [
      UserRole.PROFESSOR,
      UserRole.CRIADOR_DE_CONTEUDO_EXTERNO,
      UserRole.TIME_CONTEUDO_XPERIENCE,
      UserRole.ADMINISTRADOR_CONTRATANTE,
      UserRole.ADMINISTRADOR_CLIENTE,
      UserRole.ADMINISTRADOR_MASTER,
    ],
  },
  {
    component: Feedback,
    path: "/support/feedback",
    title: "Feedback",
    icon: BugReportIcon,
    display: false,
    permission: [
      UserRole.PROFESSOR,
      UserRole.CRIADOR_DE_CONTEUDO_EXTERNO,
      UserRole.TIME_CONTEUDO_XPERIENCE,
      UserRole.ADMINISTRADOR_CONTRATANTE,
      UserRole.ADMINISTRADOR_CLIENTE,
      UserRole.ADMINISTRADOR_MASTER,
    ],
  },
  {
    component: BugReport,
    path: "/support/bug-report",
    title: "BugReport",
    icon: FeedbackIcon,
    display: false,
    permission: [
      UserRole.PROFESSOR,
      UserRole.CRIADOR_DE_CONTEUDO_EXTERNO,
      UserRole.TIME_CONTEUDO_XPERIENCE,
      UserRole.ADMINISTRADOR_CONTRATANTE,
      UserRole.ADMINISTRADOR_CLIENTE,
      UserRole.ADMINISTRADOR_MASTER,
    ],
  },
  {
    component: Tutorial,
    path: "/tutorial",
    title: "Tutorial",
    icon: GolfCourseIcon,
    display: false,
    permission: [UserRole.ADMINISTRADOR_MASTER, UserRole.PROFESSOR],
  },
  {
    component: Settings,
    path: "/settings",
    title: "Configurações",
    icon: SettingsIcon,
    display: false,
    permission: [
      UserRole.PROFESSOR,
      UserRole.CRIADOR_DE_CONTEUDO_EXTERNO,
      UserRole.TIME_CONTEUDO_XPERIENCE,
      UserRole.ADMINISTRADOR_CONTRATANTE,
      UserRole.ADMINISTRADOR_CLIENTE,
      UserRole.ADMINISTRADOR_MASTER,
    ],
  },
];

export default Config;
