import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SignInForm } from "./components/signInForm";
import { ForgotPassword } from "./components/forgotPassword";
import UserForm from "../../components/common/hoc/UserForm";

const SignIn = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  let history = useHistory();
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  useEffect(() => {
    if (userInfo) {
      history.push("/app");
    }
  }, [history, userInfo]);

  return (
    <UserForm>
      {!isForgotPassword ? (
        <SignInForm isForgot={setIsForgotPassword} />
      ) : (
        <ForgotPassword isForgot={setIsForgotPassword} />
      )}
    </UserForm>
  );
};

export { SignIn };
