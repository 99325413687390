import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CustomSpinner from "../../components/common/Spinner/CustomSpinner";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import EnhancedTableHead from "../../components/common/table/EnhancedTableHead";
import Layout from "../../components/common/hoc/Layout";
import Add from "./components/add";
import Edit from "./components/edit";
import NotFound from "../../components/common/notFound/NotFound";
import RecordChangeButtons from "../../components/common/recordChangeButtons/RecordChangeButtons";
import { listContractor } from "../../store/contractor";
import { addContractor } from "../../store/contractor";
import { updateContractor } from "../../store/contractor";
import { deleteContractor } from "../../store/contractor";
import { showCustomConfirmationDialog } from "../../store/customConfirmationDialogReducer";
import { getComparator, stableSort } from "../../utils/tableFilter";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    marginTop: "1rem",
    minWidth: 750,
  },
  rowEnable: {
    outlineColor: theme.palette.secondary.main,
    outlineWidth: "2px",
    outlineStyle: "solid",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  buttonAdd: {
    marginLeft: theme.spacing(1),
  },
}));

const RegisterContractor = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation("translation");
  const contractorReducer = useSelector((state) => state.contractorReducer);
  const { loading, error, contractors } = contractorReducer;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [rowEnable, setRowEnable] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [email, setEmail] = useState("");
  const [isEnabledFormAddAdmin, setIsEnabledFormAddAdmin] = useState(false);
  const [isEnabledFormEditAdmin, setIsEnabledFormEditAdmin] = useState(false);
  const [currentContractor, setCurrentAdmin] = useState();
  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: t("contractor.headCells.name"),
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: t("contractor.headCells.email"),
    },
    {
      id: "role",
      numeric: false,
      disablePadding: true,
      label: t("contractor.headCells.role"),
    },
    {
      id: "cnpj",
      numeric: false,
      disablePadding: false,
      label: t("contractor.headCells.cnpj"),
    },
  ];
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, contractors.length - page * rowsPerPage);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = contractors.map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const toggleFormAdmin = () => {
    setIsEnabledFormAddAdmin((prev) => !prev);
    setIsEnabledFormEditAdmin(false);
  };

  const toggleFormEdit = (isEnable) => {
    setIsEnabledFormAddAdmin(false);
    setIsEnabledFormEditAdmin(isEnable);
  };

  const focusOnlastItemAdded = () => {
    setRowEnable(true);
    setTimeout(function () {
      setRowEnable(false);
    }, 3000);
  };

  const addHandler = async (formValues) => {
    setEmail(formValues.email);
    await dispatch(
      addContractor(formValues.name, formValues.email, formValues.cnpj)
    );
    await dispatch(listContractor());
    setSelected([]);
    focusOnlastItemAdded();
  };

  const deleteHandler = (contractor) => {
    const configDialog = {
      title: t("contractor.deleteDialog.title"),
      message: `${t("contractor.deleteDialog.firstMessage")} "${
        contractor.name
      }", ${t("contractor.deleteDialog.secondMessage")}`,
      onSubmit: async function () {
        await dispatch(deleteContractor(contractor))
          .then(async (result) => {
            dispatch({ type: "CUSTOM_CONFIRMATION_DIALOG_CLOSED" });
            await dispatch(listContractor());
            setSelected([]);
          })
          .catch((err) => {
            dispatch({
              type: "ADMIN_DELETE_FAIL",
              payload: error,
            });
          });
      },

      close: async function () {
        await dispatch({ type: "CUSTOM_CONFIRMATION_DIALOG_CLOSED" });
      },
      buttons: {
        cancel: t("contractor.deleteDialog.buttons.cancel"),
        confirm: t("contractor.deleteDialog.buttons.confirm"),
      },
    };

    dispatch(
      showCustomConfirmationDialog({
        ...configDialog,
      })
    );
  };

  const editHandler = (contractor) => {
    toggleFormEdit(false);
    setCurrentAdmin(contractor);
    const configDialog = {
      title: t("contractor.editDialog.title"),
      message: `${t("contractor.editDialog.firstMessage")} "${
        contractor.name
      }", ${t("contractor.editDialog.secondMessage")}`,
      onSubmit: function () {
        toggleFormEdit(true);
      },
      close: async function () {
        await dispatch({ type: "CUSTOM_CONFIRMATION_DIALOG_CLOSED" });
      },
      buttons: {
        cancel: t("contractor.editDialog.buttons.cancel"),
        confirm: t("contractor.editDialog.buttons.confirm"),
      },
    };
    dispatch(
      showCustomConfirmationDialog({
        ...configDialog,
      })
    );
  };

  const updateHandler = async (formValues) => {
    setEmail(formValues.email);
    await dispatch(
      updateContractor(
        currentContractor?.id,
        formValues.name,
        formValues.email,
        formValues.cnpj
      )
    );
    await dispatch(listContractor());
    setSelected([]);
    focusOnlastItemAdded();
  };

  const formConfigI18n = {
    singleDeleteButton: t("contractor.singleDeleteButton"),
    singleDeleteMultipleButton: t("contractor.singleDeleteMultipleButton"),
    addForm: {
      title: t("contractor.addForm.title"),
      cancelButton: t("contractor.addForm.cancelButton"),
      confirmButton: t("contractor.addForm.confirmButton"),
      name: {
        requiredErrorMsg: t("contractor.addForm.name.requiredErrorMsg"),
        label: t("contractor.addForm.name.label"),
        placeholder: t("contractor.addForm.name.placeholder"),
      },
      email: {
        requiredErrorMsg: t("contractor.addForm.email.requiredErrorMsg"),
        label: t("contractor.addForm.email.label"),
        placeholder: t("contractor.addForm.email.placeholder"),
      },
      cnpj: {
        requiredErrorMsg: t("contractor.editForm.cnpj.requiredErrorMsg"),
        label: t("contractor.editForm.cnpj.label"),
        placeholder: t("contractor.editForm.cnpj.placeholder"),
      },
    },
    editForm: {
      title: t("contractor.editForm.title"),
      cancelButton: t("contractor.editForm.cancelButton"),
      confirmButton: t("contractor.editForm.confirmButton"),
      name: {
        requiredErrorMsg: t("contractor.editForm.name.requiredErrorMsg"),
        label: t("contractor.editForm.name.label"),
        placeholder: t("contractor.editForm.name.placeholder"),
      },
      email: {
        requiredErrorMsg: t("contractor.editForm.email.requiredErrorMsg"),
        label: t("contractor.editForm.email.label"),
        placeholder: t("contractor.editForm.email.placeholder"),
      },
      cnpj: {
        requiredErrorMsg: t("contractor.editForm.cnpj.requiredErrorMsg"),
        label: t("contractor.editForm.cnpj.label"),
        placeholder: t("contractor.editForm.cnpj.placeholder"),
      },
    },
  };

  useEffect(() => {
    dispatch(listContractor());
  }, [dispatch]);

  return (
    <>
      <Layout title={t("contractor.title")}>
        <Container disableGutters maxWidth={false}>
          <Box
            component="span"
            display={
              isEnabledFormAddAdmin || isEnabledFormEditAdmin ? "none" : "block"
            }
          >
            <Grid item xs={12} sm={12} lg={12}>
              <div className={classes.buttons}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  onClick={() => setIsEnabledFormAddAdmin((prev) => !prev)}
                >
                  {t("contractor.addButton")}
                </Button>
              </div>
            </Grid>
          </Box>

          <Add
            translateValidation={formConfigI18n.addForm}
            isEnabledFormAddAdmin={!isEnabledFormAddAdmin}
            loading={loading}
            addHandler={addHandler}
            show={toggleFormAdmin}
          />

          <Edit
            translateValidation={formConfigI18n.editForm}
            isEnabledFormEditAdmin={!isEnabledFormEditAdmin}
            loading={loading}
            editHandler={updateHandler}
            show={toggleFormEdit}
            currentContractor={currentContractor}
          />

          <>
            {loading ? (
              <CustomSpinner />
            ) : error ? (
              <NotFound
                title="Oops!"
                message="Ocorreu um erro, tente recarregar a página"
                image="/images/notfound.png"
                redirect="/app/content"
              />
            ) : (
              <div>
                <TableContainer>
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={"medium"}
                    aria-label="enhanced table"
                  >
                    <EnhancedTableHead
                      headCells={headCells}
                      classes={classes}
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={contractors.length}
                    />
                    <TableBody>
                      {stableSort(contractors, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const isItemSelected = isSelected(row);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={0}
                              key={row.id}
                              selected={isItemSelected}
                              className={
                                row.email === email && rowEnable
                                  ? classes.rowEnable
                                  : ""
                              }
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  onClick={(event) => handleClick(event, row)}
                                  checked={isItemSelected}
                                  inputProps={{ "aria-labelledby": labelId }}
                                />
                                <IconButton
                                  aria-label="edit"
                                  onClick={(event) => editHandler(row)}
                                >
                                  <EditIcon color="secondary" />
                                </IconButton>
                              </TableCell>

                              <TableCell padding="none">
                                {row.nameFantasy}
                              </TableCell>
                              <TableCell align="left">{row.email}</TableCell>
                              <TableCell align="left">
                                {/* {row.dateContractValidity} */}
                                TODO
                              </TableCell>
                              <TableCell align="left">
                                {row.cnpjContractor}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Box display="flex" p={1}>
                  <Box p={1} flexGrow={1}>
                    <TablePagination
                      rowsPerPageOptions={[10, 25]}
                      component="div"
                      count={contractors.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </Box>
                  <Box p={1} flexGrow={1}>
                    <RecordChangeButtons
                      singleDeleteButton={formConfigI18n.singleDeleteButton}
                      singleDeleteMultipleButton={
                        formConfigI18n.singleDeleteMultipleButton
                      }
                      itensSelected={selected}
                      handlerSingle={deleteHandler}
                      icon={<DeleteIcon />}
                    />
                  </Box>
                </Box>
              </div>
            )}
          </>
        </Container>
      </Layout>
    </>
  );
};

export { RegisterContractor };
