import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Image from "material-ui-image";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MovieIcon from "@material-ui/icons/Movie";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ImageIcon from "@material-ui/icons/Image";
import AudiotrackIcon from "@material-ui/icons/Audiotrack";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import PublishIcon from "@material-ui/icons/Publish";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import { yupResolver } from "@hookform/resolvers/yup";
import { custom, simple } from "./schema";

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    flexGrow: 1,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  save: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: "none",
  },
}));

const InteractionExtraDataDialog = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("translation");
  const { interaction, onClose, openPopUp, setOpenPopUp } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const currentSchema = interaction.contentType === "Custom" ? custom : simple;
  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    resolver: yupResolver(currentSchema),
  });

  const [inputState, setInputState] = useState({
    name: "",
    title: "",
    markerFile: "",
    imageFile: FileList,
    videoFile: FileList,
    audioFile: FileList,
    externalUrl: "",
  });

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevVisibility = usePrevious(openPopUp);

  useEffect(() => {
    if (prevVisibility !== openPopUp) {
      const newState = {
        ...inputState,
        idContent: props.interaction.idContent,
        title: "",
        description: "",
        markerFile: "",
        imageFile: FileList,
        videoFile: FileList,
        audioFile: FileList,
        externalUrl: "",
      };
      setInputState(newState);
    }
  }, [
    inputState,
    interaction,
    openPopUp,
    prevVisibility,
    props.interaction.idContent,
  ]);

  const onsubmit = (data) => {
    updateParams();
    setOpenPopUp(false);
    const dialogData = {
      ...data,
      ...inputState,
    };
    onClose(dialogData);
  };

  const updateParams = () => {
    let updatedParams = {
      ...inputState,
      markerFile: "",
      imageFile: "",
      videoFile: FileList,
      audioFile: FileList,
      externalUrl: inputState.externalUrl === "" ? inputState.externalUrl : "",
    };
    setInputState(updatedParams);
  };

  const handleChangeInput = (event) => {
    if (event.target.files) {
      const file = event.target.files[0];
      setInputState({ ...inputState, [event.target.name]: file });
    } else {
      setInputState({
        ...inputState,
        [event.target.name]: event.target.value,
      });
    }
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={openPopUp}
        onClose={() => setOpenPopUp(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <div align="right">
            <Button onClick={() => setOpenPopUp(false)}>
              <CloseIcon />
            </Button>
          </div>

          <Typography variant="h5" component="h3" gutterBottom>
            {t("publish.review.interactionExtraDialog.title")}:{" "}
            {interaction !== undefined ? interaction.contentName : ""}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FormControl className={classes.formControl}>
            <form
              onSubmit={handleSubmit(onsubmit)}
              className={classes.form}
              noValidate
            >
              {interaction !== undefined
                ? interaction.contentType === "Custom" && (
                    <div>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            inputRef={register}
                            error={errors.title && true}
                            helperText={
                              errors.title &&
                              t(
                                "publish.review.interactionExtraDialog.custom.title.requiredErrorMsg"
                              )
                            }
                            name="title"
                            variant="outlined"
                            color="secondary"
                            required
                            fullWidth
                            id="title"
                            label={t(
                              "publish.review.interactionExtraDialog.custom.title.label"
                            )}
                            placeholder={t(
                              "publish.review.interactionExtraDialog.custom.title.placeholder"
                            )}
                            autoFocus
                            onChange={handleChangeInput}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <div className={classes.root}>
                            <input
                              ref={register}
                              accept="image/*"
                              className={classes.input}
                              id="markerFile"
                              type="file"
                              name="markerFile"
                              onChange={handleChangeInput}
                            />
                            <label htmlFor="markerFile">
                              <Button
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                endIcon={
                                  inputState.markerFile === "" ? (
                                    <PhotoCamera />
                                  ) : (
                                    <CheckCircleOutlineIcon />
                                  )
                                }
                                component="span"
                              >
                                {t(
                                  "publish.review.interactionExtraDialog.custom.uploadButtom"
                                )}
                              </Button>
                              <span style={{ color: "red" }}>
                                {errors.markerFile && errors.markerFile.message}
                              </span>
                            </label>
                          </div>
                        </Grid>
                      </Grid>

                      {/*image*/}
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} lg={12}>
                          <input
                            ref={register}
                            accept="image/*"
                            className={classes.input}
                            id="imageFile"
                            type="file"
                            name="imageFile"
                            onChange={handleChangeInput}
                          />
                          <label htmlFor="imageFile">
                            <Button
                              variant="outlined"
                              color="secondary"
                              fullWidth
                              component="span"
                              endIcon={<ImageIcon />}
                            >
                              {t(
                                "publish.review.interactionExtraDialog.custom.imageFileButtom"
                              )}
                            </Button>
                            <span style={{ color: "red" }}>
                              {errors.imageFile && errors.imageFile.message}
                            </span>
                          </label>
                        </Grid>

                        {/*video*/}
                        <Grid item xs={12} sm={12} lg={12}>
                          <input
                            ref={register}
                            accept="video/mp4"
                            className={classes.input}
                            id="videoFile"
                            multiple
                            type="file"
                            name="videoFile"
                            onChange={handleChangeInput}
                          />
                          <label htmlFor="videoFile">
                            <Button
                              variant="outlined"
                              color="secondary"
                              fullWidth
                              component="span"
                              endIcon={<MovieIcon />}
                            >
                              {t(
                                "publish.review.interactionExtraDialog.custom.videoFileButtom"
                              )}
                            </Button>
                            <span style={{ color: "red" }}>
                              {errors.videoFile && errors.videoFile.message}
                            </span>
                          </label>
                        </Grid>

                        <Grid item xs={12} sm={12} lg={12}>
                          <input
                            ref={register}
                            accept="audio/*"
                            className={classes.input}
                            id="audioFile"
                            multiple
                            type="file"
                            name="audioFile"
                            onChange={handleChangeInput}
                          />
                          <label htmlFor="audioFile">
                            <Button
                              variant="outlined"
                              color="secondary"
                              fullWidth
                              component="span"
                              endIcon={<AudiotrackIcon />}
                            >
                              {t(
                                "publish.review.interactionExtraDialog.custom.audioFileButtom"
                              )}
                            </Button>
                            <span style={{ color: "red" }}>
                              {errors.audioFile && errors.audioFile.message}
                            </span>
                          </label>
                        </Grid>

                        <span style={{ color: "red" }}>
                          {errors.checkValuesSet &&
                            errors.checkValuesSet.message}
                        </span>

                        <Grid item xs={12} sm={12} lg={12}>
                          <TextField
                            inputRef={register}
                            error={errors.externalUrl && true}
                            helperText={errors.externalUrl && "*required"}
                            name="externalUrl"
                            variant="outlined"
                            color="secondary"
                            fullWidth
                            id="externalUrl"
                            label={t(
                              "publish.review.interactionExtraDialog.custom.externalUrl.label"
                            )}
                            onChange={handleChangeInput}
                            value={inputState.externalUrl}
                            placeholder={t(
                              "publish.review.interactionExtraDialog.custom.externalUrl.placeholder"
                            )}
                            autoFocus
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )
                : ""}

              {interaction !== undefined
                ? interaction.contentType === "Basic" && (
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          defaultValue={interaction.params?.title || ""}
                          multiline
                          rows={11}
                          inputRef={register}
                          error={errors.title && true}
                          helperText={
                            errors.title &&
                            t(
                              "publish.review.interactionExtraDialog.basic.teacherNote.requiredErrorMsg"
                            )
                          }
                          name="title"
                          variant="outlined"
                          color="secondary"
                          required
                          fullWidth
                          id="title"
                          label={t(
                            "publish.review.interactionExtraDialog.basic.teacherNote.label"
                          )}
                          placeholder={t(
                            "publish.review.interactionExtraDialog.basic.teacherNote.placeholder"
                          )}
                          autoFocus
                          onChange={handleChangeInput}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <input
                          ref={register}
                          accept="image/*"
                          className={classes.input}
                          id="markerFile"
                          type="file"
                          name="markerFile"
                          onChange={handleChangeInput}
                        />
                        <label htmlFor="markerFile">
                          <Button
                            style={{ justifyContent: "start" }}
                            color="primary"
                            fullWidth
                            endIcon={
                              inputState.markerFile === "" ? (
                                <PublishIcon />
                              ) : (
                                <CheckCircleOutlineIcon />
                              )
                            }
                            component="span"
                          >
                            {t(
                              "publish.review.interactionExtraDialog.basic.uploadButton.label"
                            )}
                          </Button>

                          <span style={{ color: "red" }}>
                            {errors.markerFile &&
                              t(
                                "publish.review.interactionExtraDialog.basic.uploadButton.requiredErrorMsg"
                              )}
                          </span>
                        </label>

                        <CustomTooltip
                          title={
                            <>
                              <Image
                                cover={true}
                                aspectRatio={1 / 1}
                                imageStyle={{ padding: 10 }}
                                alt="Dica"
                                src="/images/marker.png"
                                animationDuration={1500}
                              />

                              <Typography
                                variant="h6"
                                component="h6"
                                gutterBottom
                              >
                                {t(
                                  "publish.review.interactionExtraDialog.basic.tooltip.title"
                                )}
                              </Typography>
                              <Typography variant="body2" gutterBottom>
                                {t(
                                  "publish.review.interactionExtraDialog.basic.tooltip.body"
                                )}
                              </Typography>
                            </>
                          }
                        >
                          <Box display="flex" p={1}>
                            <Box>
                              <ErrorOutlineIcon color="primary" />
                            </Box>
                            <Box style={{ padding: "0 8px" }}>
                              <Typography variant="body1">
                                {t(
                                  "publish.review.interactionExtraDialog.basic.markerTip"
                                )}
                              </Typography>
                            </Box>
                          </Box>
                        </CustomTooltip>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="caption" gutterBottom>
                          {t(
                            "publish.review.interactionExtraDialog.basic.imageInfo"
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  )
                : ""}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.save}
              >
                {t(
                  "publish.review.interactionExtraDialog.basic.saveButton.label"
                )}
              </Button>
            </form>
          </FormControl>
        </DialogContent>
        <DialogActions>
          {/*TODO: This piece of code needs is in <form>*/}
          {/*<Button*/}
          {/*	type="submit"*/}
          {/*	fullWidth*/}
          {/*	variant="contained"*/}
          {/*	color="primary"*/}
          {/*	className={classes.save}*/}
          {/*>*/}
          {/*	Salvar*/}
          {/*</Button>*/}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InteractionExtraDataDialog;
