import * as yup from "yup";

const batchStudentSchema = (props) => {
  const SUPPORTED_SPREADSHEET_FORMATS = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
  const SPREADSHEET_SIZE = 4000 * 1024; // 4MB

  const schema = yup.object().shape({
    schoolClass: yup.string().required(),
    spreadsheet: yup
      .mixed()
      .test(
        "fileFormat",
        "select a valid Spreadsheet: .`xlsx`",
        function (value) {
          if (!value.length) return false; // attachment is required
          return SUPPORTED_SPREADSHEET_FORMATS.includes(value[0].type);
        }
      )
      .test("fileSize", "File too large", function (value) {
        if (!value.length) return false; // attachment is required
        return value[0].size <= SPREADSHEET_SIZE;
      }),
  });
  return schema;
};

export { batchStudentSchema };
