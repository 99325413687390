import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Image from "material-ui-image";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import TablePagination from "@material-ui/core/TablePagination";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { showCustomConfirmationDialog } from "../../store/customConfirmationDialogReducer";
import { contentDelete } from "../../store/content";
import EnhancedTableHead from "../../components/common/table/EnhancedTableHead";
import RecordChangeButtons from "../../components/common/recordChangeButtons/RecordChangeButtons";
import CustomSpinner from "../../components/common/Spinner/CustomSpinner";
import { getComparator, stableSort } from "../../utils/tableFilter";
import Layout from "../../components/common/hoc/Layout";
import NotFound from "../../components/common/notFound/NotFound";
import { listInteractions, searchInteractions, resetInteractionsRedux } from "../../store/duck/myContent/myContent";
import { showSnackbar } from "../../store/duck/uiReducer/uiReducer";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    marginTop: "1rem",
    minWidth: 750,
  },
  rowEnable: {
    outlineColor: theme.palette.secondary.main,
    outlineWidth: "2px",
    outlineStyle: "solid",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  buttonAdd: {
    marginRight: theme.spacing(1),
  },
}));

const MyContent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation("translation");
  const myContentList = useSelector((state) => state.myContentList);
  const { loading, error, interactions, keyword, lastTypeOfSearch  } = myContentList;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [keywordToSearch, setKeywordToSearch] = useState("");

  const headCells = [
    {
      id: "st_marker",
      numeric: false,
      disablePadding: false,
      label: t("myContent.headCells.st_marker"),
    },
    {
      id: "contentName",
      numeric: false,
      disablePadding: false,
      label: t("myContent.headCells.contentName"),
    },
    {
      id: "contentStyle",
      numeric: false,
      disablePadding: false,
      label: t("myContent.headCells.contentStyle"),
    },
    {
      id: "subject",
      numeric: false,
      disablePadding: false,
      label: t("myContent.headCells.subject"),
    },
    {
      id: "effectiveDate",
      numeric: false,
      disablePadding: false,
      label: t("myContent.headCells.effectiveDate"),
    },
  ];
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, interactions?.length - page * rowsPerPage);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = selected.map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteHandler = async (content) => {
    const configDialog = {
      title: t("student.deleteDialog.title"),
      message: `${t("student.deleteDialog.firstMessage")} "${
        content.contentName
      }", ${t("student.deleteDialog.secondMessage")}`,
      onSubmit: async function () {
        await dispatch(contentDelete(content.idContent))
          .then(async (result) => {
            await dispatch({ type: "CUSTOM_CONFIRMATION_DIALOG_CLOSED" });
            await dispatch(
              showSnackbar({
                message: "Conteúdo removido com sucesso!",
                severity: "success",
                autoHideDuration: 3000,
              })
            );
            await dispatch(listInteractions());
            setSelected([]);
          })
          .catch(async (err) => {
            await dispatch(
              showSnackbar({
                message: "Ocorreu um erro ao remover o conteúdo!",
                severity: "error",
                autoHideDuration: 3000,
              })
            );
          });
      },

      close: async function () {
        await dispatch({ type: "CUSTOM_CONFIRMATION_DIALOG_CLOSED" });
      },
      buttons: {
        cancel: t("student.deleteDialog.buttons.cancel"),
        confirm: t("student.deleteDialog.buttons.confirm"),
      },
    };

    dispatch(
      showCustomConfirmationDialog({
        ...configDialog,
      })
    );
  };

  const formConfigI18n = {
    singleDeleteButton: t("student.singleDeleteButton"),
    singleDeleteMultipleButton: t("student.singleDeleteMultipleButton"),
  };

  const searchDefault = React.useCallback(async () => {
    if (lastTypeOfSearch === "default") {
        await dispatch(listInteractions());
    }
  }, [dispatch, lastTypeOfSearch]);

  const searchKeyword = React.useCallback(
    async (currentKeyword) => {
      if (currentKeyword) {
        if (currentKeyword !== keywordToSearch) {
          setKeywordToSearch(currentKeyword);
          await resetInteractionsRedux();
          await dispatch(searchInteractions(currentKeyword));
        }
      } else if (!currentKeyword) {
        await resetInteractionsRedux();
        await dispatch(listInteractions());
      }
    },

    [dispatch, keywordToSearch]
  );

  useEffect(() => {
    console.log('KEYWORD', keyword, lastTypeOfSearch);
    if (keyword !== undefined) {
      if (lastTypeOfSearch === "keyword") {
        if (keyword === ":clear") {
          searchKeyword();
        } else {
          searchKeyword(keyword);
        }
      } else {
        searchDefault();
      }
    }
  }, [dispatch, keyword, lastTypeOfSearch, searchDefault, searchKeyword]);

  return (
    <Layout title={t("myContent.title")}>
      <Container disableGutters maxWidth={false}>
        <>
          {loading ? (
            <CustomSpinner />
          ) : error ? (
            <NotFound
              title="Oops!"
              message="Ocorreu um erro, tente recarregar a página"
              image="/images/notfound.png"
              redirect="/app/content"
            />
          ) : (
            <div>
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={"medium"}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    headCells={headCells}
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={interactions.length}
                  />
                  <TableBody>
                    {stableSort(interactions, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={0}
                            key={row.idContent}
                            selected={isItemSelected}
                          >
                            <TableCell padding="normal">
                              <Checkbox
                                onClick={(event) => handleClick(event, row)}
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                              />

                              <Link
                                to={`/app/my-content/update-content/${row.idContent}`}
                              >
                                <IconButton aria-label="edit">
                                  <EditIcon color="secondary" />
                                </IconButton>
                              </Link>
                            </TableCell>
                            <TableCell padding="normal">
                              <Image
                                cover={true}
                                aspectRatio={1 / 1}
                                alt={row.contentName}
                                src={row.st_thumbnail}
                              />
                            </TableCell>
                            <TableCell align="left">
                              {row.contentName}
                            </TableCell>
                            <TableCell align="left">
                              {row.contentStyle}
                            </TableCell>
                            <TableCell align="left">{row.subject}</TableCell>
                            <TableCell align="left">
                              {row.effectiveDate}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box display="flex" p={1}>
                <Box p={1} flexGrow={1}>
                  <TablePagination
                    rowsPerPageOptions={[10, 25]}
                    component="div"
                    count={interactions.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Box>
                <Box p={1} flexGrow={1}>
                  <RecordChangeButtons
                    singleDeleteButton={formConfigI18n.singleDeleteButton}
                    singleDeleteMultipleButton={
                      formConfigI18n.singleDeleteMultipleButton
                    }
                    itensSelected={selected}
                    handlerSingle={deleteHandler}
                    icon={<DeleteIcon />}
                  />
                </Box>
              </Box>
            </div>
          )}
        </>
      </Container>
    </Layout>
  );
};

export { MyContent };
