import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { FilterIcon } from "../../../icons/CustomIcons";

const CarouselFilter = ({ onSelectedTags }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const interactionList = useSelector((state) => state.interactionList);
  const { lastTypeOfSearch } = interactionList;
  const { keyword, pageNumber } = useParams();
  const { t } = useTranslation("translation");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const theme = useTheme();
  const [filtersApplied, setFiltersApplied] = useState([]);

  const configI18n = {
    filter: {
      label: t("InteractionsLibrary.filter.label"),
      disciplines: {
        fisica: t("InteractionsLibrary.filter.disciplines.fisica"),
        quimica: t("InteractionsLibrary.filter.disciplines.quimica"),
        biologia: t("InteractionsLibrary.filter.disciplines.biologia"),
        ciencias: t("InteractionsLibrary.filter.disciplines.ciencias"),
      },
    },
  };

  const items = [
    // {
    //   id: 1,
    //   title: 'Língua Portuguesa',
    //   color: theme.palette.quimica.backgroundColor,
    // },
    // {
    //   id: 2,
    //   title: 'Língua estrangeira',
    //   color: theme.palette.quimica.backgroundColor,
    // },
    // {
    //   id: 3,
    //   title: 'Arte',
    //   color: theme.palette.quimica.backgroundColor,
    // },
    // {
    //   id: 4,
    //   title: 'Educação Física',
    //   color: theme.palette.quimica.backgroundColor,
    // },
    // {
    //   id: 5,
    //   title: 'Matemática',
    //   color: theme.palette.quimica.backgroundColor,
    // },
    {
      id: 6,
      title: configI18n.filter.disciplines.fisica,
      color: theme.palette.fisica.backgroundColor,
      value: "Física",
    },
    {
      id: 7,
      title: configI18n.filter.disciplines.quimica,
      color: theme.palette.quimica.backgroundColor,
      value: "Química",
    },
    {
      id: 8,
      title: configI18n.filter.disciplines.biologia,
      color: theme.palette.biologia.backgroundColor,
      value: "Biologia",
    },
    // {
    //   id: 9,
    //   title: 'História',
    //   color: theme.palette.quimica.backgroundColor,
    // },
    // {
    //   id: 10,
    //   title: 'Geografia',
    //   color: theme.palette.quimica.backgroundColor,
    // },
    // {
    //   id: 11,
    //   title: 'Filosofia',
    //   color: theme.palette.quimica.backgroundColor,
    // },
    // {
    //   id: 12,
    //   title: 'Sociologia',
    //   color: theme.palette.quimica.backgroundColor,
    // },
    {
      id: 13,
      title: configI18n.filter.disciplines.ciencias,
      color: theme.palette.ciencias.backgroundColor,
      value: "Ciências",
    },
  ];

  const applyFilter = (filter) => {
    const isFinded = filtersApplied.find((item) => item.id === filter.id);
    if (!isFinded) {
      setFiltersApplied([filter]);
    } else {
      setFiltersApplied(
        filtersApplied.filter(function (item) {
          return item.title !== filter.title;
        })
      );
    }
  };

  const prevVisibility = usePrevious(filtersApplied);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  useEffect(() => {
    if (prevVisibility !== filtersApplied) {
      onSelectedTags(filtersApplied);
    } else if (
      prevVisibility === filtersApplied &&
      lastTypeOfSearch === "keyword"
    ) {
      setFiltersApplied([]);
    }
  }, [
    isMobile,
    dispatch,
    prevVisibility,
    history.location.pathname,
    pageNumber,
    keyword,
    history,
    onSelectedTags,
    filtersApplied,
    lastTypeOfSearch,
  ]);
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
        flexWrap="nowrap"
        overflow="hidden"
      >
        <FilterIcon fontSize="small" color="secondary" />
        <Typography variant="h6" component="p" style={{ marginLeft: ".1em" }}>
          {configI18n.filter.label}:
        </Typography>
        {filtersApplied &&
          filtersApplied.length > 0 &&
          filtersApplied.map((item) => {
            return (
              <Button
                onClick={() => applyFilter(item)}
                key={item.id}
                size="small"
                variant="outlined"
                endIcon={<CloseIcon />}
                style={{
                  borderColor: item.color,
                  color: item.color,
                  marginLeft: theme.spacing(0.5),
                }}
              >
                {item.title}
              </Button>
            );
          })}
      </Box>

      {
        items.length &&
          items.map((item) => {
            return (
              <ButtonGroup
                size="small"
                color="primary"
                aria-label="small outlined button group"
                style={{ margin: ".5em 0 1em" }}
              >
                <Button
                  onClick={() => applyFilter(item)}
                  key={item.id}
                  size="small"
                  variant="outlined"
                  style={{
                    "white-space": "nowrap",
                    borderColor: item.color,
                    color: item.color,
                    marginRight: ".5em",
                  }}
                >
                  {item.title}
                </Button>
              </ButtonGroup>
            );
          })
        // <Carousel
        //   dragging={true}
        //   slidesToShow={slidesToShowInCarousel}
        //   heightMode="current"
        //   transitionMode="scroll"
        //   scrollMode="page"
        //   framePadding="10px 40px"
        //   cellSpacing={cellSpacing}
        //   renderBottomCenterControls={null}
        //   cellAlign="left"
        //   renderCenterLeftControls={({ previousSlide }) => (
        //     <IconButton
        //       style={{
        //         border: '1px solid rgba(21, 18, 64, 0.5)',
        //         padding: 2,
        //       }}
        //       color="primary"
        //       onClick={previousSlide}
        //       aria-label="previousSlide"
        //     >
        //       <ArrowBackIcon />
        //     </IconButton>
        //   )}
        //   renderCenterRightControls={({ nextSlide }) => (
        //     <IconButton
        //       style={{
        //         border: '1px solid rgba(21, 18, 64, 0.5)',
        //         padding: 2,
        //       }}
        //       color="primary"
        //       onClick={nextSlide}
        //       aria-label="nextSlide"
        //     >
        //       <ArrowRightIcon />
        //     </IconButton>
        //   )}
        // >
        //   {items.map((item) => {
        //     return (
        //       <Button
        //         onClick={() => applyFilter(item)}
        //         key={item.id}
        //         size="small"
        //         variant="outlined"
        //         style={{
        //           'white-space': 'nowrap',
        //           minWidth: '160px',
        //           width: '100%',
        //           borderColor: item.color,
        //           color: item.color,
        //         }}
        //       >
        //         {item.title}
        //       </Button>
        //     )
        //   })}
        // </Carousel>
      }
    </>
  );
};

export default CarouselFilter;
