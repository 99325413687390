import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Layout from "../components/common/hoc/Layout";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import NotFound from "../components/common/notFound/NotFound";
import ReactHookFormSelect from "../components/common/form/ReactHookFormSelect";
import { UserRole } from "../config/userRole";
import { listInteractionsPerformance } from "../store/performanceIndicators";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  button: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
}));

const PerformanceIndicatorsScreen = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const performanceIndicatorReducer = useSelector(
    (state) => state.performanceIndicatorsReducer
  );
  const { loading, error, performance } = performanceIndicatorReducer;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const { t } = useTranslation("translation");
  const history = useHistory();
  const { register, handleSubmit, errors, control } = useForm({
    mode: "onChange",
    //resolver: yupResolver(studentSchema()),
  });

  useEffect(() => {
    dispatch(listInteractionsPerformance());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Layout title={t("performanceIndicators.title")}>
        <Container component="main" maxWidth={false}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={3}
          >
            {userInfo.role[0] === UserRole.PROFESSOR && (
              <Grid item xs={12} sm={12} lg={6}>
                <Paper elevation={1} className={classes.paper}>
                  <Grid container>
                    <Grid item xs={12} sm={12} lg={6} xl={6}>
                      <Typography
                        className={classes.title}
                        variant="h5"
                        component="h1"
                        gutterBottom
                      >
                        {t("performanceIndicators.performance.title")}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      lg={6}
                      className={classes.button}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        disabled={loading}
                        onClick={() =>
                          history.push(
                            "performance-indicators/reports/performance"
                          )
                        }
                      >
                        {t("performanceIndicators.performance.reportButton")}
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <ReactHookFormSelect
                      inputRef={register}
                      error={errors.performanceIndicatorReport && true}
                      helperText={
                        errors.performanceIndicatorReport &&
                        t(
                          "performanceIndicators.performance.performanceIndicatorReport.requiredErrorMsg"
                        )
                      }
                      id="performanceIndicatorReport"
                      name="performanceIndicatorReport"
                      label={t(
                        "performanceIndicators.performance.performanceIndicatorReport.label"
                      )}
                      style={{ width: "100%" }}
                      control={control}
                      defaultValue="isFinished"
                      onClick={(event) =>
                        dispatch(
                          listInteractionsPerformance(event.target.value)
                        )
                      }
                    >
                      <MenuItem value="isFinished">
                        {t(
                          "performanceIndicators.performance.performanceIndicatorReport.options.isFinished"
                        )}
                      </MenuItem>
                      <MenuItem value="timeSpent">
                        {" "}
                        {t(
                          "performanceIndicators.performance.performanceIndicatorReport.options.timeSpent"
                        )}
                      </MenuItem>
                      <MenuItem value="clickCount">
                        {" "}
                        {t(
                          "performanceIndicators.performance.performanceIndicatorReport.options.clickCount"
                        )}
                      </MenuItem>
                    </ReactHookFormSelect>

                    {loading ? (
                      <CircularProgress
                        style={{ display: "block", margin: "0 auto" }}
                      />
                    ) : error ? (
                      <NotFound
                        title="Oops!"
                        message="Ocorreu um erro, tente recarregar a página"
                        image="/images/notfound.png"
                        redirect="/app/content"
                      />
                    ) : (
                      <>
                        <List dense={true}>
                          {performance?.map((row, index) => {
                            return (
                              <ListItem>
                                <ListItemText primary={row.interactionname} />
                                <ListItemSecondaryAction>
                                  {row.concluded} %
                                </ListItemSecondaryAction>
                              </ListItem>
                            );
                          })}
                        </List>
                      </>
                    )}
                  </Grid>
                </Paper>
              </Grid>
            )}

            {userInfo.role[0] === UserRole.ADMINISTRADOR_MASTER && (
              <>
                <Grid item xs={12} sm={12} lg={6}>
                  <Paper elevation={1} className={classes.paper}>
                    <Typography
                      className={classes.title}
                      variant="h5"
                      component="h1"
                      gutterBottom
                    >
                      Gap de conteúdos(0)
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={12} lg={12}>
                  <Paper elevation={1} className={classes.paper}>
                    <Typography
                      className={classes.title}
                      variant="h5"
                      component="h1"
                      gutterBottom
                    >
                      % Conteúdos para BNCC e Enem
                    </Typography>
                  </Paper>
                </Grid>
              </>
            )}
          </Grid>
        </Container>
      </Layout>
    </React.Fragment>
  );
};

export { PerformanceIndicatorsScreen };
