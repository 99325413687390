import Container from "@material-ui/core/Container";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import InfoIcon from "@material-ui/icons/Info";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import LinearProgress from "@material-ui/core/LinearProgress";
import Tooltip from "@material-ui/core/Tooltip";
import Link from "@material-ui/core/Link";
import { batchStudentSchema } from "./schema/batchStudent";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useDispatch, useSelector } from "react-redux";
import SchoolClassDialog from "./SchoolClassDialog";

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  buttonAdd: {
    marginLeft: theme.spacing(1),
  },
  error: {
    border: "1px solid" + theme.palette.error.main,
  },
  input: {
    display: "none",
  },
}));

const AddBatch = (props) => {
  const classes = useStyles();
  const {
    isEnabledFormAddAdmin,
    addBatchHandler,
    //loading,
    show,
    title,
    saveButtonLabel,
    titleSchoolClassDialog,
    schoolClassButton,
    cancelButton,
    confirmButton,
  } = props;
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    reset,
    clearErrors,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(batchStudentSchema()),
  });

  const dispatch = useDispatch();

  const schoolClassConfigReducer = useSelector(
    (state) => state.schoolClassConfigReducer
  );
  const { loading, error, schoolClassConfig } = schoolClassConfigReducer;
  const [openPopUp, setOpenPopUp] = useState(false);
  const [currentSchoolClass, setCurrentSchoolClass] = useState("");

  const [inputState, setInputState] = useState({
    spreedSheet: "",
  });

  const onsubmit = (data) => {
    addBatchHandler({ ...data, currentSchoolClass });
    reset();
    setCurrentSchoolClass("");
  };

  const getDialogData = (data) => {
    const schoolClass = data.schoolClass.split("-");
    const currentClass = `Período ${schoolClass[0]} - Ano ${schoolClass[1]} - Turma ${schoolClass[2]}`;
    register("schoolClass", currentClass);
    setValue("schoolClass", data.schoolClass);
    setCurrentSchoolClass(currentClass);
    clearErrors("schoolClass");
  };

  const handleChangeInput = (event) => {
    if (event.target.files !== null && event.target.files.length > 0) {
      const file = event.target.files[0];
      setInputState({ [event.target.name]: file });
    }
  };

  useEffect(() => {
    register("schoolClass");
  }, [getValues, register]);

  return (
    <>
      <Box component="span" display={isEnabledFormAddAdmin ? "none" : "block"}>
        <Paper elevation={1}>
          <Container maxWidth={false} className={classes.container}>
            <Typography
              className={classes.title}
              variant="h5"
              component="h1"
              gutterBottom
            >
              {title}
            </Typography>

            <form fullWidth onSubmit={handleSubmit(onsubmit)} noValidate>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} lg={6}>
                  <div className={classes.root}>
                    <input
                      ref={register}
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      className={classes.input}
                      id="spreadsheet"
                      name="spreadsheet"
                      type="file"
                      onChange={handleChangeInput}
                    />
                    <label htmlFor="spreadsheet">
                      <Button
                        variant="outlined"
                        color="secondary"
                        endIcon={
                          inputState.spreedSheet === "" ? (
                            <AttachFileIcon />
                          ) : (
                            <CheckCircleIcon color="primary" />
                          )
                        }
                        component="span"
                      >
                        Upload
                      </Button>
                      <span style={{ color: "red" }}>
                        {errors.spreadsheet && errors.spreadsheet.message}
                      </span>
                    </label>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6} lg={6}>
                  <Button
                    variant="outlined"
                    endIcon={<ArrowForwardIosIcon />}
                    onClick={() => setOpenPopUp(true)}
                    className={errors.schoolClass ? classes.error : ""}
                  >
                    {currentSchoolClass !== ""
                      ? currentSchoolClass
                      : schoolClassButton}
                  </Button>
                </Grid>

                <Grid item xs={12} sm={6} lg={6}>
                  <Tooltip
                    title="Siga o modelo abaixo, para cadastrar os alunos"
                    placement="right"
                  >
                    <InfoIcon color="primary" />
                  </Tooltip>

                  <Typography>
                    <Link
                      target="_blank"
                      rel="noopener"
                      href="https://docs.google.com/spreadsheets/d/10DFOEC_rDfxCry_hes4u5ySyz_E0NEmSY916gKTvQjQ/edit?usp=sharing"
                    >
                      Modelo de planilha
                    </Link>
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} lg={12}>
                  <div className={classes.buttons}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      disabled={loading}
                      onClick={show}
                    >
                      {cancelButton}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.buttonAdd}
                      disabled={loading}
                    >
                      {confirmButton}
                    </Button>
                  </div>
                  {loading ? (
                    <LinearProgress />
                  ) : error ? (
                    <Typography
                      variant="subtitle1"
                      color="secondary"
                      align="center"
                      component="h3"
                      gutterBottom
                    >
                      Erro
                      {/* {t('signIn.loginErrorMsg')} */}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </form>
          </Container>
        </Paper>
      </Box>

      <SchoolClassDialog
        title={titleSchoolClassDialog}
        saveButtonLabel={saveButtonLabel}
        schoolClassData={schoolClassConfig}
        onClose={getDialogData}
        openPopUp={openPopUp}
        setOpenPopUp={setOpenPopUp}
      />
    </>
  );
};

export default AddBatch;
