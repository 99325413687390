import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/Error";
import Grid from "@material-ui/core/Grid";
import Layout from "../../components/common/hoc/Layout";
import { cartPublish } from "../../store/duck/publish/cart";
import NotFound from "../../components/common/notFound/NotFound";
import ContentForm from "./componets/ContentForm";
import Review from "./componets/Review";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",
    marginTop: theme.spacing(6),
    [theme.breakpoints.down(600 + theme.spacing(2) * 2)]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
  },
  form: {
    borderRadius: "10px",
    padding: theme.spacing(3),
  },
  disableForm: {
    pointerEvents: "none",
    opacity: "0.4",
  },
  enableForm: {
    backgroundColor: "#f2f2f2",
  },
}));

const PublishScreen = () => {
  const classes = useStyles();
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const { items } = cart;
  const publish = useSelector((state) => state.cart);
  const { status, loading } = publish;
  const steps = [t("publish.contentStepLabel"), t("publish.reviewStepLabel")];
  const [activeStep, setActiveStep] = useState(0);

  const [noItemsInTheCart, setNoItemsInTheCart] = useState(items?.length > 0);

  const [form, setForm] = useState({});

  const formConfigI18n = {
    notFound: {
      title: t("common.notFoundPage.title"),
      message: t("common.notFoundPage.message"),
      redirectButton: t("common.notFoundPage.homeButton"),
    },
    publishMessage: {
      title: t("publish.publishFinishTitle"),
      message: t("publish.publishFinishSubTitle"),
      redirectButton: "Publicar outro conteúdo",
    },
    EmptyCart: {
      title: t("publish.emptyCartMessage.title"),
      message: t("publish.emptyCartMessage.subtitle"),
      redirectButton: t("publish.emptyCartMessage.addContentButton"),
    },
    contentForm: {
      title: t("publish.contentForm.title"),
      schoolGrade: {
        requiredErrorMsg: t("publish.contentForm.schoolGrade.requiredErrorMsg"),
        label: t("publish.contentForm.schoolGrade.label"),
        placeholder: t("registerAdmin.editForm.email.placeholder"),
        codeName: {
          EM: t("publish.contentForm.schoolGrade.codeName.EM"),
          EM1: t("publish.contentForm.schoolGrade.codeName.EM1"),
          EM2: t("publish.contentForm.schoolGrade.codeName.EM2"),
        },
      },
      schoolClass: {
        requiredErrorMsg: t("publish.contentForm.schoolClass.requiredErrorMsg"),
        label: t("publish.contentForm.schoolClass.label"),
        placeholder: t("publish.contentForm.schoolClass.placeholder"),
      },
      tags: {
        requiredErrorMsg: t("publish.contentForm.tags.requiredErrorMsg"),
        label: t("publish.contentForm.tags.label"),
      },
      contentName: {
        requiredErrorMsg: t("publish.contentForm.schoolGrade.requiredErrorMsg"),
        label: t("publish.contentForm.contentName.label"),
        placeholder: t("publish.contentForm.contentName.placeholder"),
      },
      discipline: {
        requiredErrorMsg: t("publish.contentForm.discipline.requiredErrorMsg"),
        label: t("publish.contentForm.discipline.label"),
      },
    },
    review: {
      title: t("publish.review.title"),
      settingButton: t("publish.review.settingButton"),
      interactionsToBePublished: t("publish.review.interactionsToBePublished"),
      backButton: t("publish.review.backButton"),
      publishButton: t("publish.review.publishButton"),
    },
  };

  const handleNext = (data) => {
    setForm({ ...form, ...data });

    if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1);
    } else {
      const classData = {
        infoClass: { ...form },
        interactions: [...items],
      };
      dispatch(cartPublish(classData));
      setActiveStep(activeStep + 1); //TODO capture CART_PUBLISH_SUCCESS
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const getDialogData = async (data) => {
    if (data.hasOwnProperty("data")) {
      handleNext(data.data);
    } else {
      handleBack();
    }
  };

  return (
    <Layout title={t("publish.title")}>
      <main className={classes.layout}>
        <Grid container spacing={6}>
          {noItemsInTheCart && (
            <>
              <Grid
                item
                xs={12}
                md={6}
                className={clsx(classes.form, {
                  [classes.enableForm]: activeStep === 0,
                  [classes.disableForm]: activeStep === 1,
                })}
              >
                <ContentForm
                  enableNextButtom={activeStep !== 1 ? true : false}
                  onClose={getDialogData}
                  formConfigI18n={formConfigI18n}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                display={{ xs: "none", sm: "none" }}
                className={clsx(classes.form, {
                  [classes.enableForm]: activeStep === 1,
                  [classes.disableForm]: activeStep === 0,
                })}
              >
                <Review
                  enableNextButtom={activeStep !== 0 ? true : false}
                  onClose={getDialogData}
                  formConfigI18n={formConfigI18n}
                  setNoItemsInTheCart={setNoItemsInTheCart}
                />
              </Grid>
            </>
          )}

          {!noItemsInTheCart && !status?.success && !loading && (
            <Grid item xs={12} md={12} display={{ xs: "none", sm: "none" }}>
              <NotFound
                title={formConfigI18n.EmptyCart.title}
                message={formConfigI18n.EmptyCart.message}
                // image="/images/notfound.png"
                buttonText={formConfigI18n.EmptyCart.redirectButton}
                icon={<ErrorIcon fontSize="large" />}
                redirect="/app/content"
              />
            </Grid>
          )}

          {!noItemsInTheCart && status?.success && !loading && (
            <Grid item xs={12} md={12} display={{ xs: "none", sm: "none" }}>
              <NotFound
                title={formConfigI18n.publishMessage.title}
                message={formConfigI18n.publishMessage.message}
                image="/images/avatar.png"
                buttonText={formConfigI18n.publishMessage.redirectButton}
                icon={<ErrorIcon fontSize="large" />}
                redirect="/app/content"
              />
            </Grid>
          )}
        </Grid>
      </main>
    </Layout>
  );
};

export { PublishScreen };
