import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import {
  studentsActiveReducer,
  teacherssActiveReducer,
  myActivitiesReducer,
  interactionsRelatedReducer,
  interactionDetailsReducer,
  interactionDetailsUpdateReducer,
  interactionListReducer,
  myContentListReducer,
  userLoginReducer,
  updatePasswordReducer,
  adminMasterReducer,
  contractorReducer,
  clientReducer,
  teacherReducer,
  studentReducer,
  schoolClassReducer,
  schoolClassConfigReducer,
  contentManagerReducer,
  cartReducer,
  contentReducer,
  contentUpdateReducer,
  reportReducer,
  performanceIndicatorsReducer,
  uiReducer,
  customConfirmationDialogReducer,
  themeManagerReducer,
} from "./index";

const reducer = combineReducers({
  studentsActive: studentsActiveReducer,
  teachersActive: teacherssActiveReducer,
  myActivities: myActivitiesReducer,
  interactionsRelated: interactionsRelatedReducer,
  interactionList: interactionListReducer,
  interactionDetails: interactionDetailsReducer,
  interactionDetailsUpdate: interactionDetailsUpdateReducer,
  myContentList: myContentListReducer,
  adminMasterReducer: adminMasterReducer,
  contractorReducer: contractorReducer,
  teacherReducer: teacherReducer,
  clientReducer: clientReducer,
  studentReducer: studentReducer,
  schoolClassReducer: schoolClassReducer,
  schoolClassConfigReducer: schoolClassConfigReducer,
  contentManagerReducer: contentManagerReducer,
  reportReducer: reportReducer,
  performanceIndicatorsReducer: performanceIndicatorsReducer,
  userLogin: userLoginReducer,
  updatePasswordReducer: updatePasswordReducer,
  cart: cartReducer,
  content: contentReducer,
  contentUpdate: contentUpdateReducer,
  ui: uiReducer,
  dialogReducer: customConfirmationDialogReducer,
  themeManager: themeManagerReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const cartItemsFromStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : [];

const themeNameFromStorage = localStorage.getItem("themeConfig")
  ? localStorage.getItem("themeConfig")
  : "Light";

const initialState = {
  contractorReducer: { contractors: [] },
  userLogin: { userInfo: userInfoFromStorage },
  cart: { items: cartItemsFromStorage },
  themeManager: { themeConfig: { themeName: themeNameFromStorage } },
};

export default function configureStore() {
  const middleware = [thunk];
  return createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  );
}
