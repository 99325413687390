import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FormControl from "@material-ui/core/FormControl";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import ReactHookFormRadio from "../../../components/common/form/ReactHookFormRadio";
import TabPanelYear from "./SchoolClassDialog/TabPanelYear";
import TabsPeriod from "./SchoolClassDialog/TabsPeriod";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    flexGrow: 1,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  save: {
    margin: theme.spacing(3, 0, 2),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const SchoolClassDialog = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    onClose,
    openPopUp,
    setOpenPopUp,
    title,
    saveButtonLabel,
    currentSchoolClassData,
  } = props;
  const schoolClassConfigReducer = useSelector(
    (state) => state.schoolClassConfigReducer
  );
  const { loading, error, schoolClassConfig } = schoolClassConfigReducer;

  const { register, handleSubmit, errors, control } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    // resolver: yupResolver(currentSchema),
  });

  const [currentTab, setCurrentTab] = React.useState(0);
  const [checked, setChecked] = React.useState([0]);

  const handleCurrentTab = (newValue) => {
    setCurrentTab(newValue);
  };

  const onsubmit = (data) => {
    setOpenPopUp(false);
    onClose(data);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const periodos = _(schoolClassConfig)
    .map((x) => x.periodo)
    .value();

  const objPeriodo = _(schoolClassConfig).find(
    (x) => x.periodo === periodos[currentTab]
  );

  const anos = objPeriodo
    ? _(objPeriodo.turmas)
        .map((t) => t.ano)
        .uniq()
        .sort((a, b) => a - b)
        .map((ano) => ({
          ano,
          turmas: _(objPeriodo.turmas)
            .filter((t) => t.ano === ano)
            .sortBy("turma")
            .value(),
        }))
        .value()
    : [];

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={openPopUp}
        onClose={() => setOpenPopUp(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {title}
          <Button
            aria-label="close"
            onClick={() => setOpenPopUp(false)}
            className={classes.closeButton}
          >
            <CloseIcon />
          </Button>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TabsPeriod
                currentTab={currentTab}
                handleCurrentTab={handleCurrentTab}
                periodos={periodos}
              />

              <ReactHookFormRadio
                inputRef={register}
                error={errors.schoolClass && true}
                helperText={errors.schoolClass && errors.schoolClass.message}
                id="schoolClass"
                name="schoolClass"
                control={control}
                defaultValue="serie2"
              >
                <TabPanelYear
                  index={currentTab}
                  anos={anos}
                  register={register}
                  control={control}
                  errors={errors}
                  handleToggle={handleToggle}
                  checked={checked}
                />
              </ReactHookFormRadio>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <FormControl className={classes.formControl}>
            <form
              onSubmit={handleSubmit(onsubmit)}
              className={classes.form}
              noValidate
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.save}
              >
                {saveButtonLabel}
              </Button>
            </form>
          </FormControl>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SchoolClassDialog;
