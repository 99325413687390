import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { DatePicker } from "@material-ui/pickers";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ReactHookFormSelect from "../../../components/common/form/ReactHookFormSelect";
import Button from "@material-ui/core/Button";
import { contentInfoSchema } from "../components/schema/contentInfo";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function ContentInfo(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedDate, handleDateChange] = useState(new Date());
  const { onClose, formConfigI18n } = props;

  const { register, handleSubmit, errors, control } = useForm({
    resolver: yupResolver(contentInfoSchema()),
  });

  const onsubmit = (dialogData) => {
    const data = {
      data: dialogData,
    };
    onClose(data);
  };

  return (
    <>
      <Typography align="center" variant="h4" component="h1" gutterBottom>
        {formConfigI18n.contentInfo.title}
      </Typography>
      <p align="center">{formConfigI18n.contentInfo.subtitle}</p>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            inputRef={register}
            error={errors.contentName && true}
            helperText={
              errors.contentName &&
              formConfigI18n.contentInfo.contentName.requiredErrorMsg
            }
            id="contentName"
            name="contentName"
            label={formConfigI18n.contentInfo.contentName.label}
            placeholder={formConfigI18n.contentInfo.contentName.placeholder}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            inputRef={register}
            error={errors.contentNameEn && true}
            helperText={
              errors.contentNameEn &&
              formConfigI18n.contentInfo.contentNameEn.requiredErrorMsg
            }
            id="contentNameEn"
            name="contentNameEn"
            label={formConfigI18n.contentInfo.contentNameEn.label}
            placeholder={formConfigI18n.contentInfo.contentNameEn.placeholder}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            inputRef={register}
            error={errors.contentNameFr && true}
            helperText={
              errors.contentNameFr &&
              formConfigI18n.contentInfo.contentNameFr.requiredErrorMsg
            }
            id="contentNameFr"
            name="contentNameFr"
            label={formConfigI18n.contentInfo.contentNameFr.label}
            placeholder={formConfigI18n.contentInfo.contentNameFr.placeholder}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            inputRef={register}
            error={errors.contentDescription && true}
            helperText={
              errors.contentDescription &&
              formConfigI18n.contentInfo.contentDescription.requiredErrorMsg
            }
            id="contentDescription"
            name="contentDescription"
            label={formConfigI18n.contentInfo.contentDescription.label}
            multiline
            rowsMax={2}
            placeholder={
              formConfigI18n.contentInfo.contentDescription.placeholder
            }
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            inputRef={register}
            error={errors.contentDescriptionEn && true}
            helperText={
              errors.contentDescriptionEn &&
              formConfigI18n.contentInfo.contentDescriptionEn.requiredErrorMsg
            }
            id="contentDescriptionEn"
            name="contentDescriptionEn"
            label={formConfigI18n.contentInfo.contentDescriptionEn.label}
            multiline
            rowsMax={5}
            placeholder={
              formConfigI18n.contentInfo.contentDescriptionEn.placeholder
            }
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            inputRef={register}
            error={errors.contentDescriptionFr && true}
            helperText={
              errors.contentDescriptionFr &&
              formConfigI18n.contentInfo.contentDescriptionFr.requiredErrorMsg
            }
            id="contentDescriptionFr"
            name="contentDescriptionFr"
            label={formConfigI18n.contentInfo.contentDescriptionFr.label}
            multiline
            rowsMax={5}
            placeholder={
              formConfigI18n.contentInfo.contentDescriptionFr.placeholder
            }
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ReactHookFormSelect
            inputRef={register}
            error={errors.discipline && true}
            helperText={
              errors.discipline &&
              formConfigI18n.contentInfo.discipline.requiredErrorMsg
            }
            id="discipline"
            name="discipline"
            label={formConfigI18n.contentInfo.discipline.label}
            style={{ width: "100%" }}
            control={control}
            defaultValue="Biologia"
          >
            <MenuItem value="Física">Física</MenuItem>
            <MenuItem value="Química">Química</MenuItem>
            <MenuItem value="Biologia">Biologia</MenuItem>
            <MenuItem value="Ciências">Ciências da Natureza</MenuItem>
          </ReactHookFormSelect>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            inputRef={register}
            error={errors.schoolSubjects && true}
            helperText={
              errors.schoolSubjects &&
              formConfigI18n.contentInfo.schoolSubjects.requiredErrorMsg
            }
            id="schoolSubjects"
            name="schoolSubjects"
            label={formConfigI18n.contentInfo.schoolSubjects.label}
            placeholder={formConfigI18n.contentInfo.schoolSubjects.placeholder}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            inputRef={register}
            error={errors.topic && true}
            helperText={
              errors.topic && formConfigI18n.contentInfo.topic.requiredErrorMsg
            }
            id="topic"
            name="topic"
            label={formConfigI18n.contentInfo.topic.label}
            placeholder={formConfigI18n.contentInfo.topic.placeholder}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            inputRef={register}
            error={errors.bncc && true}
            helperText={
              errors.bncc && formConfigI18n.contentInfo.bncc.requiredErrorMsg
            }
            id="bncc"
            name="bncc"
            label={formConfigI18n.contentInfo.bncc.label}
            placeholder={formConfigI18n.contentInfo.bncc.placeholder}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ReactHookFormSelect
            inputRef={register}
            error={errors.client && true}
            helperText={
              errors.client &&
              formConfigI18n.contentInfo.client.requiredErrorMsg
            }
            id="client"
            name="client"
            label={formConfigI18n.contentInfo.client.label}
            style={{ width: "100%" }}
            control={control}
            defaultValue="Escola A"
          >
            <MenuItem value="Escola A">Escola A</MenuItem>
            <MenuItem value="Escola B">Escola B</MenuItem>
            <MenuItem value="Escola C">Escola C</MenuItem>
          </ReactHookFormSelect>
        </Grid>

        <Grid item xs={12} sm={6}>
          <ReactHookFormSelect
            inputRef={register}
            error={errors.schoolLevel && true}
            helperText={
              errors.schoolLevel &&
              formConfigI18n.contentInfo.schoolLevel.requiredErrorMsg
            }
            id="schoolLevel"
            name="schoolLevel"
            label={formConfigI18n.contentInfo.schoolLevel.label}
            style={{ width: "100%" }}
            control={control}
            defaultValue="Fundamental 1"
          >
            <MenuItem value="Fundamental 1">Fundamental 1</MenuItem>
            <MenuItem value="Fundamental 2">Fundamental 2</MenuItem>
            <MenuItem value="Ensino Médio">Ensino Médio</MenuItem>
          </ReactHookFormSelect>
        </Grid>

        <Grid item xs={12} sm={6}>
          <ReactHookFormSelect
            inputRef={register}
            error={errors.schoolGrade && true}
            helperText={
              errors.schoolGrade &&
              formConfigI18n.contentInfo.schoolGrade.requiredErrorMsg
            }
            id="schoolGrade"
            name="schoolGrade"
            label={formConfigI18n.contentInfo.schoolGrade.label}
            style={{ width: "100%" }}
            control={control}
            defaultValue="6"
          >
            <MenuItem value="6">6</MenuItem>
            <MenuItem value="7">7</MenuItem>
            <MenuItem value="8">8</MenuItem>
            <MenuItem value="9">9</MenuItem>
            <MenuItem value="EM1">EM1</MenuItem>
            <MenuItem value="EM2">EM2</MenuItem>
            <MenuItem value="EM3">EM3</MenuItem>
          </ReactHookFormSelect>
        </Grid>

        <Grid item xs={12} sm={6}>
          <ReactHookFormSelect
            inputRef={register}
            error={errors.companyName && true}
            helperText={
              errors.companyName &&
              formConfigI18n.contentInfo.companyName.requiredErrorMsg
            }
            id="companyName"
            name="companyName"
            label={formConfigI18n.contentInfo.companyName.label}
            style={{ width: "100%" }}
            control={control}
            defaultValue="Empresa 1"
          >
            <MenuItem value="Empresa 1">Empresa 1</MenuItem>
            <MenuItem value="Empresa 2">Empresa 2</MenuItem>
            <MenuItem value="Empresa 3">Empresa 3</MenuItem>
          </ReactHookFormSelect>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            inputRef={register}
            error={errors.author && true}
            helperText={
              errors.author &&
              formConfigI18n.contentInfo.author.requiredErrorMsg
            }
            id="author"
            name="author"
            label={formConfigI18n.contentInfo.author.label}
            placeholder={formConfigI18n.contentInfo.author.placeholder}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <DatePicker
            inputRef={register}
            id="effectiveDate"
            name="effectiveDate"
            views={["year", "month", "date"]}
            label={formConfigI18n.contentInfo.effectiveDate.label}
            helperText={
              errors.effectiveDate &&
              formConfigI18n.contentInfo.effectiveDate.requiredErrorMsg
            }
            disablePast
            openTo="year"
            format="DD/MM/YYYY"
            value={selectedDate}
            onChange={handleDateChange}
            style={{ width: "100%" }}
          />
        </Grid>

        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleSubmit(onsubmit)}
          >
            {formConfigI18n.contentInfo.nextButton}
          </Button>
        </div>
      </Grid>
    </>
  );
}
