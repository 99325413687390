import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import Layout from "../components/common/hoc/Layout";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import { useDispatch } from "react-redux";
import { setTheme } from "../store/themeManager";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { MenuItem, Select } from "@material-ui/core";
import Brightness5Icon from "@material-ui/icons/Brightness5";
import Brightness2Icon from "@material-ui/icons/Brightness2";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.dark,
  },
  moonIcon: {
    transform: "rotate(150deg)",
  },
  formControl: {
    width: "100%",
  },
}));

const Settings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const themeConfigState =
    localStorage.getItem("themeConfig") === null
      ? false
      : localStorage.getItem("themeConfig") === "Light"
      ? false
      : true;
  const [defaultTheme, setDefaultTheme] = useState(themeConfigState);
  const { t } = useTranslation("translation");
  const { i18n } = useTranslation();
  const [dropdownLang, setDropdownLang] = useState(i18n.language || "en-US");

  const languageHandler = (event) => {
    const newLanguage = event.target.value;
    if (dropdownLang !== newLanguage) {
      setDropdownLang(newLanguage);
      i18n.changeLanguage(newLanguage);
    }
  };

  const handleChange = (event) => {
    setDefaultTheme(event.target.checked);

    if (defaultTheme) {
      dispatch(
        setTheme({
          themeName: "Light",
        })
      );
    } else {
      dispatch(
        setTheme({
          themeName: "Dark",
        })
      );
    }
  };

  const currentFlag = (lang) => {
    switch (lang) {
      case "pt-BR":
        return (
          <Avatar className={classes.avatar} src="/images/flags/brasil.png" />
        );
      case "en":
        return (
          <Avatar
            className={classes.avatar}
            src="/images/flags/estados-unidos.png"
          />
        );
      case "fr":
        return (
          <Avatar className={classes.avatar} src="/images/flags/franca.png" />
        );
      default:
        return (
          <Avatar className={classes.avatar} src="/images/estados-unidos.png" />
        );
    }
  };

  return (
    <Layout title={t("settings.title")}>
      <main>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <Grid>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                  <Avatar className={classes.avatar}>
                    {defaultTheme === true ? (
                      <Brightness2Icon color="primary" />
                    ) : (
                      <Brightness5Icon color="primary" />
                    )}
                  </Avatar>
                </Grid>
                <Grid item xs>
                  <FormControl component="fieldset" variant="standard">
                    <FormLabel component="legend">
                      {t("settings.themeLabel")}
                    </FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={defaultTheme}
                            onChange={handleChange}
                            name="Dark"
                            color="primary"
                          />
                        }
                        label="Dark"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>{currentFlag(dropdownLang)}</Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="language">
                    {t("settings.languageLabel")}
                  </InputLabel>
                  <Select
                    labelId="language"
                    id="language-select"
                    value={dropdownLang}
                    defaultValue={dropdownLang}
                    onChange={languageHandler}
                  >
                    <MenuItem value="en">{t("settings.languages.en")}</MenuItem>
                    <MenuItem value="pt-BR">
                      {t("settings.languages.pt-BR")}
                    </MenuItem>
                    <MenuItem value="fr">{t("settings.languages.fr")}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </main>
    </Layout>
  );
};

export { Settings };
