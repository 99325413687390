import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    maxWidth: 480,
  },
  logo: {
    width: 100,
    margin: "1rem",
  },
}));

const UserForm = (Component) => {
  const classes = useStyles();
  return (
    <Container component="main">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={12}>
          <img
            className={classes.logo}
            src="/images/regular/icone_xperience_normal.svg"
            alt="logo"
          />
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paper}>{Component.children}</Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UserForm;
