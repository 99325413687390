import ApiService from "./../services/ApiService";

// Action types
const INTERACTIONS_LIST_REQUEST = "INTERACTIONS_LIST_REQUEST";
const INTERACTIONS_LIST_SUCCESS = "INTERACTIONS_LIST_SUCCESS";
const INTERACTIONS_LIST_FAIL = "INTERACTIONS_LIST_FAIL";

const INTERACTION_DETAILS_REQUEST = "INTERACTION_DETAILS_REQUEST";
const INTERACTION_DETAILS_SUCCESS = "INTERACTION_DETAILS_SUCCESS";
const INTERACTION_DETAILS_FAIL = "INTERACTION_DETAILS_FAIL";

const INTERACTION_DETAILS_UPDATE_REQUEST = "INTERACTION_DETAILS_UPDATE_REQUEST";
const INTERACTION_DETAILS_UPDATE_SUCCESS = "INTERACTION_DETAILS_UPDATE_SUCCESS";
const INTERACTION_DETAILS_UPDATE_FAIL = "INTERACTION_DETAILS_UPDATE_FAIL";

const INTERACTION_SEARCH_REQUEST = "INTERACTION_SEARCH_REQUEST";
const INTERACTION_SEARCH_SUCCESS = "INTERACTION_SEARCH_SUCCESS";
const INTERACTION_SEARCH_FAIL = "INTERACTION_SEARCH_FAIL";

const INTERACTION_TAGS_SEARCH_REQUEST = "INTERACTION_TAGS_SEARCH_REQUEST";
const INTERACTION_TAGS_SEARCH_SUCCESS = "INTERACTION_TAGS_SEARCH_SUCCESS";
const INTERACTION_TAGS_SEARCH_FAIL = "INTERACTION_TAGS_SEARCH_FAIL";
const INTERACTION_RESET = "INTERACTION_RESET";

// Reducer
export const interactionListReducer = (
  state = {
    interactions: [],
    hasMore: false,
    lastTypeOfSearch: "default",
    keyword: "",
  },
  action
) => {
  switch (action.type) {
    case INTERACTIONS_LIST_REQUEST:
      return {
        loading: true,
        interactions: state.interactions,
      };
    case INTERACTIONS_LIST_SUCCESS:
      return {
        loading: false,
        interactions: [...state.interactions, ...action.payload],
        hasMore: action.payload.length > 0 ? true : false,
        lastTypeOfSearch: "default",
      };
    case INTERACTIONS_LIST_FAIL:
      return { loading: false, error: action.payload };
    case INTERACTION_SEARCH_REQUEST:
      return {
        loading: true,
        interactions: state.interactions,
        hasMore: false,
        lastTypeOfSearch: "keyword",
        keyword: action.keyword,
      };

    case INTERACTION_SEARCH_SUCCESS:
      return {
        loading: false,
        interactions: [...state.interactions, ...action.payload],
        hasMore: action.payload.length > 0 ? true : false,
        lastTypeOfSearch: "keyword",
        keyword: action.keyword,
      };
    case INTERACTION_SEARCH_FAIL:
      return { loading: false, error: action.payload };
    case INTERACTION_TAGS_SEARCH_REQUEST:
      return { loading: true, interactions: state.interactions };
    case INTERACTION_TAGS_SEARCH_SUCCESS:
      return {
        loading: false,
        interactions: [...state.interactions, ...action.payload],
        hasMore: action.payload.length > 0 ? true : false,
        lastTypeOfSearch: "tag",
      };
    case INTERACTION_TAGS_SEARCH_FAIL:
      return { loading: false, error: action.payload };
    case INTERACTION_RESET:
      return { loading: true, interactions: [] };
    default:
      return state;
  }
};

export const interactionDetailsReducer = (
  state = { interaction: {} },
  action
) => {
  switch (action.type) {
    case INTERACTION_DETAILS_REQUEST:
      return { loading: true, ...state };
    case INTERACTION_DETAILS_SUCCESS:
      return { loading: false, interaction: action.payload };
    case INTERACTION_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const interactionDetailsUpdateReducer = (
  state = { interaction: {}, loading: false, error: false },
  action
) => {
  switch (action.type) {
    case INTERACTION_DETAILS_UPDATE_REQUEST:
      return { ...state, loading: true };
    case INTERACTION_DETAILS_UPDATE_SUCCESS:
      return { loading: false, interaction: action.payload };
    case INTERACTION_DETAILS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Action Creators
export const listInteractions =
  (page = 1, limit = 10, keyword, tags) =>
  async (dispatch) => {
    dispatch({ type: INTERACTIONS_LIST_REQUEST });

    let result;
    try {
      if (keyword) {
        result = await ApiService.get(
          `/content/library?keyword=${keyword}&limit=${limit}`
        );
      } else if (tags && tags.length > 0) {
        const tagsParams = tags.map((tag) => tag.value);
        const subjects = tagsParams.toString();
        result = await ApiService.get(
          `/content/library?subject=${subjects}&limit=${limit}`
        );
      } else {
        result = await ApiService.get(
          `/content/library?limit=${limit}&offset=${page}&order=ASC`
        );
      }

      const { data } = result;

      dispatch({
        type: INTERACTIONS_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INTERACTIONS_LIST_FAIL,
        payload: error,
      });
    }
  };

export const searchKeywordRequest = (keyword) => async (dispatch) => {
  if (keyword) {
    dispatch({ type: INTERACTION_SEARCH_REQUEST, keyword });
  } else {
    dispatch({ type: INTERACTION_SEARCH_REQUEST });
  }
};

export const searchInteractions =
  (offset = 1, limit = 10, keyword) =>
  async (dispatch) => {
    let result;
    try {
      if (keyword) {
        result = await ApiService.get(
          `/content/library?keyword=${keyword}&limit=${limit}&offset=${offset}`
        );
      }
      const { data } = result;

      dispatch({
        type: INTERACTION_SEARCH_SUCCESS,
        payload: data,
        keyword,
      });
    } catch (error) {
      dispatch({
        type: INTERACTION_SEARCH_FAIL,
        payload: error,
      });
    }
  };

export const filterInteractionsByTags =
  (offset = 1, limit = 10, tags) =>
  async (dispatch) => {
    dispatch({ type: INTERACTION_TAGS_SEARCH_REQUEST });

    let result;
    try {
      if (tags && tags.length > 0) {
        const tagsParams = tags.map((tag) => tag.value);
        const subjects = tagsParams.toString();
        result = await ApiService.get(
          `/content/library?subject=${subjects}&limit=${limit}&offset=${offset}`
        );
      }

      const { data } = result;

      dispatch({
        type: INTERACTION_TAGS_SEARCH_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INTERACTION_TAGS_SEARCH_FAIL,
        payload: error,
      });
    }
  };

export const listInteractionDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: INTERACTION_DETAILS_REQUEST });

    const { data } = await ApiService.get(`/content/details/${id}`);
    dispatch({
      type: INTERACTION_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: INTERACTION_DETAILS_FAIL,
      payload: error,
    });
  }
};

export const listInteractionUpdateDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: INTERACTION_DETAILS_UPDATE_REQUEST,
      loading: true,
      error: false,
    });

    const { data } = await ApiService.get(`/content/details/update/${id}`);
    dispatch({
      type: INTERACTION_DETAILS_UPDATE_SUCCESS,
      payload: data,
      loading: false,
      error: false,
    });
  } catch (error) {
    dispatch({
      type: INTERACTION_DETAILS_UPDATE_FAIL,
      payload: error,
      loading: false,
      error: true,
    });
  }
};
