import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  videoResponsive: {
    overflow: "hidden",
    paddingBottom: "56.25%",
    position: "relative",
    height: 0,
  },

  videoIframe: {
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
    position: "absolute",
    padding: theme.spacing(1),
  },
}));

const YoutubePlayer = ({ embedId }) => {
  const classes = useStyles();
  return (
    <div className={classes.videoResponsive}>
      <iframe
        className={classes.videoIframe}
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
};

YoutubePlayer.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default YoutubePlayer;
