import ApiService from "../../../services/ApiService";

// Action types
const MY_ACTIVITIES_LIST_REQUEST = "MY_ACTIVITIES_LIST_REQUEST";
const MY_ACTIVITIES_LIST_SUCCESS = "MY_ACTIVITIES_LIST_SUCCESS";
const MY_ACTIVITIES_LIST_FAIL = "MY_ACTIVITIES_LIST_FAIL";

const MY_ACTIVITIES_DELETE_REQUEST = "MY_ACTIVITIES_DELETE_REQUEST";
const MY_ACTIVITIES_DELETE_SUCCESS = "MY_ACTIVITIES_DELETE_SUCCESS";
const MY_ACTIVITIES_DELETE_FAIL = "MY_ACTIVITIES_DELETE_FAIL";

// Reducer
export const myActivitiesReducer = (state = { interactions: [] }, action) => {
  switch (action.type) {
    case MY_ACTIVITIES_LIST_REQUEST:
      return { loading: true, myActivities: [] };
    case MY_ACTIVITIES_LIST_SUCCESS:
      return { loading: false, myActivities: action.payload };
    case MY_ACTIVITIES_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const myActivities =
  (page = 1, limit = 100, keyword = "") =>
  async (dispatch) => {
    dispatch({ type: MY_ACTIVITIES_LIST_REQUEST });
    try {
      const { data } = await ApiService.get(`/lecture?limitOffset=${limit}`);

      dispatch({
        type: MY_ACTIVITIES_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MY_ACTIVITIES_LIST_FAIL,
        payload: error,
      });
    }
  };

export const deleteSingleActivity = (id) => async (dispatch) => {
  try {
    dispatch({ type: MY_ACTIVITIES_DELETE_REQUEST });

    const { data } = await ApiService.delete(`/lecture/${id}`);

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteMultipleActivity = (activities) => async (dispatch) => {
  const ids = activities.map((activity) => activity.id);

  try {
    dispatch({ type: MY_ACTIVITIES_DELETE_REQUEST });

    const { data } = await ApiService.delete(`/lecture/${ids.toString()}`);

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
