import * as yup from "yup";

const { setLocale } = yup;

setLocale({
  mixed: {
    notType: "the ${path} is obligatory",
    required: "the field ${path} is obligatory",
    oneOf: "the field ${path} must have one of the following values: ${values}",
  },
});

const SUPPORTED_TARGET_FORMATS = ["image/jpg", "image/jpeg"];
const SUPPORTED_IMAGE_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
const SUPPORTED_AUDIO_FORMATS = ["audio/ogg", "audio/mpeg", "audio/wav"];
const SUPPORTED_VIDEO_FORMATS = ["video/ogg", "video/mp4"];

const IMAGE_SIZE = 2000 * 1024; //2MB
const VIDEO_SIZE = 10000 * 1024; //10MB
const AUDIO_SIZE = 10000 * 1024; //10MB

const custom = yup.object().shape({
  title: yup.string().max(100).required(),
  markerFile: yup
    .mixed()
    .test("fileFormat", "select a valid file: .jpg or .jpeg", function (value) {
      if (!value.length) return false; // attachment is required
      return SUPPORTED_TARGET_FORMATS.includes(value[0].type);
    })
    .test("fileSize", "File too large", function (value) {
      if (!value.length) return false; // attachment is required
      return value[0].size <= IMAGE_SIZE;
    }),

  imageFile: yup
    .mixed()
    .test(
      "fileFormat",
      "select a valid file: .jpg, .jpeg or .png",
      function (value) {
        if (!value.length) return true; // attachment is optional
        return SUPPORTED_IMAGE_FORMATS.includes(value[0].type);
      }
    )
    .test("fileSize", "File too large", function (value) {
      if (!value.length) return true; // attachment is optional
      return value[0].size <= IMAGE_SIZE;
    }),

  videoFile: yup
    .mixed()
    .test("fileFormat", "select a valid file: .ogg or .mp4", function (value) {
      if (!value.length) return true; // attachment is optional
      return SUPPORTED_VIDEO_FORMATS.includes(value[0].type);
    })
    .test("fileSize", "File too large", function (value) {
      if (!value.length) return true; // attachment is optional
      return value[0].size <= VIDEO_SIZE;
    }),

  audioFile: yup
    .mixed()
    .test(
      "fileFormat",
      "select a valid file: .ogg, .mpeg or .wav",
      function (value) {
        if (!value.length) return true; // attachment is optional
        return SUPPORTED_AUDIO_FORMATS.includes(value[0].type);
      }
    )
    .test("fileSize", "File too large", function (value) {
      if (!value.length) return true; // attachment is optional
      return value[0].size <= AUDIO_SIZE;
    }),
  externalUrl: yup.string().max(200),
  checkValuesSet: yup
    .bool()
    .when(["imageFile", "videoFile", "audioFile", "externalUrl"], {
      is: (imageFile, videoFile, audioFile, externalUrl) =>
        imageFile.length <= 0 &&
        videoFile.length <= 0 &&
        audioFile.length <= 0 &&
        externalUrl.length <= 0,
      then: yup.bool().required("Choose at least one"),
      otherwise: yup.bool(),
    }),
});

export { custom };
