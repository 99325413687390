import * as yup from "yup";

const SUPPORTED_IMAGE_FORMATS = ["image/jpg", "image/jpeg"];
const IMAGE_SIZE = 2000 * 1024; //2 MB

const simple = yup.object().shape({
  title: yup.string().max(1000).notRequired(),
  markerFile: yup
    .mixed()
    .test("fileFormat", "Unsupported Format", function (value) {
      if (value.length > 0) {
        return SUPPORTED_IMAGE_FORMATS.includes(value[0].type);
      } else {
        return true;
      }
    })
    .test("fileSize", "File too large", function (value) {
      if (value.length > 0) {
        return value[0].size <= IMAGE_SIZE;
      } else {
        return true;
      }
    }),
});

export { simple };
