import ApiService from "../../../services/ApiService";
import _ from "lodash";

const createSchoolClass = (data) => {
  const schoolClassData = Object.values(
    _(data)
      .groupBy("periodo")
      .map((value, key) => ({ periodo: key, turmas: value }))
      .value()
  );
  return schoolClassData;
};

// Action types
const SCHOOL_CLASS_CONFIG_REQUEST = "SCHOOL_CLASS_CONFIG_REQUEST";
const SCHOOL_CLASS_CONFIG_SUCCESS = "SCHOOL_CLASS_CONFIG_SUCCESS";
const SCHOOL_CLASS_CONFIG_FAIL = "SCHOOL_CLASS_CONFIG_FAIL";

// Reducer
export const schoolClassConfigReducer = (
  state = { schoolClassConfig: [] },
  action
) => {
  switch (action.type) {
    case SCHOOL_CLASS_CONFIG_REQUEST:
      return { loading: true, schoolClassConfig: [] };
    case SCHOOL_CLASS_CONFIG_SUCCESS:
      return {
        loading: false,
        error: false,
        schoolClassConfig: [...state.schoolClassConfig, ...action.payload],
      };
    case SCHOOL_CLASS_CONFIG_FAIL:
      return {
        loading: false,
        error: action.payload,
        schoolClassConfig: [...state.schoolClassConfig],
      };
    default:
      return state;
  }
};

export const listSchoolClassConfigStudent = () => async (dispatch) => {
  try {
    dispatch({ type: SCHOOL_CLASS_CONFIG_REQUEST });

    const { data } = await ApiService.get("/room/student");

    const filteredData = createSchoolClass(data.rooms);

    dispatch({
      type: SCHOOL_CLASS_CONFIG_SUCCESS,
      payload: filteredData,
    });
  } catch (error) {
    dispatch({
      type: SCHOOL_CLASS_CONFIG_FAIL,
      payload: error,
    });
  }
};

export const listSchoolClassConfigProfessor = () => async (dispatch) => {
  try {
    dispatch({ type: SCHOOL_CLASS_CONFIG_REQUEST });

    const { data } = await ApiService.get("/room/professor");

    const filteredData = createSchoolClass(data.rooms);

    dispatch({
      type: SCHOOL_CLASS_CONFIG_SUCCESS,
      payload: filteredData,
    });
  } catch (error) {
    dispatch({
      type: SCHOOL_CLASS_CONFIG_FAIL,
      payload: error,
    });
  }
};
