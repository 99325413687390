import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import ReactHookFormSelect from "../../../components/common/form/ReactHookFormSelect";
import MenuItem from "@material-ui/core/MenuItem";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schoolClassSchema } from "./schema/schoolClass";

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  buttonAdd: {
    marginLeft: theme.spacing(1),
  },
  error: {
    border: "1px solid" + theme.palette.error.main,
  },
}));
const Edit = (props) => {
  const classes = useStyles();
  const {
    isEnabledFormEdit,
    editHandler,
    loading,
    show,
    formConfigI18n,
    currentData,
  } = props;
  const { register, handleSubmit, errors, reset, control } = useForm({
    resolver: yupResolver(schoolClassSchema()),
  });

  const [inputState, setInputState] = useState({});

  const [schoolGrade, setSchoolGrade] = useState();

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevVisibility = usePrevious(currentData);

  useEffect(() => {
    if (prevVisibility !== currentData) {
      const newState = {
        ...inputState,
        id: currentData.id,
        period: currentData.periodo,
        schoolLevel: currentData.nivelEscolar,
        schoolGrade: currentData.serie,
        schoolClass: currentData.turma,
      };
      setInputState(newState);
      setSchoolGrade(newState.schoolLevel);
    }
  }, [currentData, inputState, prevVisibility]);

  const onsubmit = (data) => {
    reset();
    editHandler(data);
  };

  const schoolData = {
    period: ["matutino", "vespertino", "noturno", "integral"], //ok
    schoolLevel: {
      EF1: [1, 2, 3, 4, 5],
      EF2: [6, 7, 8, 9],
      EM: [1, 2, 3],
    },
  };

  const setGrade = (event) => {
    if (event.target.value !== 0) setSchoolGrade(event.target.value);
  };

  const handleCancel = () => {
    reset();
    show();
  };

  return (
    <>
      {!isEnabledFormEdit && (
        <Box component="span">
          <Paper elevation={1}>
            <Container maxWidth={false} className={classes.container}>
              <Typography
                className={classes.title}
                variant="h5"
                component="h1"
                gutterBottom
              >
                {formConfigI18n.editForm.title}
              </Typography>

              <form onSubmit={handleSubmit(onsubmit)} noValidate>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} lg={6}>
                    <ReactHookFormSelect
                      inputRef={register}
                      error={errors.period && true}
                      helperText={
                        errors.period &&
                        formConfigI18n.editForm.period.requiredErrorMsg
                      }
                      id="period"
                      name="period"
                      label={formConfigI18n.editForm.period.label}
                      style={{ width: "100%" }}
                      control={control}
                      defaultValue={inputState.period}
                    >
                      {schoolData.period.map((value, index) => {
                        return <MenuItem value={value}>{value}</MenuItem>;
                      })}
                    </ReactHookFormSelect>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={6}>
                    <ReactHookFormSelect
                      inputRef={register}
                      error={errors.schoolLevel && true}
                      helperText={
                        errors.schoolLevel &&
                        formConfigI18n.editForm.schoolLevel.requiredErrorMsg
                      }
                      id="schoolLevel"
                      name="schoolLevel"
                      label={formConfigI18n.editForm.schoolLevel.label}
                      style={{ width: "100%" }}
                      control={control}
                      defaultValue={inputState.schoolLevel}
                      onClick={(event) => setGrade(event)}
                    >
                      {Object.keys(schoolData?.schoolLevel).map(
                        (value, index) => {
                          return <MenuItem value={value}>{value}</MenuItem>;
                        }
                      )}
                    </ReactHookFormSelect>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={6}>
                    <ReactHookFormSelect
                      inputRef={register}
                      error={errors.schoolGrade && true}
                      helperText={
                        errors.schoolGrade &&
                        formConfigI18n.editForm.schoolGrade.requiredErrorMsg
                      }
                      id="schoolGrade"
                      name="schoolGrade"
                      label={formConfigI18n.editForm.schoolGrade.label}
                      style={{ width: "100%" }}
                      control={control}
                      defaultValue={inputState.schoolGrade}
                    >
                      {schoolData.schoolLevel[schoolGrade]?.map(
                        (value, index) => {
                          return <MenuItem value={value}>{value}</MenuItem>;
                        }
                      )}
                    </ReactHookFormSelect>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={6}>
                    <TextField
                      inputRef={register}
                      error={errors.schoolClass && true}
                      helperText={
                        errors.schoolClass &&
                        formConfigI18n.editForm.schoolClass.requiredErrorMsg
                      }
                      id="schoolClass"
                      name="schoolClass"
                      label={formConfigI18n.editForm.schoolClass.label}
                      placeholder={
                        formConfigI18n.editForm.schoolClass.placeholder
                      }
                      defaultValue={inputState.schoolClass}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={12}>
                    <div className={classes.buttons}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        disabled={loading}
                        onClick={() => handleCancel()}
                      >
                        {formConfigI18n.editForm.cancelButton}
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.buttonAdd}
                        disabled={loading}
                      >
                        {formConfigI18n.editForm.confirmButton}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </form>
            </Container>
          </Paper>
        </Box>
      )}
    </>
  );
};

export default Edit;
