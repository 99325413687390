import * as yup from "yup";

const studentSchema = () => {
  const schema = yup.object().shape({
    name: yup
      .string()
      //.required(`${contentName.requiredErrorMsg}`),
      .required(),
    registrationCode: yup
      .string()
      //.required(`${contentDescription.requiredErrorMsg}`),
      .required(),
    schoolClass: yup
      .string()
      //.required(`${contentDescription.requiredErrorMsg}`),
      .required(),
  });
  return schema;
};

export { studentSchema };
