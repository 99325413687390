import * as yup from "yup";

const contentManagerSchema = (translateValidation) => {
  const schema = yup.object().shape({
    name: yup.string().required(`${translateValidation.name.requiredErrorMsg}`),
    email: yup
      .string()
      .email()
      .required(`${translateValidation.email.requiredErrorMsg}`),
    role: yup.string().required(`${translateValidation.role.requiredErrorMsg}`),
  });
  return schema;
};

export { contentManagerSchema };
