import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Box from "@material-ui/core/Box";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CustomSpinner from "../../components/common/Spinner/CustomSpinner";
import Layout from "../../components/common/hoc/Layout";
import NotFound from "../../components/common/notFound/NotFound";
import Filter from "./components/Filter";
import { listPerformance } from "../../store/report";
import EnhancedTableHead from "../../components/common/table/EnhancedTableHead";
import { getComparator, stableSort } from "../../utils/tableFilter";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    marginTop: "1rem",
    minWidth: 750,
  },
  rowEnable: {
    outlineColor: theme.palette.secondary.main,
    outlineWidth: "2px",
    outlineStyle: "solid",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  filterButton: {
    marginLeft: theme.spacing(1),
  },
  star: {
    fontSize: "1rem",
  },
}));

const Performance = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation("translation");
  const reportReducer = useSelector((state) => state.reportReducer);
  const { loading, error, performanceData } = reportReducer;
  const history = useHistory();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isEnabledFilter, setIsEnabledFilter] = useState(false);

  const headCells = [
    {
      id: "enrollmentStudent",
      numeric: true,
      disablePadding: false,
      label: t(
        "performanceIndicators.report.performance.headCells.registrationCode"
      ),
    },
    {
      id: "nameStudent",
      numeric: false,
      disablePadding: false,
      label: t(
        "performanceIndicators.report.performance.headCells.studentName"
      ),
    },

    {
      id: "turma",
      numeric: false,
      disablePadding: false,
      label: t(
        "performanceIndicators.report.performance.headCells.schoolClass"
      ),
    },
    {
      id: "serie",
      numeric: false,
      disablePadding: false,
      label: t(
        "performanceIndicators.report.performance.headCells.schoolGrade"
      ),
    },
    {
      id: "nivelEscolar",
      numeric: false,
      disablePadding: false,
      label: t(
        "performanceIndicators.report.performance.headCells.schoolLevel"
      ),
    },
    {
      id: "contentName",
      numeric: false,
      disablePadding: false,
      label: t("performanceIndicators.report.performance.headCells.content"),
    },
    {
      id: "subject",
      numeric: false,
      disablePadding: false,
      label: t("performanceIndicators.report.performance.headCells.subject"),
    },
    {
      id: "timeSpent",
      numeric: true,
      disablePadding: false,
      label: t("performanceIndicators.report.performance.headCells.timeSpent"),
    },
    {
      id: "clickCount",
      numeric: true,
      disablePadding: false,
      label: t("performanceIndicators.report.performance.headCells.clickCount"),
    },
    {
      id: "quizResult",
      numeric: true,
      disablePadding: false,
      label: t("performanceIndicators.report.performance.headCells.quizResult"),
    },
    {
      id: "isFinished",
      numeric: false,
      disablePadding: false,
      label: t("performanceIndicators.report.performance.headCells.isFinished"),
    },
    {
      id: "stars",
      numeric: false,
      disablePadding: false,
      label: t("performanceIndicators.report.performance.headCells.stars"),
    },
    {
      id: "feedback",
      numeric: false,
      disablePadding: false,
      label: t("performanceIndicators.report.performance.headCells.feedback"),
    },
    {
      id: "updatedAt",
      numeric: false,
      disablePadding: false,
      label: t("performanceIndicators.report.performance.headCells.date"),
    },
  ];

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      performanceData.performanceIndicators?.length - page * rowsPerPage
    );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = performanceData.map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatDate = (date) => {
    return moment(date).format("DD/MM/YYYY - HH:mm:ss");
  };

  const getStars = (stars) => {
    const starsList = [];
    if (stars) {
      for (let index = 0; index < stars; index++) {
        starsList.push(<StarIcon color="primary" className={classes.star} />);
      }

      for (let index2 = stars; index2 < 5; index2++) {
        starsList.push(
          <StarBorderIcon color="primary" className={classes.star} />
        );
      }
    } else {
      for (let index = 0; index < 5; index++) {
        starsList.push(
          <StarBorderIcon color="primary" className={classes.star} />
        );
      }
    }
    return starsList;
  };
  const formConfigI18n = {
    filterForm: {
      title: t("report.performance.modal.title"),
      cancelButton: t("student.addForm.cancelButton"),
      confirmButton: t("student.addForm.confirmButton"),
      schoolClassButton: t("student.addForm.schoolClass.label"),
      name: {
        requiredErrorMsg: t("student.addForm.name.requiredErrorMsg"),
        label: t("student.addForm.name.label"),
        placeholder: t("student.addForm.name.placeholder"),
      },
      registrationCode: {
        requiredErrorMsg: t(
          "student.addForm.registrationCode.requiredErrorMsg"
        ),
        label: t("student.addForm.registrationCode.label"),
        placeholder: t("student.addForm.registrationCode.placeholder"),
      },
    },
  };

  useEffect(() => {
    dispatch(listPerformance());
  }, [dispatch]);

  return (
    <Layout title={t("performanceIndicators.report.performance.title")}>
      <Container disableGutters maxWidth={false}>
        <Box component="span">
          <Grid item xs={12} sm={12} lg={12}>
            <div className={classes.buttons}>
              <IconButton
                aria-label="back"
                //onClick={() => history.push("/app/performance-indicators")}
                onClick={() => history.push("/app/")}
              >
                <ArrowBackIosIcon color="primary" fontSize="default" />
              </IconButton>
              {/* TODO: WIP FILTER */}
              {/*                
                <Button
                  className={classes.filterButton}
                  variant="outlined"
                  color="primary"
                  disabled={loading}
                  onClick={() => setIsEnabledFilter((prev) => !prev)}
                >
                  {t('performanceIndicators.report.performance.filterButton')}{' '}
                  (8)
                </Button> */}
            </div>
          </Grid>
        </Box>

        <Filter
          show={isEnabledFilter}
          setIsEnabledFilter={setIsEnabledFilter}
          translateValidation={formConfigI18n.filterForm}
        />

        <>
          {loading ? (
            <CustomSpinner />
          ) : error ? (
            <NotFound
              title="Oops!"
              message="Ocorreu um erro, tente recarregar a página"
              image="/images/notfound.png"
              redirect="/app/content"
            />
          ) : (
            <>
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={"medium"}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    headCells={headCells}
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={performanceData.performanceIndicators?.length}
                    checkbox={false}
                  />
                  <TableBody>
                    {stableSort(
                      performanceData.performanceIndicators,
                      getComparator(order, orderBy)
                    )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={0}
                            key={row.enrollmentStudent}
                          >
                            <TableCell>{row.enrollmentStudent}</TableCell>
                            <TableCell>{row.nameStudent}</TableCell>
                            <TableCell>{row.turma}</TableCell>
                            <TableCell>{row.serie}</TableCell>
                            <TableCell>{row.nivelEscolar}</TableCell>
                            <TableCell>{row.contentName}</TableCell>
                            <TableCell>{row.subject}</TableCell>
                            <TableCell>{Math.floor(row.timeSpent)}</TableCell>
                            <TableCell>{row.clickCount}</TableCell>
                            <TableCell>{row.quizResult}%</TableCell>
                            <TableCell>
                              {row.isFinished === "yes" ? (
                                <CheckIcon color="primary" />
                              ) : (
                                <CloseIcon color="primary" />
                              )}
                            </TableCell>
                            <TableCell>{getStars(row.stars)}</TableCell>
                            <TableCell>
                              {row.feedback
                                ? row.feedback
                                : "Usuário não preencheu"}
                            </TableCell>
                            <TableCell>{formatDate(row.updatedAt)}</TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={headCells.length} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <TablePagination
                  rowsPerPageOptions={[10, 25]}
                  component="div"
                  count={performanceData.performanceIndicators?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Grid>
            </>
          )}
        </>
      </Container>
    </Layout>
  );
};

export { Performance };
