import React, { memo } from "react";
import { isLoggedIn } from "../utils";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";

function Auth() {
  return isLoggedIn() ? <PrivateRoutes /> : <PublicRoutes />;
}

export default memo(Auth);
