import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import ReactHookFormSelect from "../../../components/common/form/ReactHookFormSelect";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schoolClassSchema } from "./schema/schoolClass";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  buttonAdd: {
    marginLeft: theme.spacing(1),
  },
  error: {
    border: "1px solid" + theme.palette.error.main,
  },
}));

const Add = (props) => {
  const classes = useStyles();
  const { isEnabledFormAdd, addHandler, show, formConfigI18n } = props;
  const { register, handleSubmit, errors, reset, control } = useForm({
    mode: "onChange",
    resolver: yupResolver(schoolClassSchema()),
  });

  const schoolClassReducer = useSelector((state) => state.schoolClassReducer);
  const { loading, error } = schoolClassReducer;
  const [schoolGrade, setSchoolGrade] = useState("EF1");
  const [defaultValue, setDefaultValue] = useState("");

  const onsubmit = (data) => {
    addHandler({ ...data });
    reset();
  };
  const schoolData = {
    period: ["matutino", "vespertino", "noturno", "integral"],
    schoolLevel: {
      EF1: [1, 2, 3, 4, 5],
      EF2: [6, 7, 8, 9],
      EM: [1, 2, 3],
    },
  };

  const setGrade = (event) => {
    if (event.target.value !== 0) setSchoolGrade(event.target.value);
    const key = event.target.value.toString();
    const currentValues = schoolData["schoolLevel"][key];
    if (currentValues !== undefined) setDefaultValue(currentValues[0][0]);
  };

  return (
    <>
      <Box component="span" display={isEnabledFormAdd ? "none" : "block"}>
        <Paper elevation={1}>
          <Container maxWidth={false} className={classes.container}>
            <Typography
              className={classes.title}
              variant="h5"
              component="h1"
              gutterBottom
            >
              {formConfigI18n.addForm.title}
            </Typography>

            <form onSubmit={handleSubmit(onsubmit)} noValidate>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} lg={6}>
                  <ReactHookFormSelect
                    inputRef={register}
                    error={errors.period && true}
                    helperText={
                      errors.period &&
                      formConfigI18n.addForm.period.requiredErrorMsg
                    }
                    id="period"
                    name="period"
                    label={formConfigI18n.addForm.period.label}
                    style={{ width: "100%" }}
                    control={control}
                    defaultValue={schoolData.period[0]}
                  >
                    {schoolData.period.map((value, index) => {
                      return <MenuItem value={value}>{value}</MenuItem>;
                    })}
                  </ReactHookFormSelect>
                </Grid>

                <Grid item xs={12} sm={12} lg={6}>
                  <ReactHookFormSelect
                    inputRef={register}
                    error={errors.schoolLevel && true}
                    helperText={
                      errors.schoolLevel &&
                      formConfigI18n.addForm.schoolLevel.requiredErrorMsg
                    }
                    id="schoolLevel"
                    name="schoolLevel"
                    label={formConfigI18n.addForm.schoolLevel.label}
                    style={{ width: "100%" }}
                    control={control}
                    defaultValue={
                      Object.keys(schoolData["schoolLevel"])[0] !== undefined
                        ? Object.keys(schoolData["schoolLevel"])[0]
                        : ""
                    }
                    onClick={(event) => setGrade(event)}
                  >
                    {Object.keys(schoolData?.schoolLevel).map(
                      (value, index) => {
                        return <MenuItem value={value}>{value}</MenuItem>;
                      }
                    )}
                  </ReactHookFormSelect>
                </Grid>

                <Grid item xs={12} sm={12} lg={6}>
                  <ReactHookFormSelect
                    inputRef={register}
                    error={errors.schoolGrade && true}
                    helperText={
                      errors.schoolGrade &&
                      formConfigI18n.addForm.schoolGrade.requiredErrorMsg
                    }
                    id="schoolGrade"
                    name="schoolGrade"
                    label={formConfigI18n.addForm.schoolGrade.label}
                    style={{ width: "100%" }}
                    control={control}
                    defaultValue={defaultValue}
                  >
                    {schoolData &&
                      schoolData.schoolLevel[schoolGrade]?.map(
                        (value, index) => {
                          return <MenuItem value={value}>{value}</MenuItem>;
                        }
                      )}
                  </ReactHookFormSelect>
                </Grid>

                <Grid item xs={12} sm={12} lg={6}>
                  <TextField
                    inputRef={register}
                    error={errors.schoolClass && true}
                    helperText={
                      errors.schoolClass &&
                      formConfigI18n.addForm.schoolClass.requiredErrorMsg
                    }
                    id="schoolClass"
                    name="schoolClass"
                    label={formConfigI18n.addForm.schoolClass.label}
                    placeholder={formConfigI18n.addForm.schoolClass.placeholder}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={12}>
                  <div className={classes.buttons}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      disabled={loading}
                      onClick={show}
                    >
                      {formConfigI18n.addForm.cancelButton}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.buttonAdd}
                      disabled={loading}
                    >
                      {formConfigI18n.addForm.confirmButton}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Paper>
      </Box>
    </>
  );
};

export default Add;
