import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import CustomTooltip from "../../customTooltip/CustomTooltip";
import Zoom from "@material-ui/core/Zoom";
import { logout } from "../../../store/duck/signIn/login";
import { UserRole } from "../../../config/userRole";
import CustomIconButton from "../../customIconButton/CustomIconButton";
import NavBarCart from "./cart";
import {
  HelpIcon,
  ArrowRightIcon,
  SettingsIcon,
  UserIcon,
} from "../../../icons/CustomIcons";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  selected: {
    borderRadius: "10px !important",
    "&$selected": {
      textDecoration: "none",
      backgroundColor: theme.palette.secondary.main,
      borderRadius: "10px",
    },
  },
  ".selected > MuiTouchRipple-root": {
    backgroundColor: "red",
  },
}));

const NavBarUserInfo = () => {
  const { t } = useTranslation("translation");
  const [changedToLogin, setChangedToLogin] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEnableMenu, setIsEnableMenu] = useState({
    help: false,
    account: false,
  });
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const handleClick = (event) => {
    switch (event.currentTarget.id) {
      case "help":
        setIsEnableMenu({ ...isEnableMenu, help: !isEnableMenu.help });
        setAnchorEl(event.currentTarget);
        break;
      case "account":
        setIsEnableMenu({ ...isEnableMenu, account: !isEnableMenu.account });
        setAnchorEl(event.currentTarget);
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    setIsEnableMenu({ ...isEnableMenu, help: false, account: false });
    setAnchorEl(null);
  };

  const handleLogout = () => {
    if (!changedToLogin) {
      dispatch(logout());
      setChangedToLogin(true);
    }
  };

  const redirectToPath = (path) => {
    handleClose();
    history.push(path);
  };

  const i18nConfig = {
    userRole: {
      ADMINISTRADOR_MASTER: t("menu.user.role.ADMINISTRADOR_MASTER"),
      ADMINISTRADOR_CONTRATANTE: t("menu.user.role.ADMINISTRADOR_CONTRATANTE"),
      ADMINISTRADOR_CLIENTE: t("menu.user.role.ADMINISTRADOR_CLIENTE"),
      PROFESSOR: t("menu.user.role.PROFESSOR"),
      TIME_CONTEUDO_XPERIENCE: t("menu.user.role.TIME_CONTEUDO_XPERIENCE"),
      CRIADOR_DE_CONTEUDO_EXTERNO: t(
        "menu.user.role.CRIADOR_DE_CONTEUDO_EXTERNO"
      ),
    },
    help: {
      label: t("menu.navBar.help.label"),
      menu: {
        faq: t("menu.navBar.help.menu.faq"),
        feedback: t("menu.navBar.help.menu.feedback"),
        bugReport: t("menu.navBar.help.menu.bugReport"),
      },
    },
    settings: {
      label: t("menu.navBar.settings.label"),
    },
    account: {
      menu: {
        exit: t("menu.navBar.account.menu.exit"),
      },
    },
  };

  const translateRole = (role) => {
    switch (role) {
      case UserRole.ADMINISTRADOR_MASTER:
        return i18nConfig.userRole.ADMINISTRADOR_MASTER;
      case UserRole.ADMINISTRADOR_CONTRATANTE:
        return i18nConfig.userRole.ADMINISTRADOR_CONTRATANTE;
      case UserRole.ADMINISTRADOR_CLIENTE:
        return i18nConfig.userRole.ADMINISTRADOR_CLIENTE;
      case UserRole.PROFESSOR:
        return i18nConfig.userRole.PROFESSOR;
      case UserRole.TIME_CONTEUDO_XPERIENCE:
        return i18nConfig.userRole.TIME_CONTEUDO_XPERIENCE;
      case UserRole.CRIADOR_DE_CONTEUDO_EXTERNO:
        return i18nConfig.userRole.CRIADOR_DE_CONTEUDO_EXTERNO;
      default:
        return "";
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
      width="100%"
      flexWrap="nowrap"
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        flexWrap="nowrap"
      >
        <CustomTooltip
          title={i18nConfig.help.label}
          arrow
          placement="bottom"
          TransitionComponent={Zoom}
        >
          <CustomIconButton
            onClick={handleClick}
            color="primary"
            id="help"
            className={
              window?.location.pathname.includes("support") && classes.selected
            }
          >
            <HelpIcon />
          </CustomIconButton>
        </CustomTooltip>

        <Menu
          id="help"
          anchorEl={anchorEl}
          keepMounted
          open={isEnableMenu.help}
          onClose={handleClose}
        >
          <MenuItem onClick={() => redirectToPath("/app/support/faq")}>
            <ListItemIcon style={{ minWidth: 30 }}>
              <ArrowRightIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">
              {i18nConfig.help.menu.faq}
            </Typography>
          </MenuItem>

          <MenuItem onClick={() => redirectToPath("/app/support/feedback")}>
            <ListItemIcon style={{ minWidth: 30 }}>
              <ArrowRightIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">
              {" "}
              {i18nConfig.help.menu.feedback}
            </Typography>
          </MenuItem>

          <MenuItem onClick={() => redirectToPath("/app/support/bug-report")}>
            <ListItemIcon style={{ minWidth: 30 }}>
              <ArrowRightIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">
              {" "}
              {i18nConfig.help.menu.bugReport}
            </Typography>
          </MenuItem>
        </Menu>

        <CustomTooltip
          title={i18nConfig.settings.label}
          arrow
          placement="bottom"
          TransitionComponent={Zoom}
        >
          <CustomIconButton
            onClick={() => redirectToPath("/app/settings")}
            color="primary"
            className={
              "/app/settings" === window?.location.pathname && classes.selected
            }
          >
            <SettingsIcon />
          </CustomIconButton>
        </CustomTooltip>
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        flexWrap="nowrap"
        style={{ marginRight: "1rem" }}
      >
        {userInfo && userInfo.role[0] === UserRole.PROFESSOR ? (
          <NavBarCart location={window?.location.pathname} />
        ) : (
          ""
        )}

        <CustomIconButton onClick={handleClick} color="secondary" id="account">
          <UserIcon />
        </CustomIconButton>

        <Menu
          id="account"
          anchorEl={anchorEl}
          keepMounted
          open={isEnableMenu.account}
          onClose={handleClose}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flexWrap="nowrap"
            p={2}
            style={{ minWidth: "250px" }}
          >
            <Avatar
              alt={userInfo && userInfo.username}
              src="/images/default-avatar.png"
              className={classes.avatar}
            />
            <Typography
              color="textPrimary"
              align="left"
              variant={"h6"}
              className={classes.username}
            >
              {userInfo && userInfo.username}
            </Typography>
            <Typography color="textSecondary" align="left" noWrap gutterBottom>
              {userInfo && translateRole(userInfo.role[0])}
            </Typography>
          </Box>
          <MenuItem
            onClick={handleLogout}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ListItemIcon style={{ minWidth: 25 }}>
              <ExitToAppIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">
              {i18nConfig.account.menu.exit}
            </Typography>
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};
NavBarUserInfo.propTypes = {
  collapsed: PropTypes.bool,
};
NavBarUserInfo.defaultProps = {
  collapsed: false,
};

export default NavBarUserInfo;
