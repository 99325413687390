import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import { clearSnackbar } from "../../../store/duck/uiReducer/uiReducer";
import MuiAlert from "@material-ui/lab/Alert";

export default function CustomSnackBar() {
  const dispatch = useDispatch();

  const { snackbarConfig, successSnackbarOpen } = useSelector(
    (state) => state.ui
  );

  function handleClose() {
    dispatch(clearSnackbar());
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <Snackbar
      open={successSnackbarOpen}
      autoHideDuration={snackbarConfig && snackbarConfig.autoHideDuration}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={snackbarConfig && snackbarConfig.severity}
      >
        {snackbarConfig && snackbarConfig.message}
      </Alert>
    </Snackbar>
  );
}
