import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {
  searchInteractions,
  listInteractions,
  searchKeywordRequest,
} from "../../../store/interactions";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: 250,
    },
    marginTop: 0,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    margin: 10,
  },
}));

export default function Search() {
  const classes = useStyles();
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const interactionList = useSelector((state) => state.interactionList);
  const { lastTypeOfSearch, loading, keyword } = interactionList;
  const [currentKeyword, setCurrentKeyword] = useState("");
  const prevCurrentKeyword = usePrevious(currentKeyword);
  const delay = 1000;

  const debounceSearch = useRef(
    debounce(async (keyword) => {
      if (keyword !== "") {
        await dispatch(searchKeywordRequest(keyword));
      }
    }, delay)
  ).current;

  const search = (event) => {
    if (event.target.value && event.target.value !== "") {
      setCurrentKeyword(event.target.value);
    } else {
      clearSearch();
    }
  };

  const clearSearch = async (event) => {
    setCurrentKeyword("");

    await dispatch(searchKeywordRequest());
  };

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  useEffect(() => {
    if (prevCurrentKeyword !== currentKeyword) {
      debounceSearch(currentKeyword);
    } else if (
      prevCurrentKeyword === currentKeyword &&
      lastTypeOfSearch === "tag"
    ) {
      setCurrentKeyword("");
    }
  }, [
    currentKeyword,
    debounceSearch,
    keyword,
    lastTypeOfSearch,
    loading,
    prevCurrentKeyword,
  ]);

  return (
    <Paper component="form" className={classes.root}>
      <SearchIcon color="primary" className={classes.iconButton} />
      <InputBase
        value={currentKeyword}
        onChange={(e) => search(e)}
        onKeyPress={(e) => (e.key === "Enter" ? e.preventDefault() : "")}
        className={classes.input}
        placeholder={t("InteractionsLibrary.search.placeholder")}
        inputProps={{ "aria-label": "search" }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label="clear Search" onClick={clearSearch}>
              {currentKeyword !== "" ? <CloseIcon /> : ""}
            </IconButton>
          </InputAdornment>
        }
      />
    </Paper>
  );
}
