import * as yup from "yup";

const schoolClassSchema = () => {
  const schema = yup.object().shape({
    period: yup.string().required(),
    schoolLevel: yup.string().required(),
    schoolGrade: yup.string().required(),
    schoolClass: yup.string().required(),
  });
  return schema;
};

export { schoolClassSchema };
