import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ClassList from "./ClassList";

const ToggleYear = ({ ano, checked, handleToggle, turmas }) => {
  return (
    <>
      <ListItem button onClick={handleToggle(ano)}>
        <ListItemText primary={ano} />
        {checked.includes(ano) ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={checked.indexOf(ano) !== -1} timeout="auto" unmountOnExit>
        <ClassList turmas={turmas} ano={ano} />
      </Collapse>
    </>
  );
};

export default ToggleYear;
