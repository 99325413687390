import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import { activateUser, updatePassword } from "../store/updatePassword";
import UserForm from "../components/common/hoc/UserForm";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const UpdatePassword = () => {
  const classes = useStyles();
  const update = useSelector((state) => state.updatePasswordReducer);
  const { loading, error } = update;
  const dispatch = useDispatch();
  const { t } = useTranslation("translation");
  const password = useRef({});
  const history = useHistory();
  const { search } = useLocation();
  const queryParams = queryString.parse(search);

  const [consent, setConsent] = useState({
    privacyPolicy: false,
    lgpd: false,
  });

  const schema = yup.object().shape({
    password: yup
      .string()
      .max(100)
      .required(t("signIn.password.requiredErrorMsg")),
    passwordRepeat: yup
      .string()
      .max(100)
      .required(t("signIn.password.requiredErrorMsg"))
      .oneOf([yup.ref("password")], "Passwords must match"),
    privacyPolicy: yup.bool().oneOf([true], "Field must be checked"),
  });

  const { register, handleSubmit, errors, watch } = useForm({
    resolver: yupResolver(schema),
  });

  password.current = watch("password", "");

  const getCurrentPath = () => {
    return history.location.pathname.replace("/", "");
  };

  const handleChange = (event) => {
    setConsent({ ...consent, [event.target.name]: event.target.checked });
  };
  const submitHandler = (formValues) => {
    const pathname = getCurrentPath();

    switch (pathname) {
      case "validate":
        dispatch(activateUser(queryParams.token, formValues.password));
        break;
      case "password":
        dispatch(updatePassword(queryParams.token, formValues.password));
        break;
      default:
        console.log("Invalid path");
        break;
    }
  };

  return (
    <UserForm>
      {error !== undefined && error === false && loading === false ? (
        <>
          <Typography variant="h5" component="h1" gutterBottom>
            {t("updatePassword.formSuccess.title")}
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            {t("updatePassword.formSuccess.message")}
          </Typography>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => history.push("/app")}
          >
            {t("updatePassword.formSuccess.button")}
          </Button>
        </>
      ) : (
        <>
          <Typography variant="h5" component="h1" gutterBottom>
            {t("updatePassword.formPassword.title")}
          </Typography>
          <form
            className={classes.form}
            onSubmit={handleSubmit(submitHandler)}
            noValidate
          >
            <TextField
              name="password"
              id="password"
              type="password"
              label={t("updatePassword.formPassword.password.label")}
              inputRef={register}
              error={errors.password && true}
              helperText={
                errors.password &&
                t("updatePassword.formPassword.password.requiredErrorMsg")
              }
              variant="outlined"
              margin="normal"
              fullWidth
              placeholder={t(
                "updatePassword.formPassword.password.placeholder"
              )}
            />

            <TextField
              name="passwordRepeat"
              id="passwordRepeat"
              type="password"
              label={t("updatePassword.formPassword.passwordRepeat.label")}
              inputRef={register}
              error={errors.password && true}
              helperText={
                errors.password &&
                t("updatePassword.formPassword.passwordRepeat.requiredErrorMsg")
              }
              variant="outlined"
              margin="normal"
              fullWidth
              placeholder={t(
                "updatePassword.formPassword.passwordRepeat.placeholder"
              )}
            />

            {getCurrentPath() === "validate" && (
              <>
                <Typography variant="body2" component="h6" gutterBottom>
                  {t("signIn.consent")}
                </Typography>

                <FormGroup>
                  <FormControlLabel
                    inputRef={register}
                    name="privacyPolicy"
                    control={
                      <Checkbox
                        checked={consent.privacyPolicy}
                        onChange={handleChange}
                        color="primary"
                      />
                    }
                    label={
                      <>
                        {t("signIn.privacyPolicy.label")}{" "}
                        <Link
                          href="https://xperiencexr.com/politica-de-privacidade-e-cookies/"
                          target="_blank"
                          rel="noopener"
                        >
                          {t("signIn.privacyPolicy.linkLabel")}
                        </Link>
                      </>
                    }
                  />
                </FormGroup>
                {errors && errors.privacyPolicy && (
                  <Typography
                    variant="subtitle1"
                    align="center"
                    component="h3"
                    gutterBottom
                    color="error"
                  >
                    {t("signIn.privacyPolicy.requiredErrorMsg")}
                  </Typography>
                )}
              </>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {getCurrentPath() === "password"
                ? t("updatePassword.formPassword.buttonUpdatePassword")
                : t("updatePassword.formPassword.buttonValidateUser")}
            </Button>

            {loading ? (
              <LinearProgress />
            ) : error ? (
              <Typography
                variant="subtitle1"
                color="error"
                align="center"
                component="h3"
                gutterBottom
              >
                {t("signIn.loginErrorMsg")}
              </Typography>
            ) : (
              ""
            )}
          </form>
        </>
      )}
    </UserForm>
  );
};

export { UpdatePassword };
