import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { adminSchema } from "./schema/admin";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  buttonAdd: {
    marginLeft: theme.spacing(1),
  },
}));

const Add = (props) => {
  const classes = useStyles();
  const {
    isEnabledFormAddAdmin,
    addHandler,
    loading,
    show,
    title,
    name,
    email,
    cancelButton,
    confirmButton,
  } = props;
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(adminSchema()),
  });

  const onsubmit = (data) => {
    reset();
    addHandler(data);
  };
  return (
    <Box component="span" display={isEnabledFormAddAdmin ? "none" : "block"}>
      <Paper elevation={1}>
        <Container maxWidth={false} className={classes.container}>
          <Typography
            className={classes.title}
            variant="h5"
            component="h1"
            gutterBottom
          >
            {title}
          </Typography>

          <form fullWidth onSubmit={handleSubmit(onsubmit)} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} lg={4}>
                <TextField
                  inputRef={register}
                  error={errors.name && true}
                  helperText={errors.name && name.requiredErrorMsg}
                  id="name"
                  name="name"
                  label={name.label}
                  placeholder={name.placeholder}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={12} lg={4}>
                <TextField
                  inputRef={register}
                  error={errors.email && true}
                  helperText={errors.email && email.requiredErrorMsg}
                  id="email"
                  name="email"
                  label={email.label}
                  placeholder={email.placeholder}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={12} lg={12}>
                <div className={classes.buttons}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    disabled={loading}
                    onClick={show}
                  >
                    {cancelButton}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.buttonAdd}
                    disabled={loading}
                  >
                    {confirmButton}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Paper>
    </Box>
  );
};

export default Add;
