import * as yup from "yup";

const contractorSchema = (translateValidation) => {
  const schema = yup.object().shape({
    name: yup.string().required(`${translateValidation.name.requiredErrorMsg}`),
    email: yup
      .string()
      .email()
      .required(`${translateValidation.email.requiredErrorMsg}`),
    cnpj: yup
      .string()
      .matches(
        /(^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$)/,
        `${translateValidation.cnpj.requiredErrorMsg}`
      )
      .required(`${translateValidation.cnpj.requiredErrorMsg}`),
  });
  return schema;
};

export { contractorSchema };
