import React from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import darkTheme from '../../../../themes/darkTheme'
import lightTheme from '../../../../themes/lightTheme'

export default function ThemeManager(Component) {
  const { themeConfig } = useSelector((state) => state.themeManager)
  return (
    <React.Fragment>
      <ThemeProvider
        key={themeConfig.themeName}
        theme={themeConfig.themeName === 'Light' ? lightTheme : darkTheme}
      >
        {Component.children}
      </ThemeProvider>
    </React.Fragment>
  )
}
