import CssBaseline from "@material-ui/core/CssBaseline";
import React, { Suspense } from "react";
import Routes from "./routes";
import CustomSnackBar from "./components/common/SnackBar/CustomSnackBar";
import CustomConfirmationDialog from "./components/common/dialog/CustomConfirmationDialog";
import ThemeManager from "./components/common/hoc/ThemeManager/themeManager";

function App() {
  return (
    <>
      <Suspense fallback={<p>Loading Translations ...</p>}></Suspense>
      <ThemeManager>
        <CssBaseline />
        <div className="App">
          <CustomSnackBar />
          <CustomConfirmationDialog />
          <Routes />
        </div>
      </ThemeManager>
    </>
  );
}

export default App;
