import ApiService from "./../services/ApiService";

// Action types
const ACTIVATE_USER_REQUEST = "ACTIVATE_USER_REQUEST";
const ACTIVATE_USER_SUCCESS = "ACTIVATE_USER_SUCCESS";
const ACTIVATE_USER_FAIL = "ACTIVATE_USER_FAIL";
const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL";
const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

// Reducer
export const updatePasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PASSWORD_REQUEST:
      return { loading: true };
    case UPDATE_PASSWORD_SUCCESS:
      return { loading: false, error: false };
    case UPDATE_PASSWORD_FAIL:
      return { loading: false, error: true };
    case RESET_PASSWORD_REQUEST:
      return { loading: true };
    case RESET_PASSWORD_SUCCESS:
      return { loading: false, error: false, status: action.payload };
    case RESET_PASSWORD_FAIL:
      return { loading: false, error: true };
    default:
      return state;
  }
};
// Action Creators
export const activateUser = (token, password) => async (dispatch) => {
  try {
    dispatch({ type: ACTIVATE_USER_REQUEST });

    const userCredentials = {
      token,
      password,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await ApiService.put(
      `${process.env.REACT_APP_BASE_API}/auth/verify/user`,
      userCredentials,
      config
    );

    dispatch({
      type: ACTIVATE_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ACTIVATE_USER_FAIL,
      payload: error && error.message,
    });
  }
};

export const updatePassword = (token, password) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PASSWORD_REQUEST });

    const userCredentials = {
      token,
      password,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await ApiService.put(
      `${process.env.REACT_APP_BASE_API}/auth/password`,
      userCredentials,
      config
    );

    dispatch({
      type: UPDATE_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PASSWORD_FAIL,
      payload: error && error.message,
    });
  }
};

export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({ type: RESET_PASSWORD_REQUEST });

    const userCredentials = {
      email,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await ApiService.put(
      `${process.env.REACT_APP_BASE_API}/auth/pass`,
      userCredentials,
      config
    );

    dispatch({
      type: RESET_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload: error && error.message,
    });
  }
};
