const SET_THEME = "SET_THEME";

// Reducer
export const themeManagerReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_THEME:
      return {
        ...state,
        themeConfig: action.config,
      };
    default:
      return state;
  }
};

// Action Creators
export const setTheme = (message) => {
  localStorage.setItem("themeConfig", message.themeName);
  const config = {
    themeName: message.themeName ? message.themeName : "Dark",
  };

  return (dispatch) => {
    dispatch({ type: SET_THEME, config });
  };
};
