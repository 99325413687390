import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import Typography from "@material-ui/core/Typography";
import Layout from "../../components/common/hoc/Layout";
import ContentInfo from "./components/contentInfo";
import { Hidden, LinearProgress } from "@material-ui/core";
import { contentPublish } from "../../store/content";
import Customization from "../registerContent/components/customization";
import ReviewContent from "../registerContent/components/reviewContent";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    padding: theme.spacing(3),
    [theme.breakpoints.down(600 + theme.spacing(2) * 2)]: {
      width: "auto",
      marginLeft: "auto",
      marginRight: "auto",
    },
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  stepper: {
    padding: theme.spacing(2, 0, 2),
  },
}));

const RegisterContent = () => {
  const classes = useStyles();
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const publish = useSelector((state) => state.content);
  const { status, loading } = publish;
  const steps = [
    t("registerContent.contentStepLabel"),
    t("registerContent.customizationStepLabel"),
    t("registerContent.reviewStepLabel"),
  ];

  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {});

  const [form, setForm] = useState({});

  const handleNext = (data) => {
    setForm({ ...form, ...data });

    if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1);
    } else {
      dispatch(contentPublish(form));
      setActiveStep(activeStep + 1); // TODO capture CART_PUBLISH_SUCCESS
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const getDialogData = async (data) => {
    if (data.hasOwnProperty("data")) {
      handleNext(data.data);
    } else {
      handleBack();
    }
  };

  const getStepContent = (step, getDialogData, dataToPublish = {}) => {
    switch (step) {
      case 0:
        return (
          <ContentInfo
            formConfigI18n={formConfigI18n}
            onClose={getDialogData}
          />
        );
      case 1:
        return (
          <Customization
            formConfigI18n={formConfigI18n}
            onClose={getDialogData}
          />
        );
      case 2:
        return (
          <ReviewContent
            formConfigI18n={formConfigI18n}
            dataToPublish={dataToPublish}
            onClose={getDialogData}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  };

  const formConfigI18n = {
    singleDeleteButton: t("client.singleDeleteButton"),
    singleDeleteMultipleButton: t("client.singleDeleteMultipleButton"),
    contentInfo: {
      title: t("registerContent.contentInfo.title"),
      subtitle: t("registerContent.contentInfo.subtitle"),
      nextButton: t("registerContent.nextButton"),
      contentName: {
        requiredErrorMsg: t("client.addForm.name.requiredErrorMsg"),
        label: t("registerContent.contentInfo.contentName.label"),
        placeholder: t("registerContent.contentInfo.contentName.placeholder"),
      },
      contentNameEn: {
        requiredErrorMsg: t(
          "registerContent.contentInfo.contentNameEn.requiredErrorMsg"
        ),
        label: t("registerContent.contentInfo.contentNameEn.label"),
        placeholder: t("registerContent.contentInfo.contentNameEn.placeholder"),
      },
      contentNameFr: {
        requiredErrorMsg: t(
          "registerContent.contentInfo.contentNameFr.requiredErrorMsg"
        ),
        label: t("registerContent.contentInfo.contentNameFr.label"),
        placeholder: t("registerContent.contentInfo.contentNameFr.placeholder"),
      },
      contentDescription: {
        requiredErrorMsg: t(
          "registerContent.contentInfo.contentDescription.requiredErrorMsg"
        ),
        label: t("registerContent.contentInfo.contentDescription.label"),
        placeholder: t(
          "registerContent.contentInfo.contentDescription.placeholder"
        ),
      },
      contentDescriptionEn: {
        requiredErrorMsg: t(
          "registerContent.contentInfo.contentDescriptionEn.requiredErrorMsg"
        ),
        label: t("registerContent.contentInfo.contentDescriptionEn.label"),
        placeholder: t(
          "registerContent.contentInfo.contentDescriptionEn.placeholder"
        ),
      },
      contentDescriptionFr: {
        requiredErrorMsg: t(
          "registerContent.contentInfo.contentDescriptionFr.requiredErrorMsg"
        ),
        label: t("registerContent.contentInfo.contentDescriptionFr.label"),
        placeholder: t(
          "registerContent.contentInfo.contentDescriptionFr.placeholder"
        ),
      },
      discipline: {
        requiredErrorMsg: t(
          "registerContent.contentInfo.discipline.requiredErrorMsg"
        ),
        label: t("registerContent.contentInfo.discipline.label"),
      },
      schoolSubjects: {
        requiredErrorMsg: t(
          "registerContent.contentInfo.schoolSubjects.requiredErrorMsg"
        ),
        label: t("registerContent.contentInfo.schoolSubjects.label"),
        placeholder: t(
          "registerContent.contentInfo.schoolSubjects.placeholder"
        ),
      },
      topic: {
        requiredErrorMsg: t(
          "registerContent.contentInfo.topic.requiredErrorMsg"
        ),
        label: t("registerContent.contentInfo.topic.label"),
        placeholder: t("registerContent.contentInfo.topic.placeholder"),
      },
      bncc: {
        requiredErrorMsg: t(
          "registerContent.contentInfo.bncc.requiredErrorMsg"
        ),
        label: t("registerContent.contentInfo.bncc.label"),
        placeholder: t("registerContent.contentInfo.bncc.placeholder"),
      },
      client: {
        requiredErrorMsg: t(
          "registerContent.contentInfo.client.requiredErrorMsg"
        ),
        label: t("registerContent.contentInfo.client.label"),
        placeholder: t("registerContent.contentInfo.client.placeholder"),
      },
      schoolLevel: {
        requiredErrorMsg: t(
          "registerContent.contentInfo.schoolLevel.requiredErrorMsg"
        ),
        label: t("registerContent.contentInfo.schoolLevel.label"),
      },
      schoolGrade: {
        requiredErrorMsg: t(
          "registerContent.contentInfo.schoolGrade.requiredErrorMsg"
        ),
        label: t("registerContent.contentInfo.schoolGrade.label"),
      },
      companyName: {
        requiredErrorMsg: t(
          "registerContent.contentInfo.companyName.requiredErrorMsg"
        ),
        label: t("registerContent.contentInfo.companyName.label"),
      },
      author: {
        requiredErrorMsg: t(
          "registerContent.contentInfo.author.requiredErrorMsg"
        ),
        label: t("registerContent.contentInfo.author.label"),
        placeholder: t("registerContent.contentInfo.author.placeholder"),
      },
      effectiveDate: {
        requiredErrorMsg: t(
          "registerContent.contentInfo.effectiveDate.requiredErrorMsg"
        ),
        label: t("registerContent.contentInfo.effectiveDate.label"),
      },
    },
  };
  return (
    <Layout title="Cadastro de Conteúdo">
      <main className={classes.layout}>
        <>
          <Hidden xsDown>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Hidden>

          <>
            {activeStep === steps.length ? (
              <>
                {loading ? (
                  <>
                    <Typography align="center" variant="h5" gutterBottom>
                      {t("registerContent.publishSendTitle")}
                    </Typography>

                    <LinearProgress />
                  </>
                ) : (
                  <>
                    {status.success === true ? (
                      <>
                        <Typography align="center" variant="h5" gutterBottom>
                          {t("registerContent.publishFinishTitle")}
                        </Typography>
                        <Typography align="center" variant="subtitle1">
                          {t("registerContent.publishFinishSubTitle")}
                        </Typography>
                        <img
                          src="/images/avatar.png"
                          className={classes.title}
                          style={{ display: "block", margin: "1rem auto" }}
                          alt="avatar"
                        />
                      </>
                    ) : (
                      <>
                        <Typography align="center" variant="h5" gutterBottom>
                          {t("registerContent.publishErrorTitle")}
                        </Typography>
                        <Typography align="center" variant="subtitle1">
                          {t("registerContent.publishErrorSubTitle")}
                        </Typography>
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <>{getStepContent(activeStep, getDialogData, form)}</>
            )}
          </>
        </>
      </main>
    </Layout>
  );
};

export { RegisterContent };
