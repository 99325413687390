import axios from "axios";

// Action types
const ADMIN_LIST_REQUEST = "ADMIN_LIST_REQUEST";
const ADMIN_LIST_SUCCESS = "ADMIN_LIST_SUCCESS";
const ADMIN_LIST_FAIL = "ADMIN_LIST_FAIL";

const ADMIN_ADD_REQUEST = "ADMIN_ADD_REQUEST";
const ADMIN_ADD_SUCCESS = "ADMIN_ADD_SUCCESS";
const ADMIN_ADD_FAIL = "ADMIN_ADD_FAIL";

const ADMIN_UPDATE_REQUEST = "ADMIN_UPDATE_REQUEST";
const ADMIN_UPDATE_SUCCESS = "ADMIN_UPDATE_SUCCESS";
const ADMIN_UPDATE_FAIL = "ADMIN_UPDATE_FAIL";

const ADMIN_DELETE_REQUEST = "ADMIN_DELETE_REQUEST";
const ADMIN_DELETE_SUCCESS = "ADMIN_DELETE_SUCCESS";
const ADMIN_DELETE_FAIL = "ADMIN_DELETE_FAIL";

// Reducer
export const adminMasterReducer = (state = { admins: [] }, action) => {
  switch (action.type) {
    case ADMIN_LIST_REQUEST:
      return { loading: true, admin: [] };
    case ADMIN_LIST_SUCCESS:
      return { loading: false, admin: action.payload };
    case ADMIN_LIST_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_ADD_REQUEST:
      return { loading: true, admin: [] };
    case ADMIN_ADD_SUCCESS:
      return { loading: false, admin: action.payload };
    case ADMIN_ADD_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_UPDATE_REQUEST:
      return { loading: true, admin: [] };
    case ADMIN_UPDATE_SUCCESS:
      return { loading: false, admin: action.payload };
    case ADMIN_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_DELETE_REQUEST:
      return { loading: true, admin: [] };
    case ADMIN_DELETE_SUCCESS:
      return { loading: false, admin: action.payload };
    case ADMIN_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Action Creators
export const listAdmin = () => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_LIST_REQUEST });
    //const {data} = await axios.get(`${apiUrl}/admin?page=${page}&limit=${limit}&filter=${keyword}`);
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_API}/users/admins`
      //'https://600605a13698a80017de12be.mockapi.io/api/v1/AdministradorMaster'
    );
    dispatch({
      type: ADMIN_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_LIST_FAIL,
      payload: error,
    });
  }
};

export const addAdmin = (name, email) => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_ADD_REQUEST });

    const user = {
      name: name,
      email: email,
      password: "123",
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_API}/users/admins`,
      //'https://600605a13698a80017de12be.mockapi.io/api/v1/AdministradorMaster',
      user
    );

    dispatch({
      type: ADMIN_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_ADD_FAIL,
      payload: error,
    });
  }
};

export const updateAdmin = (id, name, email) => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_UPDATE_REQUEST });

    const user = {
      name: name,
      email: email,
    };

    //TODO After created the api change this
    const { data } = await axios.put(
      `${process.env.REACT_APP_BASE_API}/users/admin/${id}`,
      //`https://600605a13698a80017de12be.mockapi.io/api/v1/AdministradorMaster/${id}`,
      user
    );
    dispatch({
      type: ADMIN_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_UPDATE_FAIL,
      payload: error,
    });
  }
};

export const deleteAdmin = (admin) => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_DELETE_REQUEST });

    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_API}/users/admin/${admin.id}`
      //`https://600605a13698a80017de12be.mockapi.io/api/v1/AdministradorMaster/${admin.id}`
    );
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
