import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Typography from "@material-ui/core/Typography";
import { useParams, useHistory } from "react-router-dom";
import Layout from "../../components/common/hoc/Layout";
import ContentInfo from "./components/contentInfo";
import { Hidden, LinearProgress } from "@material-ui/core";
import Customization from "./components/customization";
import ReviewContent from "./components/reviewContent";
import CustomSpinner from "../../components/common/Spinner/CustomSpinner";
import NotFound from "../../components/common/notFound/NotFound";
import { contentUpdate } from "../../store/content";
import { listInteractionUpdateDetails } from "../../store/interactions";
import { showCustomConfirmationDialog } from "../../store/customConfirmationDialogReducer";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down(600 + theme.spacing(2) * 2)]: {
      width: "auto",
      marginLeft: "auto",
      marginRight: "auto",
    },
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: 0,
    marginBottom: 0,
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(2, 0, 2),
  },
}));

const UpdateContent = () => {
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();

  const interactionDetails = useSelector(
    (state) => state.interactionDetailsUpdate
  );
  const contentUpdateState = useSelector((state) => state.contentUpdate);

  const steps = [
    t("registerContent.contentStepLabel"),
    t("registerContent.customizationStepLabel"),
    t("registerContent.reviewStepLabel"),
  ];

  const [activeStep, setActiveStep] = useState(0);

  const [form, setForm] = useState({});

  const handleNext = async (data) => {
    setForm({ ...form, ...data });

    if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1);
    } else {
      setActiveStep(activeStep + 1);
      await dispatch(contentUpdate(form, interactionDetails.interaction));
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const getDialogData = async (data) => {
    if (data.hasOwnProperty("data")) {
      handleNext(data.data);
    } else {
      handleBack();
    }
  };

  const getStepContent = (
    step,
    getDialogData,
    dataToPublish = {},
    interaction,
    loading
  ) => {
    switch (step) {
      case 0:
        return (
          <ContentInfo
            interactionData={interaction}
            formConfigI18n={formConfigI18n}
            onClose={getDialogData}
            loading={loading}
          />
        );
      case 1:
        return (
          <Customization
            interactionData={interaction}
            formConfigI18n={formConfigI18n}
            onClose={getDialogData}
            loading={loading}
          />
        );
      case 2:
        return (
          <ReviewContent
            formConfigI18n={formConfigI18n}
            dataToPublish={dataToPublish}
            onClose={getDialogData}
            loading={loading}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  };

  const formConfigI18n = {
    singleDeleteButton: t("client.singleDeleteButton"),
    singleDeleteMultipleButton: t("client.singleDeleteMultipleButton"),
    contentInfo: {
      title: t("updateContent.contentInfo.title"),
      subtitle: t("updateContent.contentInfo.subtitle"),
      nextButton: t("updateContent.nextButton"),
      contentName: {
        requiredErrorMsg: t(
          "updateContent.contentInfo.contentName.requiredErrorMsg"
        ),
        label: t("updateContent.contentInfo.contentName.label"),
        placeholder: t("updateContent.contentInfo.contentName.placeholder"),
      },
      contentNameEn: {
        requiredErrorMsg: t(
          "updateContent.contentInfo.contentNameEn.requiredErrorMsg"
        ),
        label: t("updateContent.contentInfo.contentNameEn.label"),
        placeholder: t("updateContent.contentInfo.contentNameEn.placeholder"),
      },
      contentNameFr: {
        requiredErrorMsg: t(
          "updateContent.contentInfo.contentNameFr.requiredErrorMsg"
        ),
        label: t("updateContent.contentInfo.contentNameFr.label"),
        placeholder: t("updateContent.contentInfo.contentNameFr.placeholder"),
      },
      contentDescription: {
        requiredErrorMsg: t(
          "updateContent.contentInfo.contentDescription.requiredErrorMsg"
        ),
        label: t("updateContent.contentInfo.contentDescription.label"),
        placeholder: t(
          "updateContent.contentInfo.contentDescription.placeholder"
        ),
      },
      contentDescriptionEn: {
        requiredErrorMsg: t(
          "updateContent.contentInfo.contentDescriptionEn.requiredErrorMsg"
        ),
        label: t("updateContent.contentInfo.contentDescriptionEn.label"),
        placeholder: t(
          "updateContent.contentInfo.contentDescriptionEn.placeholder"
        ),
      },
      contentDescriptionFr: {
        requiredErrorMsg: t(
          "updateContent.contentInfo.contentDescriptionFr.requiredErrorMsg"
        ),
        label: t("updateContent.contentInfo.contentDescriptionFr.label"),
        placeholder: t(
          "updateContent.contentInfo.contentDescriptionFr.placeholder"
        ),
      },
      discipline: {
        requiredErrorMsg: t(
          "updateContent.contentInfo.discipline.requiredErrorMsg"
        ),
        label: t("updateContent.contentInfo.discipline.label"),
      },
      schoolSubjects: {
        requiredErrorMsg: t(
          "updateContent.contentInfo.schoolSubjects.requiredErrorMsg"
        ),
        label: t("updateContent.contentInfo.schoolSubjects.label"),
        placeholder: t("updateContent.contentInfo.schoolSubjects.placeholder"),
      },
      topic: {
        requiredErrorMsg: t("updateContent.contentInfo.topic.requiredErrorMsg"),
        label: t("updateContent.contentInfo.topic.label"),
        placeholder: t("updateContent.contentInfo.topic.placeholder"),
      },
      bncc: {
        requiredErrorMsg: t("updateContent.contentInfo.bncc.requiredErrorMsg"),
        label: t("updateContent.contentInfo.bncc.label"),
        placeholder: t("updateContent.contentInfo.bncc.placeholder"),
      },
      client: {
        requiredErrorMsg: t(
          "updateContent.contentInfo.client.requiredErrorMsg"
        ),
        label: t("updateContent.contentInfo.client.label"),
        placeholder: t("updateContent.contentInfo.client.placeholder"),
      },
      schoolLevel: {
        requiredErrorMsg: t(
          "updateContent.contentInfo.schoolLevel.requiredErrorMsg"
        ),
        label: t("updateContent.contentInfo.schoolLevel.label"),
      },
      schoolGrade: {
        requiredErrorMsg: t(
          "updateContent.contentInfo.schoolGrade.requiredErrorMsg"
        ),
        label: t("updateContent.contentInfo.schoolGrade.label"),
      },
      companyName: {
        requiredErrorMsg: t(
          "updateContent.contentInfo.companyName.requiredErrorMsg"
        ),
        label: t("updateContent.contentInfo.companyName.label"),
      },
      author: {
        requiredErrorMsg: t(
          "updateContent.contentInfo.author.requiredErrorMsg"
        ),
        label: t("updateContent.contentInfo.author.label"),
        placeholder: t("updateContent.contentInfo.author.placeholder"),
      },
      effectiveDate: {
        requiredErrorMsg: t(
          "updateContent.contentInfo.effectiveDate.requiredErrorMsg"
        ),
        label: t("updateContent.contentInfo.effectiveDate.label"),
      },
    },
  };

  useEffect(() => {
    dispatch(listInteractionUpdateDetails(id));
  }, [dispatch, id]);

  return (
    <Layout title={t("updateContent.title")}>
      {interactionDetails.loading && !interactionDetails.isPublished ? (
        <CustomSpinner />
      ) : (
        interactionDetails.error && (
          <NotFound
            title="Oops!"
            message="Ocorreu um erro, tente recarregar a página"
            image="/images/notfound.png"
            redirect="/app/content"
          />
        )
      )}
      {!interactionDetails.loading && !interactionDetails.error && (
        <>
          {!contentUpdateState.isPublished && (
            <Grid item xs={12} sm={12} lg={6} className={classes.button}>
              <IconButton
                aria-label="back"
                onClick={() => history.push("/app/my-content/")}
              >
                <ArrowBackIosIcon color="primary" fontSize="default" />
              </IconButton>
            </Grid>
          )}

          <main className={classes.layout}>
            <>
              {activeStep === steps.length ? (
                <>
                  {!contentUpdateState.isPublished && (
                    <>
                      <Typography align="center" variant="h5" gutterBottom>
                        {t("updateContent.publishSendTitle")}
                      </Typography>

                      <LinearProgress />
                    </>
                  )}

                  {contentUpdateState.isPublished && (
                    <NotFound
                      title={t("updateContent.publishFinishTitle")}
                      message={t("updateContent.publishFinishSubTitle")}
                      buttonText="Atualizar outro conteúdo"
                      redirect="/app/my-content/"
                      image="/images/avatar.png"
                    />
                  )}

                  {contentUpdateState.error && (
                    <NotFound
                      title={t("updateContent.publishErrorTitle")}
                      message={t("updateContent.publishErrorSubTitle")}
                      image="/images/avatar.png"
                    />
                  )}
                </>
              ) : (
                <>
                  <Hidden xsDown>
                    <Stepper
                      activeStep={activeStep}
                      className={classes.stepper}
                    >
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Hidden>
                  {getStepContent(
                    activeStep,
                    getDialogData,
                    form,
                    interactionDetails.interaction
                  )}
                </>
              )}
            </>
          </main>
        </>
      )}
    </Layout>
  );
};

export { UpdateContent };
