import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as ArrowRight } from "../icons/svg/arrowRight.svg";
import { ReactComponent as ArrowBack } from "../icons/svg/arrowBack.svg";
import { ReactComponent as Home } from "../icons/svg/home.svg";
import { ReactComponent as Library } from "../icons/svg/biblioteca.svg";
import { ReactComponent as MyActivities } from "../icons/svg/minhas_atividades.svg";
import { ReactComponent as Report } from "../icons/svg/relatorio.svg";
import { ReactComponent as OpenReader } from "../icons/svg/adicionar.svg";
import { ReactComponent as CircleQuestion } from "../icons/svg/adicionar.svg";
import { ReactComponent as Play } from "../icons/svg/play_icon.svg";
import { ReactComponent as BookOpen } from "../icons/svg/adicionar.svg";
import { ReactComponent as Settings } from "../icons/svg/configuracao.svg";
import { ReactComponent as Help } from "../icons/svg/ajuda.svg";
import { ReactComponent as User } from "../icons/svg/user.svg";
import { ReactComponent as Filter } from "../icons/svg/filtrar.svg";

export const OpenReaderIcon = (props) => (
  <SvgIcon component={OpenReader} {...props} />
);
export const LibraryIcon = (props) => (
  <SvgIcon component={Library} {...props} />
);
export const MyActivitiesIcon = (props) => (
  <SvgIcon component={MyActivities} {...props} />
);
export const ArrowRightIcon = (props) => (
  <SvgIcon component={ArrowRight} {...props} />
);
export const ArrowBackIcon = (props) => (
  <SvgIcon component={ArrowBack} {...props} />
);

export const HomeIcon = (props) => <SvgIcon component={Home} {...props} />;
export const ReportIcon = (props) => <SvgIcon component={Report} {...props} />;
export const CircleQuestionIcon = (props) => (
  <SvgIcon component={CircleQuestion} {...props} />
);
export const PlayIcon = (props) => <SvgIcon component={Play} {...props} />;
export const BookOpenIcon = (props) => (
  <SvgIcon component={BookOpen} {...props} />
);

export const SettingsIcon = (props) => (
  <SvgIcon component={Settings} {...props} />
);

export const HelpIcon = (props) => <SvgIcon component={Help} {...props} />;
export const UserIcon = (props) => <SvgIcon component={User} {...props} />;
export const FilterIcon = (props) => <SvgIcon component={Filter} {...props} />;
