import Container from "@material-ui/core/Container";
import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { adminSchema } from "./schema/admin";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  buttonAdd: {
    marginLeft: theme.spacing(1),
  },
}));

const Edit = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("translation");
  const {
    isEnabledFormEditAdmin,
    editHandler,
    loading,
    show,
    currentAdmin,
    name,
    email,
    confirmButton,
    cancelButton,
  } = props;
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(adminSchema()),
  });

  const [inputState, setInputState] = useState({
    name: currentAdmin?.name,
    email: currentAdmin?.email,
  });

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevVisibility = usePrevious(currentAdmin);

  useEffect(() => {
    if (prevVisibility !== currentAdmin) {
      const newState = {
        ...inputState,
        name: currentAdmin.name,
        email: currentAdmin.email,
      };
      setInputState(newState);
    }
  }, [currentAdmin, inputState, prevVisibility]);

  const onsubmit = (data) => {
    reset();
    editHandler(data);
  };

  const handleCancel = () => {
    reset();
    show();
  };

  const handleChangeInput = (event) => {
    setInputState({
      ...inputState,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Box component="span" display={isEnabledFormEditAdmin ? "none" : "block"}>
      <Paper elevation={1}>
        <Container maxWidth={false} className={classes.container}>
          <Typography
            className={classes.title}
            variant="h5"
            component="h1"
            gutterBottom
          >
            {t("registerAdminMaster.editForm.title")}
          </Typography>

          <form fullWidth onSubmit={handleSubmit(onsubmit)} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} lg={4}>
                <TextField
                  inputRef={register}
                  error={errors.name && true}
                  helperText={errors.name && name.requiredErrorMsg}
                  id="name"
                  name="name"
                  label={name.label}
                  value={inputState.name}
                  onChange={handleChangeInput}
                  placeholder={name.placeholder}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={12} lg={4}>
                <TextField
                  inputRef={register}
                  error={errors.email && true}
                  helperText={errors.email && email.requiredErrorMsg}
                  id="email"
                  name="email"
                  label={email.label}
                  value={inputState.email}
                  onChange={handleChangeInput}
                  placeholder={name.placeholder}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={12} lg={12}>
                <div className={classes.buttons}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    onClick={() => handleCancel()}
                  >
                    {cancelButton}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.buttonAdd}
                    disabled={loading}
                  >
                    {confirmButton}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Paper>
    </Box>
  );
};

Edit.propTypes = {
  isEnabledFormEditAdmin: PropTypes.object.isRequired,
  editHandler: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  currentAdmin: PropTypes.object.isRequired,
};

export default Edit;
