import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import Avatar from "@material-ui/core/Avatar";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(2),
  },

  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function ReviewContent(props) {
  const { onClose, dataToPublish } = props;
  const classes = useStyles();
  const { t } = useTranslation("translation");

  const handleBack = () => {
    onsubmit({});
  };

  const onsubmit = (dialogData) => {
    if (Object.keys(dialogData).length === 0) {
      onClose({});
    } else {
      const data = {
        data: {},
      };
      onClose(data);
    }
  };

  const loadImage = (FileList) => {
    if (FileList !== undefined) {
      return URL.createObjectURL(FileList);
    } else {
      return "";
    }
  };

  return (
    <>
      <Typography align="center" variant="h6" component="h2" gutterBottom>
        {t("registerContent.review.title")}
      </Typography>
      <p align="center">{t("registerContent.review.subtitle")}</p>

      <List className={classes.root}>
        {dataToPublish &&
          Object.keys(dataToPublish).map((fieldName) => {
            return fieldName === "marker" &&
              dataToPublish[fieldName].length > 0 ? (
              <>
                <ListItem
                  key={Math.floor(Math.random() * 50)}
                  alignItems="flex-start"
                >
                  <ListItemAvatar>
                    <Avatar
                      alt="marker"
                      src={loadImage(dataToPublish[fieldName][0])}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary="Marcador"
                    secondary={
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {dataToPublish[fieldName][0]?.name}
                      </Typography>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </>
            ) : fieldName === "thumbnail" &&
              dataToPublish[fieldName].length > 0 ? (
              <>
                <ListItem
                  key={Math.floor(Math.random() * 50)}
                  alignItems="flex-start"
                >
                  <ListItemAvatar>
                    <Avatar
                      alt="thumbnail"
                      src={loadImage(dataToPublish[fieldName][0])}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary="Thumbnail"
                    secondary={
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {dataToPublish[fieldName][0]?.name}
                      </Typography>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </>
            ) : fieldName === "gif" && dataToPublish[fieldName].length > 0 ? (
              <>
                <ListItem alignItems="flex-start">
                  <ListItemIcon style={{ color: "white" }}>
                    <AttachFileIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="gif"
                    secondary={
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {dataToPublish[fieldName][0]?.name}
                      </Typography>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </>
            ) : fieldName === "video" && dataToPublish[fieldName].length > 0 ? (
              <>
                <ListItem alignItems="flex-start">
                  <ListItemIcon style={{ color: "white" }}>
                    <AttachFileIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Video"
                    secondary={
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {dataToPublish[fieldName][0]?.name}
                      </Typography>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </>
            ) : fieldName === "bundleAppStore" &&
              dataToPublish[fieldName].length > 0 ? (
              <>
                <ListItem alignItems="flex-start">
                  <ListItemIcon style={{ color: "white" }}>
                    <AttachFileIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Bundle App Store"
                    secondary={
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {dataToPublish[fieldName][0]?.name}
                      </Typography>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </>
            ) : fieldName === "bundlePlayStore" &&
              dataToPublish[fieldName].length > 0 ? (
              <>
                <ListItem alignItems="flex-start">
                  <ListItemIcon style={{ color: "white" }}>
                    <AttachFileIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Bundle Play Store"
                    secondary={
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {dataToPublish[fieldName][0].name}
                      </Typography>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </>
            ) : (
              <>
                <ListItem alignItems="flex-start">
                  <ListItemIcon style={{ color: "white" }}>
                    <TextFieldsIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={fieldName}
                    secondary={
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {/* {!filesTypes.includes(fieldName) ? 'Não alterado' : dataToPublish[fieldName]} */}
                        {dataToPublish[fieldName]}
                        {fieldName === "thumbnail" && "Não alterado"}
                        {fieldName === "gif" && "Não alterado"}
                        {fieldName === "marker" && "Não alterado"}
                        {fieldName === "video" && "Não alterado"}
                        {fieldName === "bundleAppStore" && "Não alterado"}
                        {fieldName === "bundlePlayStore" && "Não alterado"}
                      </Typography>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </>
            );
          })}
      </List>

      <div className={classes.buttons}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => handleBack()}
        >
          {t("registerContent.backButton")}
        </Button>

        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => onsubmit(dataToPublish)}
        >
          {t("registerContent.publishButton")}
        </Button>
      </div>
    </>
  );
}
