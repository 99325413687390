import { Controller } from "react-hook-form";
import React from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

const ReactHookFormRadio = ({
  name,
  label,
  control,
  defaultValue,
  inputRef,
  error,
  requiredErrorMsg,
  helperText,
  children,
  row,
  ...props
}) => {
  return (
    <FormControl {...props} component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <Controller
        as={
          <RadioGroup
            inputRef={inputRef}
            aria-label={label}
            name={name}
            error={error}
            row={row}
          >
            {children}
          </RadioGroup>
        }
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
export default ReactHookFormRadio;
