import React, { Fragment } from "react";
import { Redirect, Route, useRouteMatch } from "react-router-dom";
import MainNavigation from "../components/mainNavigation";
import PrivateRoutesConfig from "../config/PrivaterRoutesConfig";
import { getAllowedRoutes, isLoggedIn } from "../utils";

function PrivateRoutes() {
  const match = useRouteMatch("/app");
  let allowedRoutes = [];

  if (isLoggedIn()) {
    allowedRoutes = getAllowedRoutes(PrivateRoutesConfig);

    if (match === null) {
      return <Route path="/" render={() => <Redirect to="/app" />} />;
    }

    return (
      <Fragment>
        <MainNavigation routes={allowedRoutes} prefix={match.path} />
      </Fragment>
    );
  } else {
    localStorage.clear();
    return <Redirect to="/" />;
  }
}

export default PrivateRoutes;
