import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { showCustomConfirmationDialog } from "../../store/customConfirmationDialogReducer";
import { showSnackbar } from "../../store/duck/uiReducer/uiReducer";
import Add from "./components/add";
import AddBatch from "./components/addBatch";
import Edit from "./components/edit";
import {
  addStudent,
  updateStudent,
  deleteStudent,
  listStudent,
  addBatchStudent,
  deleteMultipleStudent,
} from "../../store/duck/student/student";
import { listSchoolClassConfigStudent } from "../../store/duck/student/schoolClassConfig";
import EnhancedTableHead from "../../components/common/table/EnhancedTableHead";
import RecordChangeButtons from "../../components/common/recordChangeButtons/RecordChangeButtons";
import CustomSpinner from "../../components/common/Spinner/CustomSpinner";
import { getComparator, stableSort } from "../../utils/tableFilter";
import Layout from "../../components/common/hoc/Layout";
import NotFound from "../../components/common/notFound/NotFound";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    marginTop: "1rem",
    minWidth: 750,
  },
  rowEnable: {
    outlineColor: theme.palette.secondary.main,
    outlineWidth: "2px",
    outlineStyle: "solid",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  buttonAdd: {
    marginRight: theme.spacing(1),
  },
}));

const RegisterStudent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation("translation");
  const studentReducer = useSelector((state) => state.studentReducer);
  const { loading, error, student } = studentReducer;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [rowEnable, setRowEnable] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [registrationCode, setRegistrationCode] = useState("");
  const [isEnabledFormAddBatch, setIsEnabledFormAddBatch] = useState(false);
  const [isEnabledFormAdd, setIsEnabledFormAdd] = useState(false);
  const [isEnabledFormEdit, setIsEnabledFormEdit] = useState(false);
  const [currentStudent, setCurrentStudent] = useState();

  const headCells = [
    {
      id: "nameStudent",
      numeric: false,
      disablePadding: true,
      label: t("student.headCells.name"),
    },
    {
      id: "enrollmentStudent",
      numeric: false,
      disablePadding: false,
      label: t("student.headCells.registrationCode"),
    },
    {
      id: "uniqueCode",
      numeric: false,
      disablePadding: false,
      label: t("student.headCells.accessCode"),
    },
    {
      id: "turma",
      numeric: false,
      disablePadding: false,
      label: t("student.headCells.schoolClass"),
    },
    {
      id: "isActive",
      numeric: false,
      disablePadding: false,
      label: t("student.headCells.isActive"),
    },
  ];
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, student.length - page * rowsPerPage);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = student.map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const toggleFormAdmin = () => {
    setIsEnabledFormAdd((prev) => !prev);
    setIsEnabledFormEdit(false);
  };

  const toggleFormAdminBatch = () => {
    setIsEnabledFormAddBatch((prev) => !prev);
    setIsEnabledFormEdit(false);
  };

  const toggleFormEdit = (isEnable) => {
    setIsEnabledFormAdd(false);
    setIsEnabledFormEdit(isEnable);
  };

  const focusOnlastItemAdded = () => {
    setRowEnable(true);
    setTimeout(function () {
      setRowEnable(false);
    }, 3000);
  };

  const addHandler = async (formValues) => {
    setRegistrationCode(formValues.registrationCode);
    await dispatch(
      addStudent(
        formValues.name,
        formValues.registrationCode,
        formValues.schoolClass
      )
    );
    await dispatch(listStudent());
    setSelected([]);
    focusOnlastItemAdded();
  };

  const addBatchHandler = async (formValues) => {
    await dispatch(
      addBatchStudent(formValues.spreadsheet, formValues.schoolClass)
    );
    await dispatch(listStudent());
    setSelected([]);
    focusOnlastItemAdded();
  };

  const deleteHandler = (student) => {
    const configDialog = {
      title: t("student.deleteDialog.title"),
      message: `${t("student.deleteDialog.firstMessage")} "${
        student.nameStudent
      }", ${t("student.deleteDialog.secondMessage")}`,
      onSubmit: async function () {
        await dispatch(deleteStudent(student))
          .then(async (result) => {
            await dispatch({ type: "CUSTOM_CONFIRMATION_DIALOG_CLOSED" });
            await dispatch(listStudent());
            setSelected([]);
          })
          .catch((err) => {
            dispatch({
              type: "STUDENT_DELETE_FAIL",
              payload: error,
            });
          });
      },

      close: async function () {
        await dispatch({ type: "CUSTOM_CONFIRMATION_DIALOG_CLOSED" });
      },
      buttons: {
        cancel: t("student.deleteDialog.buttons.cancel"),
        confirm: t("student.deleteDialog.buttons.confirm"),
      },
    };

    dispatch(
      showCustomConfirmationDialog({
        ...configDialog,
      })
    );
  };

  const editHandler = (student) => {
    toggleFormEdit(false);
    const schoolClassTemp = `${student.periodo}-${student.serie}-${student.turma}`;
    setCurrentStudent({ ...student, schoolClass: schoolClassTemp });
    const configDialog = {
      title: t("student.editDialog.title"),
      message: `${t("student.editDialog.firstMessage")} "${
        student.nameStudent
      }", ${t("student.editDialog.secondMessage")}`,
      onSubmit: function () {
        toggleFormEdit(true);
      },
      close: async function () {
        await dispatch({ type: "CUSTOM_CONFIRMATION_DIALOG_CLOSED" });
      },
      buttons: {
        cancel: t("student.editDialog.buttons.cancel"),
        confirm: t("student.editDialog.buttons.confirm"),
      },
    };
    dispatch(
      showCustomConfirmationDialog({
        ...configDialog,
      })
    );
  };

  const updateHandler = async (formValues) => {
    setRegistrationCode(formValues.registrationCode);
    await dispatch(
      updateStudent(
        currentStudent.id,
        currentStudent.idRoom,
        formValues.name,
        formValues.registrationCode,
        formValues.schoolClass,
        formValues.resetUniqueCode
      )
    );
    await dispatch(listStudent());
    setSelected([]);
    focusOnlastItemAdded();
  };

  const deleteMultiple = async (students) => {
    const configDialog = {
      title: t("student.deleteMultipleDialog.title"),
      message: `${t("student.deleteMultipleDialog.firstMessage")} ${
        students?.length
      } ${t("student.deleteMultipleDialog.secondMessage")}`,
      onSubmit: async function () {
        await dispatch(deleteMultipleStudent(students))
          .then(async (result) => {
            await dispatch({ type: "CUSTOM_CONFIRMATION_DIALOG_CLOSED" });
            await dispatch(
              showSnackbar({
                message: "Alunos removidos com sucesso!",
                severity: "success",
                autoHideDuration: 3000,
              })
            );
            await dispatch(listStudent());
            setSelected([]);
          })
          .catch(async (err) => {
            await dispatch(
              showSnackbar({
                message: "Ocorreu um erro ao remover os alunos!",
                severity: "error",
                autoHideDuration: 3000,
              })
            );
          });
      },

      close: async function () {
        await dispatch({ type: "CUSTOM_CONFIRMATION_DIALOG_CLOSED" });
      },
      buttons: {
        cancel: t("student.deleteMultipleDialog.buttons.cancel"),
        confirm: t("student.deleteMultipleDialog.buttons.confirm"),
      },
    };

    dispatch(
      showCustomConfirmationDialog({
        ...configDialog,
      })
    );
  };

  const formConfigI18n = {
    singleDeleteButton: t("student.singleDeleteButton"),
    singleDeleteMultipleButton: t("student.singleDeleteMultipleButton"),
    addForm: {
      title: t("student.addForm.title"),
      cancelButton: t("student.addForm.cancelButton"),
      confirmButton: t("student.addForm.confirmButton"),
      schoolClassButton: t("student.addForm.schoolClass.label"),
      name: {
        requiredErrorMsg: t("student.addForm.name.requiredErrorMsg"),
        label: t("student.addForm.name.label"),
        placeholder: t("student.addForm.name.placeholder"),
      },
      registrationCode: {
        requiredErrorMsg: t(
          "student.addForm.registrationCode.requiredErrorMsg"
        ),
        label: t("student.addForm.registrationCode.label"),
        placeholder: t("student.addForm.registrationCode.placeholder"),
      },

      schoolClassDialog: {
        title: t("student.addForm.schoolClass.title"),
        saveButtonLabel: t("student.addForm.schoolClass.saveButtonLabel"),
      },
    },
    addFormBatch: {
      title: t("student.addForm.title"),
      cancelButton: t("student.addForm.cancelButton"),
      confirmButton: t("student.addForm.confirmButton"),
      schoolClassDialog: {
        title: t("student.addForm.schoolClass.title"),
        saveButtonLabel: t("student.addForm.schoolClass.saveButtonLabel"),
      },
    },
    editForm: {
      title: t("student.editForm.title"),
      cancelButton: t("student.editForm.cancelButton"),
      confirmButton: t("student.editForm.confirmButton"),
      name: {
        requiredErrorMsg: t("student.editForm.name.requiredErrorMsg"),
        label: t("student.editForm.name.label"),
        placeholder: t("student.editForm.name.placeholder"),
      },
      registrationCode: {
        requiredErrorMsg: t(
          "student.editForm.registrationCode.requiredErrorMsg"
        ),
        label: t("student.editForm.registrationCode.label"),
        placeholder: t("student.editForm.registrationCode.placeholder"),
      },
      resetUniqueCode: {
        tooltip: t("student.editForm.resetUniqueCode.tooltip"),
        label: t("student.editForm.resetUniqueCode.label"),
      },
    },
  };

  const formatUniqueCode = (uniqueCode) => {
    const pattern = ".+?(?=-)";
    if (!uniqueCode) return;
    return uniqueCode.match(pattern);
  };

  useEffect(() => {
    dispatch(listSchoolClassConfigStudent());
    dispatch(listStudent());
  }, [dispatch]);

  return (
    <Layout title={t("student.title")}>
      <Container disableGutters maxWidth={false}>
        <Box
          component="span"
          display={
            isEnabledFormAdd || isEnabledFormAddBatch || isEnabledFormEdit
              ? "none"
              : "block"
          }
        >
          <Grid item xs={12} sm={12} lg={12}>
            <div className={classes.buttons}>
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonAdd}
                disabled={loading}
                onClick={() => setIsEnabledFormAdd((prev) => !prev)}
              >
                {t("student.addButton")}
              </Button>

              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={() => setIsEnabledFormAddBatch((prev) => !prev)}
              >
                Subir planilha
              </Button>
            </div>
          </Grid>
        </Box>
        <Add
          title={formConfigI18n.addForm.title}
          cancelButton={formConfigI18n.addForm.cancelButton}
          confirmButton={formConfigI18n.addForm.confirmButton}
          titleSchoolClassDialog={
            formConfigI18n.addForm.schoolClassDialog.title
          }
          saveButtonLabel={
            formConfigI18n.addForm.schoolClassDialog.saveButtonLabel
          }
          schoolClassButton={formConfigI18n.addForm.schoolClassButton}
          name={formConfigI18n.addForm.name}
          registrationCode={formConfigI18n.addForm.registrationCode}
          isEnabledFormAddAdmin={!isEnabledFormAdd}
          loading={loading}
          addHandler={addHandler}
          show={toggleFormAdmin}
        />
        <AddBatch
          title={formConfigI18n.addFormBatch.title}
          cancelButton={formConfigI18n.addFormBatch.cancelButton}
          confirmButton={formConfigI18n.addFormBatch.confirmButton}
          isEnabledFormAddAdmin={!isEnabledFormAddBatch}
          loading={loading}
          addBatchHandler={addBatchHandler}
          show={toggleFormAdminBatch}
          titleSchoolClassDialog={
            formConfigI18n.addForm.schoolClassDialog.title
          }
          saveButtonLabel={
            formConfigI18n.addForm.schoolClassDialog.saveButtonLabel
          }
          schoolClassButton={formConfigI18n.addForm.schoolClassButton}
        />
        <Edit
          title={formConfigI18n.editForm.title}
          cancelButton={formConfigI18n.editForm.cancelButton}
          confirmButton={formConfigI18n.editForm.confirmButton}
          titleSchoolClassDialog={
            formConfigI18n.addForm.schoolClassDialog.title
          }
          saveButtonLabel={
            formConfigI18n.addForm.schoolClassDialog.saveButtonLabel
          }
          schoolClassButton={formConfigI18n.addForm.schoolClassButton}
          name={formConfigI18n.addForm.name}
          registrationCode={formConfigI18n.addForm.registrationCode}
          resetUniqueCode={formConfigI18n.editForm.resetUniqueCode}
          currentStudent={currentStudent}
          isEnabledFormEditAdmin={!isEnabledFormEdit}
          loading={loading}
          editHandler={updateHandler}
          show={toggleFormEdit}
        />
        <>
          {loading ? (
            <CustomSpinner />
          ) : error ? (
            <NotFound
              title="Oops!"
              message="Ocorreu um erro, tente recarregar a página"
              image="/images/notfound.png"
              redirect="/app/content"
            />
          ) : (
            <div>
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={"medium"}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    headCells={headCells}
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={student.length}
                  />
                  <TableBody>
                    {stableSort(student, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={0}
                            key={row.id}
                            selected={isItemSelected}
                            className={
                              row.registrationCode === registrationCode &&
                              rowEnable
                                ? classes.rowEnable
                                : ""
                            }
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                onClick={(event) => handleClick(event, row)}
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                              <IconButton
                                aria-label="edit"
                                onClick={(event) => editHandler(row)}
                              >
                                <EditIcon color="secondary" />
                              </IconButton>
                            </TableCell>
                            <TableCell padding="none">
                              {row.nameStudent}
                            </TableCell>
                            <TableCell align="left">
                              {row.enrollmentStudent}
                            </TableCell>
                            <TableCell align="left">
                              {formatUniqueCode(row.uniqueCode)}
                            </TableCell>
                            <TableCell align="left">
                              {row.periodo}-{row.serie}-{row.turma}
                            </TableCell>
                            <TableCell align="left">
                              {row.active === 0 ? (
                                <CloseIcon color="primary" />
                              ) : (
                                <CheckIcon color="primary" />
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box display="flex" p={1}>
                <Box p={1} flexGrow={1}>
                  <TablePagination
                    rowsPerPageOptions={[10, 25]}
                    component="div"
                    count={student.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Box>
                <Box p={1} flexGrow={1}>
                  <RecordChangeButtons
                    singleDeleteButton={formConfigI18n.singleDeleteButton}
                    singleDeleteMultipleButton={
                      formConfigI18n.singleDeleteMultipleButton
                    }
                    itensSelected={selected}
                    handlerSingle={deleteHandler}
                    handlerMultiple={deleteMultiple}
                    icon={<DeleteIcon />}
                  />
                </Box>
              </Box>
            </div>
          )}
        </>
      </Container>
    </Layout>
  );
};

export { RegisterStudent };
