import ApiService from "../services/ApiService";

// Action types
const CLIENT_LIST_REQUEST = "CLIENT_LIST_REQUEST";
const CLIENT_LIST_SUCCESS = "CLIENT_LIST_SUCCESS";
const CLIENT_LIST_FAIL = "CLIENT_LIST_FAIL";

const CLIENT_ADD_REQUEST = "CLIENT_ADD_REQUEST";
const CLIENT_ADD_SUCCESS = "CLIENT_ADD_SUCCESS";
const CLIENT_ADD_FAIL = "CLIENT_ADD_FAIL";

const CLIENT_UPDATE_REQUEST = "CLIENT_UPDATE_REQUEST";
const CLIENT_UPDATE_SUCCESS = "CLIENT_UPDATE_SUCCESS";
const CLIENT_UPDATE_FAIL = "CLIENT_UPDATE_FAIL";

const CLIENT_DELETE_REQUEST = "CLIENT_DELETE_REQUEST";
const CLIENT_DELETE_SUCCESS = "CLIENT_DELETE_SUCCESS";
const CLIENT_DELETE_FAIL = "CLIENT_DELETE_FAIL";

// Reducer
export const clientReducer = (state = { client: [] }, action) => {
  switch (action.type) {
    case CLIENT_LIST_REQUEST:
      return { loading: true, client: [] };
    case CLIENT_LIST_SUCCESS:
      return { loading: false, client: action.payload };
    case CLIENT_LIST_FAIL:
      return { loading: false, client: [], error: action.payload };
    case CLIENT_ADD_REQUEST:
      return { loading: true, client: [] };
    case CLIENT_ADD_SUCCESS:
      return { loading: false, client: [action.payload] };
    case CLIENT_ADD_FAIL:
      return { loading: false, client: [], error: action.payload };
    case CLIENT_UPDATE_REQUEST:
      return { loading: true, client: [] };
    case CLIENT_UPDATE_SUCCESS:
      return { loading: false, client: [action.payload] };
    case CLIENT_UPDATE_FAIL:
      return { loading: false, client: [], error: action.payload };
    case CLIENT_DELETE_REQUEST:
      return { loading: true, client: [] };
    case CLIENT_DELETE_SUCCESS:
      return { loading: false, client: action.payload };
    case CLIENT_DELETE_FAIL:
      return { loading: false, client: [], error: action.payload };
    default:
      return state;
  }
};

// Action Creators
export const listClients = () => async (dispatch) => {
  try {
    dispatch({ type: CLIENT_LIST_REQUEST });

    const { data } = await ApiService.get(`/users/clients`);

    dispatch({
      type: CLIENT_LIST_SUCCESS,
      payload: data.clients,
    });
  } catch (error) {
    dispatch({
      type: CLIENT_LIST_FAIL,
      payload: error,
    });
  }
};

export const addClient =
  (name, email, cnpj, fantasyName, expireContract, contractType) =>
  async (dispatch) => {
    try {
      dispatch({ type: CLIENT_ADD_REQUEST });

      const user = {
        name,
        email,
        cnpjClient: cnpj,
        nameFantasyClient: fantasyName,
        dateContractValidity: expireContract,
        contractType,
      };

      const { data } = await ApiService.post(`/users/client`, user);

      dispatch({
        type: CLIENT_ADD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CLIENT_ADD_FAIL,
        payload: error,
      });
    }
  };

export const updateClient =
  (id, name, email, cnpj, fantasyName, expireContract, contractType) =>
  async (dispatch) => {
    try {
      dispatch({ type: CLIENT_UPDATE_REQUEST });

      const user = {
        name,
        email,
        cnpjClient: cnpj,
        nameFantasyClient: fantasyName,
        dateContractValidity: expireContract,
        contractType,
      };

      const { data } = await ApiService.put(`/users/client/${id}`, user);

      dispatch({
        type: CLIENT_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CLIENT_UPDATE_FAIL,
        payload: error,
      });
    }
  };

export const deleteClient = (client) => async (dispatch) => {
  try {
    dispatch({ type: CLIENT_DELETE_REQUEST });

    const { data } = await ApiService.delete(`/users/client/${client.id}`);

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
