import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "1rem",
    minHeight: "100vh",
    background: theme.palette.background.paper,
  },
  title: {
    flexGrow: 1,
    color: theme.palette.text.primary,
    fontWeight: 700,
  },
}));

export default function Layout(Component) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Container maxWidth={false} className={classes.container}>
      <Typography
        className={classes.title}
        color="primary"
        variant="h4"
        component="h1"
      >
        {t(Component.title)}
      </Typography>
      <Typography variant="body2" component="p" gutterBottom>
        {t(Component.description)}
      </Typography>
      <Grid>{Component.children}</Grid>
    </Container>
  );
}
