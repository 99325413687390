import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { DatePicker } from "@material-ui/pickers";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Slide from "@material-ui/core/Slide";
import MenuItem from "@material-ui/core/MenuItem";
import ReactHookFormSelect from "../../../components/common/form/ReactHookFormSelect";
import ReactHookFormRadio from "../../../components/common/form/ReactHookFormRadio";
import { filterSchema } from "./schema/filter";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: 100 + theme.spacing(2),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
  },
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
  },
}));

const Filter = (props) => {
  const classes = useStyles();
  const { addHandler, loading, setIsEnabledFilter, show, translateValidation } =
    props;
  const { register, handleSubmit, errors, reset, control } = useForm({
    resolver: yupResolver(filterSchema()),
  });

  const onsubmit = (data) => {
    reset();
    addHandler(data);
  };
  return (
    <>
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Modal
            aria-labelledby="filter-modal"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={show}
            onClose={() => setIsEnabledFilter((prev) => !prev)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Slide direction="left" in={show} mountOnEnter unmountOnExit>
              <form fullWidth onSubmit={handleSubmit(onsubmit)} noValidate>
                <div className={classes.paperModal}>
                  <Typography variant="h5" component="h2" gutterBottom>
                    {translateValidation.title}
                  </Typography>

                  <ReactHookFormSelect
                    inputRef={register}
                    error={errors.period && true}
                    // helperText={
                    //   errors.period &&
                    //   formConfigI18n.addForm.period.requiredErrorMsg
                    // }
                    id="period"
                    name="period"
                    // label={formConfigI18n.addForm.period.label}
                    label="Por assunto"
                    style={{ width: "100%" }}
                    control={control}
                    defaultValue="1"
                  >
                    <MenuItem value="1">Assunto 1</MenuItem>
                    <MenuItem value="2">Assunto 2</MenuItem>
                    <MenuItem value="3">Assunto 3</MenuItem>
                    <MenuItem value="4">Assunto 4</MenuItem>
                    <MenuItem value="5">Assunto 5</MenuItem>
                  </ReactHookFormSelect>

                  <ReactHookFormSelect
                    inputRef={register}
                    error={errors.period && true}
                    // helperText={
                    //   errors.period &&
                    //   formConfigI18n.addForm.period.requiredErrorMsg
                    // }
                    id="period"
                    name="period"
                    // label={formConfigI18n.addForm.period.label}
                    label="Por conteúdo"
                    style={{ width: "100%" }}
                    control={control}
                    defaultValue="1"
                  >
                    <MenuItem value="1">Conteúdo 1</MenuItem>
                    <MenuItem value="2">Conteúdo 2</MenuItem>
                    <MenuItem value="3">Conteúdo 3</MenuItem>
                    <MenuItem value="4">Conteúdo 4</MenuItem>
                    <MenuItem value="5">Conteúdo 5</MenuItem>
                  </ReactHookFormSelect>

                  <ReactHookFormRadio
                    inputRef={register}
                    error={errors.contractType && true}
                    helperText={
                      errors.contractType && errors.contractType.message
                    }
                    id="contractType"
                    name="contractType"
                    // label={translateValidation.contractType.label}
                    label="Por conclusão"
                    style={{ width: "100%" }}
                    control={control}
                    defaultValue="1"
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Sim"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="Não"
                    />
                  </ReactHookFormRadio>

                  <Typography variant="h6" component="h3" gutterBottom>
                    Por período de conclusão
                  </Typography>

                  <DatePicker
                    inputRef={register}
                    id="expireContract"
                    name="expireContract"
                    views={["year", "month", "date"]}
                    //label={translateValidation.expireContract.label}
                    // helperText={
                    //   translateValidation.expireContract.label.requiredErrorMsg
                    // }
                    label="Do dia"
                    disablePast
                    openTo="year"
                    format="DD/MM/YYYY"
                    // value={expireContract}
                    // onChange={setExpireContract}
                    style={{ width: "100%", "max-width": "100%" }}
                  />

                  <DatePicker
                    inputRef={register}
                    id="expireContract"
                    name="expireContract"
                    views={["year", "month", "date"]}
                    //label={translateValidation.expireContract.label}
                    // helperText={
                    //   translateValidation.expireContract.label.requiredErrorMsg
                    // }
                    label="Até o dia"
                    disablePast
                    openTo="year"
                    format="DD/MM/YYYY"
                    // value={expireContract}
                    // onChange={setExpireContract}
                    style={{ width: "100%", "max-width": "100%" }}
                  />

                  <ReactHookFormSelect
                    inputRef={register}
                    error={errors.period && true}
                    // helperText={
                    //   errors.period &&
                    //   formConfigI18n.addForm.period.requiredErrorMsg
                    // }
                    id="period"
                    name="period"
                    // label={formConfigI18n.addForm.period.label}
                    label="Por tempo de interação"
                    style={{ width: "100%" }}
                    control={control}
                    defaultValue="1"
                  >
                    <MenuItem value="1">Tempo 1</MenuItem>
                    <MenuItem value="2">Tempo 2</MenuItem>
                    <MenuItem value="3">Tempo 3</MenuItem>
                    <MenuItem value="4">Tempo 4</MenuItem>
                    <MenuItem value="5">Tempo 5</MenuItem>
                  </ReactHookFormSelect>

                  <ReactHookFormSelect
                    inputRef={register}
                    error={errors.period && true}
                    // helperText={
                    //   errors.period &&
                    //   formConfigI18n.addForm.period.requiredErrorMsg
                    // }
                    id="period"
                    name="period"
                    // label={formConfigI18n.addForm.period.label}
                    label="Por turma"
                    style={{ width: "100%" }}
                    control={control}
                    defaultValue="1"
                  >
                    <MenuItem value="1">Turma 1</MenuItem>
                    <MenuItem value="2">Turma 2</MenuItem>
                    <MenuItem value="3">Turma 3</MenuItem>
                    <MenuItem value="4">Turma 4</MenuItem>
                    <MenuItem value="5">Turma 5</MenuItem>
                  </ReactHookFormSelect>

                  <ReactHookFormSelect
                    inputRef={register}
                    error={errors.period && true}
                    // helperText={
                    //   errors.period &&
                    //   formConfigI18n.addForm.period.requiredErrorMsg
                    // }
                    id="period"
                    name="period"
                    // label={formConfigI18n.addForm.period.label}
                    label="Por série"
                    style={{ width: "100%" }}
                    control={control}
                    defaultValue="1"
                  >
                    <MenuItem value="1">Série 1</MenuItem>
                    <MenuItem value="2">Série 2</MenuItem>
                    <MenuItem value="3">Série 3</MenuItem>
                    <MenuItem value="4">Série 4</MenuItem>
                    <MenuItem value="5">Série 5</MenuItem>
                  </ReactHookFormSelect>

                  <ReactHookFormSelect
                    inputRef={register}
                    error={errors.period && true}
                    // helperText={
                    //   errors.period &&
                    //   formConfigI18n.addForm.period.requiredErrorMsg
                    // }
                    id="period"
                    name="period"
                    // label={formConfigI18n.addForm.period.label}
                    label="Por nível de escolaridade"
                    style={{ width: "100%" }}
                    control={control}
                    defaultValue="1"
                  >
                    <MenuItem value="1">Nível 1</MenuItem>
                    <MenuItem value="2">Nível 2</MenuItem>
                    <MenuItem value="3">Nível 3</MenuItem>
                    <MenuItem value="4">Nível 4</MenuItem>
                    <MenuItem value="5">Nível 5</MenuItem>
                  </ReactHookFormSelect>

                  <div className={classes.buttons}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      disabled={loading}
                      onClick={show}
                    >
                      {/* {translateValidation.cancelButton} */}
                      Limpar filtros
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={loading}
                    >
                      {/* {translateValidation.confirmButton} */}
                      Aplicar
                    </Button>
                  </div>
                </div>
              </form>
            </Slide>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Filter;
