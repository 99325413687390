import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Layout from "../../../components/common/hoc/Layout";
import CustomSpinner from "../../../components/common/Spinner/CustomSpinner";
import NotFound from "../../../components/common/notFound/NotFound";
import Carousel from "./Carousel";
import ShortCut from "./ShortCut";

import {
  PlayIcon,
  BookOpenIcon,
  MyActivitiesIcon,
} from "../../../icons/CustomIcons";
import IconButton from "@material-ui/core/IconButton";

import InteractionDetails from "../../InteractionsLibrary/components/interactionDetails";
import InteractionCard from "../../InteractionsLibrary/components/card";
import { myActivities, interactionsRelated } from "../../../store";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  title: {
    fontWeight: 700,
  },
  carousel: {
    marginTop: theme.spacing(3),
  },
  carouselButton: {
    background: theme.palette.custom.color_1,
  },
}));

const ControlCarouselButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
    "&:disabled": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
}))(IconButton);

const Teacher = ({ match }) => {
  const { t } = useTranslation();
  const { keyword, pageNumber } = useParams();
  const dispatch = useDispatch();
  const myActivitiesPublished = useSelector((state) => state.myActivities);
  const recommendedInteractions = useSelector(
    (state) => state.interactionsRelated
  );
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const [carouselIndexOne, setCarouselIndexOne] = useState(0);
  const [carouselIndexTwo, setCarouselIndexTwo] = useState(0);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [currentId, setCurrentId] = useState(0);

  const showInteractionDetails = (id) => {
    setCurrentId(id);
    setOpenPopUp(true);
  };

  useEffect(() => {
    dispatch(interactionsRelated(5));
    dispatch(myActivities(5));
  }, [dispatch, pageNumber, keyword]);

  const shortCutItens = [
    {
      title: t("dashboard.teacher.shortcut_1"),
      icon: <BookOpenIcon color="primary" fontSize="large" />,
      link: "/app/publish",
      boxColor: theme.palette.custom.color_1,
      textColor: theme.palette.common.white,
      avatarColor: theme.palette.custom.color_3,
    },
    {
      title: t("dashboard.teacher.shortcut_2"),
      icon: <PlayIcon color="primary" fontSize="large" />,
      link: "/app/faq",
      boxColor: theme.palette.custom.color_2,
      textColor: theme.palette.common.white,
      avatarColor: theme.palette.custom.color_1,
    },
    {
      title: t("dashboard.teacher.shortcut_3"),
      icon: <MyActivitiesIcon color="primary" fontSize="large" />,
      link: "/app/my-activities",
      boxColor: theme.palette.custom.color_3,
      textColor: theme.palette.common.white,
      avatarColor: theme.palette.custom.color_1,
    },
  ];

  return (
    <>
      {recommendedInteractions.loading ? (
        <CustomSpinner />
      ) : recommendedInteractions.error ? (
        <NotFound
          title="Oops!"
          message="Ocorreu um erro, tente recarregar a página"
          image="/images/notfound.png"
        />
      ) : recommendedInteractions.interactions.length === 0 ? (
        <NotFound
          title="Sem conteúdos de realidade aumentada"
          message="Volte em breve!"
          image="/images/notfound.png"
        />
      ) : (
        <Container maxWidth={false}>
          <Box className={classes.root}>
            <Grid container spacing={3}>
              {!isMobile ? (
                <Carousel
                  items={shortCutItens}
                  cellSpacing={20}
                  cardType="ShortCut"
                  externalControl={false}
                />
              ) : (
                <Grid item xs={12} sm={12}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    width="100%"
                    flexWrap="nowrap"
                  >
                    <Box
                      maxWidth="268px"
                      width="33.33%"
                      style={{
                        height: "8.875rem",
                        marginRight: 10,
                      }}
                    >
                      <ShortCut
                        title={t("dashboard.teacher.shortcut_1")}
                        icon={<BookOpenIcon color="primary" fontSize="large" />}
                        link="/app/publish"
                        boxColor={theme.palette.custom.color_1}
                        textColor={theme.palette.common.white}
                        avatarColor={theme.palette.custom.color_3}
                      />
                    </Box>
                    <Box
                      maxWidth="268px"
                      width="33.33%"
                      style={{
                        height: "8.875",
                        marginRight: 10,
                      }}
                    >
                      <ShortCut
                        title={t("dashboard.teacher.shortcut_2")}
                        icon={<PlayIcon color="primary" fontSize="large" />}
                        link="/app/support/faq"
                        boxColor={theme.palette.custom.color_2}
                        textColor={theme.palette.common.white}
                        avatarColor={theme.palette.custom.color_1}
                      />
                    </Box>
                    <Box
                      maxWidth="268px"
                      width="33.33%"
                      style={{
                        height: "8.875",
                        marginRight: 10,
                      }}
                    >
                      <ShortCut
                        title={t("dashboard.teacher.shortcut_3")}
                        icon={
                          <MyActivitiesIcon color="primary" fontSize="large" />
                        }
                        link="/app/my-activities"
                        boxColor={theme.palette.custom.color_3}
                        textColor={theme.palette.common.white}
                        avatarColor={theme.palette.custom.color_1}
                      />
                    </Box>
                  </Box>
                </Grid>
              )}

              {/* <Grid item xs={12} sm={12}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  flexWrap="nowrap"
                  m={1}
                >
                  <Typography
                    className={classes.title}
                    variant={isMobile ? 'h6' : 'body1'}
                    gutterBottom
                  >
                    Última publicação da semana
                  </Typography>

                  <Box
                    display="flex"
                    justifyContent="space-around"
                    alignContent="center"
                    flexWrap="nowrap"
                    style={{ marginLeft: '20px' }}
                  >
                    <ControlCarouselButton
                      onClick={() =>
                        carouselIndexOne >= 1 &&
                        carouselIndexOne <
                          myActivitiesPublished.interactions.length - 1 &&
                        setCarouselIndexOne(carouselIndexOne - 1)
                      }
                      disabled={carouselIndexOne <= 0}
                    >
                      <ArrowBackIosIcon fontSize="small" />
                    </ControlCarouselButton>

                    <ControlCarouselButton
                      onClick={() =>
                        carouselIndexOne >= 0 &&
                        carouselIndexOne <
                          myActivitiesPublished.interactions.length - 3 &&
                        setCarouselIndexOne(carouselIndexOne + 1)
                      }
                      disabled={
                        carouselIndexOne >=
                        myActivitiesPublished.interactions.length - 3
                      }
                      className={classes.margin}
                      style={{ marginLeft: '10px' }}
                    >
                      <ArrowForwardIosIcon fontSize="small" />
                    </ControlCarouselButton>
                  </Box>
                </Box>

                <Box className={classes.carousel}>
                  <Carousel
                    setCarouselIndex={setCarouselIndexOne}
                    slideIndex={carouselIndexOne}
                    items={myActivitiesPublished.interactions}
                    cellSpacing={20}
                    cardType="Carouselcard"
                  />
                </Box>
              </Grid> */}

              <Grid item xs={12} sm={12}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  flexWrap="nowrap"
                  m={1}
                >
                  <Box>
                    <Typography
                      className={classes.title}
                      variant={isMobile ? "h6" : "body1"}
                      gutterBottom
                    >
                      {t("dashboard.teacher.recommendations")}
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="space-around"
                    alignContent="center"
                    flexWrap="nowrap"
                    style={{ marginLeft: "20px" }}
                  >
                    <ControlCarouselButton
                      onClick={() =>
                        carouselIndexTwo >= 1 &&
                        carouselIndexTwo <
                          recommendedInteractions.interactions.length &&
                        setCarouselIndexTwo(carouselIndexTwo - 1)
                      }
                      disabled={carouselIndexTwo <= 0}
                    >
                      <ArrowBackIosIcon fontSize="small" />
                    </ControlCarouselButton>

                    <ControlCarouselButton
                      onClick={() =>
                        carouselIndexTwo >= 0 &&
                        carouselIndexTwo <=
                          recommendedInteractions.interactions.length &&
                        setCarouselIndexTwo(carouselIndexTwo + 1)
                      }
                      disabled={
                        carouselIndexTwo >=
                        recommendedInteractions.interactions.length - 1
                      }
                      style={{ marginLeft: "10px" }}
                    >
                      <ArrowForwardIosIcon fontSize="small" />
                    </ControlCarouselButton>
                  </Box>
                </Box>

                <Box className={classes.carousel}>
                  <Carousel
                    externalControl={true}
                    setCarouselIndex={setCarouselIndexTwo}
                    slideIndex={carouselIndexTwo}
                    items={recommendedInteractions.interactions}
                    cardType="InteractionCard"
                    cellSpacing={20}
                    showInteractionDetails={showInteractionDetails}
                  >
                    <InteractionCard />
                  </Carousel>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      )}
      {openPopUp && (
        <InteractionDetails
          openPopUp={openPopUp}
          setOpenPopUp={setOpenPopUp}
          interactionId={currentId}
        />
      )}
    </>
  );
};

Teacher.propTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string,
  link: PropTypes.string.isRequired,
};

export default Teacher;
