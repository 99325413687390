import ApiService from "../../../services/ApiService";

// Action types
const MY_ACTIVITIES_LIST_REQUEST = "MY_ACTIVITIES_LIST_REQUEST";
const MY_ACTIVITIES_LIST_SUCCESS = "MY_ACTIVITIES_LIST_SUCCESS";
const MY_ACTIVITIES_LIST_FAIL = "MY_ACTIVITIES_LIST_FAIL";

const INTERACTION_RELATED_REQUEST = "INTERACTION_RELATED_REQUEST";
const INTERACTION_RELATED_SUCCESS = "INTERACTION_RELATED_SUCCESS";
const INTERACTION_RELATED_FAIL = "INTERACTION_RELATED_FAIL";

const STUDENTS_ACTIVE_REQUEST = "STUDENTS_ACTIVE_REQUEST";
const STUDENTS_ACTIVE_SUCCESS = "STUDENTS_ACTIVE_SUCCESS";
const STUDENTS_ACTIVE_FAIL = "STUDENTS_ACTIVE_FAIL";

const TEACHERS_ACTIVE_REQUEST = "TEACHERS_ACTIVE_REQUEST";
const TEACHERS_ACTIVE_SUCCESS = "TEACHERS_ACTIVE_SUCCESS";
const TEACHERS_ACTIVE_FAIL = "TEACHERS_ACTIVE_FAIL";

// Reducer
export const myActivitiesReducer = (state = { interactions: [] }, action) => {
  switch (action.type) {
    case MY_ACTIVITIES_LIST_REQUEST:
      return { loading: true, interactions: [] };
    case MY_ACTIVITIES_LIST_SUCCESS:
      return { loading: false, interactions: action.payload };
    case MY_ACTIVITIES_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const interactionsRelatedReducer = (
  state = { interactions: [] },
  action
) => {
  switch (action.type) {
    case INTERACTION_RELATED_REQUEST:
      return { loading: true, interactions: [] };
    case INTERACTION_RELATED_SUCCESS:
      return { loading: false, interactions: action.payload };
    case INTERACTION_RELATED_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const studentsActiveReducer = (
  state = { studentsActive: 0 },
  action
) => {
  switch (action.type) {
    case STUDENTS_ACTIVE_REQUEST:
      return { loading: true, studentsActive: 0 };
    case STUDENTS_ACTIVE_SUCCESS:
      const studentsActive = action.payload[0].total_student_actived;
      return { loading: false, studentsActive: studentsActive };
    case STUDENTS_ACTIVE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const teacherssActiveReducer = (
  state = { teachersActive: 0 },
  action
) => {
  switch (action.type) {
    case TEACHERS_ACTIVE_REQUEST:
      return { loading: true, teachersActive: 0 };
    case TEACHERS_ACTIVE_SUCCESS:
      return {
        loading: false,
        teachersActive: action.payload[0].total_professor_actived,
      };
    case TEACHERS_ACTIVE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Action Creators
export const myActivities =
  (limit = 10) =>
  async (dispatch) => {
    dispatch({ type: MY_ACTIVITIES_LIST_REQUEST });

    let result;
    try {
      result = await ApiService.get(`/lecture?limitOffset=${limit}`);
      const { data } = result;

      dispatch({
        type: MY_ACTIVITIES_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MY_ACTIVITIES_LIST_FAIL,
        payload: error,
      });
    }
  };

export const interactionsRelated =
  (limit = 10) =>
  async (dispatch) => {
    try {
      dispatch({ type: INTERACTION_RELATED_REQUEST });

      const { data } = await ApiService.get(
        `/content/library?limit=${limit}&order=DESC`
      );
      dispatch({
        type: INTERACTION_RELATED_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INTERACTION_RELATED_FAIL,
        payload: error,
      });
    }
  };

export const studentsActive = () => async (dispatch) => {
  try {
    dispatch({ type: STUDENTS_ACTIVE_REQUEST });

    const { data } = await ApiService.get("users/countStudentActived");
    dispatch({
      type: STUDENTS_ACTIVE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STUDENTS_ACTIVE_FAIL,
      payload: error,
    });
  }
};

export const teachersActive = () => async (dispatch) => {
  try {
    dispatch({ type: TEACHERS_ACTIVE_REQUEST });

    const { data } = await ApiService.get("/users/countProfessorActived");
    dispatch({
      type: TEACHERS_ACTIVE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TEACHERS_ACTIVE_FAIL,
      payload: error,
    });
  }
};
