// Reducer
export const uiReducer = (state = {}, action) => {
  switch (action.type) {
    case "SNACKBAR_SUCCESS":
      return {
        ...state,
        successSnackbarOpen: true,
        snackbarConfig: action.config,
      };
    case "SNACKBAR_CLEAR":
      return {
        ...state,
        successSnackbarOpen: false,
        errorSnackbarOpen: false,
        infoSnackbarOpen: false,
      };
    default:
      return state;
  }
};

// Action Creators

/*
   message: string
   severity: 'success' | 'info' | 'warning' | 'error';
   autoHideDuration: number
 */
export const showSnackbar = (message) => {
  const config = {
    message: message.message ? message.message : "",
    severity: message.severity ? message.severity : "info",
    autoHideDuration: message.autoHideDuration
      ? message.autoHideDuration
      : 1500,
  };
  return (dispatch) => {
    dispatch({ type: "SNACKBAR_SUCCESS", config });
  };
};

export const clearSnackbar = () => {
  return (dispatch) => {
    dispatch({ type: "SNACKBAR_CLEAR" });
  };
};
