import ApiService from "../services/ApiService";

// Action types
const TEACHER_LIST_REQUEST = "TEACHER_LIST_REQUEST";
const TEACHER_LIST_SUCCESS = "TEACHER_LIST_SUCCESS";
const TEACHER_LIST_FAIL = "TEACHER_LIST_FAIL";

const TEACHER_ADD_REQUEST = "TEACHER_ADD_REQUEST";
const TEACHER_ADD_SUCCESS = "TEACHER_ADD_SUCCESS";
const TEACHER_ADD_FAIL = "TEACHER_ADD_FAIL";

const TEACHER_UPDATE_REQUEST = "TEACHER_UPDATE_REQUEST";
const TEACHER_UPDATE_SUCCESS = "TEACHER_UPDATE_SUCCESS";
const TEACHER_UPDATE_FAIL = "TEACHER_UPDATE_FAIL";

const TEACHER_DELETE_REQUEST = "TEACHER_DELETE_REQUEST";
const TEACHER_DELETE_SUCCESS = "TEACHER_DELETE_SUCCESS";
const TEACHER_DELETE_FAIL = "TEACHER_DELETE_FAIL";

// Reducer
export const teacherReducer = (state = { teacher: [] }, action) => {
  switch (action.type) {
    case TEACHER_LIST_REQUEST:
      return { loading: true, teacher: [] };
    case TEACHER_LIST_SUCCESS:
      return { loading: false, teacher: action.payload };
    case TEACHER_LIST_FAIL:
      return { loading: false, teacher: [], error: action.payload };
    case TEACHER_ADD_REQUEST:
      return { loading: true, teacher: [] };
    case TEACHER_ADD_SUCCESS:
      return { loading: false, teacher: [action.payload] };
    case TEACHER_ADD_FAIL:
      return { loading: false, teacher: [], error: action.payload };
    case TEACHER_UPDATE_REQUEST:
      return { loading: true, teacher: [] };
    case TEACHER_UPDATE_SUCCESS:
      return { loading: false, teacher: [action.payload] };
    case TEACHER_UPDATE_FAIL:
      return { loading: false, teacher: [], error: action.payload };
    case TEACHER_DELETE_REQUEST:
      return { loading: true, teacher: [] };
    case TEACHER_DELETE_SUCCESS:
      return { loading: false, teacher: action.payload };
    case TEACHER_DELETE_FAIL:
      return { loading: false, teacher: [], error: action.payload };
    default:
      return state;
  }
};

// Action Creators
export const listTeacher = () => async (dispatch) => {
  try {
    dispatch({ type: TEACHER_LIST_REQUEST });

    const userInfo = localStorage.getItem("userInfo");
    const { jwt_token } = JSON.parse(userInfo);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`,
      },
    };

    const { data } = await ApiService.get(
      `${process.env.REACT_APP_BASE_API}/users/professors`,
      config
    );
    dispatch({
      type: TEACHER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TEACHER_LIST_FAIL,
      payload: error,
    });
  }
};

export const addTeacher =
  (name, email, registerCode, contentCreator) => async (dispatch) => {
    try {
      dispatch({ type: TEACHER_ADD_REQUEST });

      const user = {
        nameProfessor: name,
        email,
        professorEnrollment: registerCode,
        isContentCreator: contentCreator,
      };

      const userInfo = localStorage.getItem("userInfo");
      const { jwt_token } = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt_token}`,
        },
      };

      const { data } = await ApiService.post(
        `${process.env.REACT_APP_BASE_API}/users/professor`,
        user,
        config
      );
      dispatch({
        type: TEACHER_ADD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TEACHER_ADD_FAIL,
        payload: error,
      });
    }
  };

export const updateTeacher =
  (id, name, email, registerCode, contentCreator) => async (dispatch) => {
    try {
      dispatch({ type: TEACHER_UPDATE_REQUEST });

      const user = {
        nameProfessor: name,
        email,
        professorEnrollment: registerCode,
        isContentCreator: contentCreator,
        schoolLevel: "sas", //TODO remove this
      };

      const userInfo = localStorage.getItem("userInfo");
      const { jwt_token } = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt_token}`,
        },
      };

      const { data } = await ApiService.put(
        `${process.env.REACT_APP_BASE_API}/users/professor/${id}`,
        user,
        config
      );
      dispatch({
        type: TEACHER_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TEACHER_UPDATE_FAIL,
        payload: error,
      });
    }
  };

export const deleteTeacher = (teacher) => async (dispatch) => {
  try {
    dispatch({ type: TEACHER_DELETE_REQUEST });

    const userInfo = localStorage.getItem("userInfo");
    const { jwt_token } = JSON.parse(userInfo);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt_token}`,
      },
    };
    const { data } = await ApiService.delete(
      `${process.env.REACT_APP_BASE_API}/users/professor/${teacher.id}`,
      config
    );
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
