import React, { useEffect, useState, useRef } from "react";
import { Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  listInteractions,
  searchInteractions,
  filterInteractionsByTags,
  searchKeywordRequest,
} from "../../store/interactions";
import Layout from "../../components/common/hoc/Layout";
import Search from "./components/search";
import InteractionCard from "./components/card";
import NotFound from "../../components/common/notFound/NotFound";
import CarouselFilter from "./components/CarouselFilter";
import InteractionDetails from "./components/interactionDetails";
import CustomSpinner from "./../../components/common/Spinner/CustomSpinner";

const useStyles = makeStyles((theme) => ({
  itensList: {
    marginTop: theme.spacing(2),
    overflowX: "clip",
    overflowY: "scroll",
    "-ms-overflow-style": "none",
    "-webkit-scrollbar-width": "thin",
    "scrollbar-width": "thin",
  },
}));

const InteractionsLibrary = ({ match }) => {
  const classes = useStyles();
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const interactionList = useSelector((state) => state.interactionList);
  const { loading, error, interactions, hasMore, lastTypeOfSearch, keyword } =
    interactionList;
  const [openPopUp, setOpenPopUp] = useState(false);
  const [currentId, setCurrentId] = useState(0);
  const [offset, setOffset] = useState(0);
  const [tagsToSearch, setTagsToSearch] = useState([]);
  const prevOffset = usePrevious(offset);
  const prevTagsToSearch = usePrevious(tagsToSearch);
  const [keywordToSearch, setKeywordToSearch] = useState("");
  const prevKeywordToSearch = usePrevious(keywordToSearch);
  const LIMIT = 10;

  const search = (
    <div className={classes.search}>
      <Route render={({ history }) => <Search history={history} />} />
    </div>
  );

  const showInteractionDetails = (id) => {
    setCurrentId(id);
    setOpenPopUp(true);
  };

  const resetInteractionsRedux = React.useCallback(async () => {
    setOffset(0);
    return await dispatch({
      type: "INTERACTION_RESET",
    });
  }, [dispatch]);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const paginate = () => {
    const newOffset = offset + LIMIT;
    setOffset(newOffset);
  };

  const searchDefault = React.useCallback(async () => {
    if (lastTypeOfSearch === "default") {
      if (prevOffset !== offset) {
        await dispatch(listInteractions(offset, LIMIT));
      }
    }
  }, [dispatch, lastTypeOfSearch, offset, prevOffset]);

  const searchKeyword = React.useCallback(
    async (currentKeyword) => {
      if (currentKeyword) {
        if (currentKeyword !== keywordToSearch) {
          setKeywordToSearch(currentKeyword);
          await resetInteractionsRedux();
          await dispatch(searchInteractions(0, LIMIT, currentKeyword));
        } else if (prevOffset !== offset) {
          await dispatch(searchInteractions(offset, LIMIT, keywordToSearch));
        }
      } else if (!currentKeyword) {
        await resetInteractionsRedux();
        await dispatch(listInteractions(0, LIMIT));
      }
    },

    [dispatch, keywordToSearch, offset, prevOffset, resetInteractionsRedux]
  );

  const setTags = async (tags) => {
    if (tags && tags.length > 0) {
      setTagsToSearch(tags);
      if (lastTypeOfSearch !== "tag") {
        setKeywordToSearch();
        await resetInteractionsRedux();
        await dispatch(filterInteractionsByTags(0, LIMIT, tags));
      } else if (lastTypeOfSearch === "tag" && prevTagsToSearch !== tags) {
        await resetInteractionsRedux();
        await dispatch(filterInteractionsByTags(0, LIMIT, tags));
      }
    } else if (lastTypeOfSearch === "tag") {
      await dispatch(searchKeywordRequest());
      await resetInteractionsRedux();
      //await dispatch(listInteractions(0, LIMIT));
    }
  };

  const searchTags = React.useCallback(async () => {
    if (prevOffset !== offset && prevTagsToSearch === tagsToSearch) {
      await dispatch(filterInteractionsByTags(offset, LIMIT, tagsToSearch));
    }
  }, [dispatch, offset, prevOffset, prevTagsToSearch, tagsToSearch]);

  useEffect(() => {
    if (lastTypeOfSearch === "tag") {
      searchTags();
    } else if (lastTypeOfSearch === "keyword") {
      searchKeyword(keyword);
    } else {
      searchDefault();
    }
  }, [
    keyword,
    keywordToSearch,
    lastTypeOfSearch,
    offset,
    prevKeywordToSearch,
    prevOffset,
    searchDefault,
    searchKeyword,
    searchTags,
  ]);

  return (
    <Layout
      title={t("InteractionsLibrary.title")}
      description={t("InteractionsLibrary.description")}
      search={<Search className={classes.search} />}
    >
      <CarouselFilter onSelectedTags={setTags} />
      {error ? (
        <NotFound
          title="Oops!"
          message="Ocorreu um erro, tente recarregar a página"
          //image="/images/notfound.png"
        />
      ) : interactions && interactions.length === 0 && !loading ? (
        <NotFound
          title="Oops!"
          message="Não foram encontradas interações de realidade aumentada"
          //image="/images/notfound.png"
        />
      ) : (
        <>
          <InfiniteScroll
            style={{ overflow: "hidden" }}
            dataLength={(interactions && interactions.length) || 0}
            next={() => paginate()}
            hasMore={hasMore ? true : false}
            loader={
              loading && <LinearProgress style={{ margin: "1em 0 1em" }} />
            }
          >
            <Grid container spacing={2}>
              {interactions &&
                interactions.map((interaction) => (
                  <Grid
                    key={interaction.idContent}
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={2}
                    xl={2}
                  >
                    <InteractionCard
                      interaction={interaction}
                      setOpenPopUp={setOpenPopUp}
                      showInteractionDetails={showInteractionDetails}
                    />
                  </Grid>
                ))}
              {loading && <CustomSpinner />}
            </Grid>
          </InfiniteScroll>

          {openPopUp && (
            <InteractionDetails
              openPopUp={openPopUp}
              setOpenPopUp={setOpenPopUp}
              interactionId={currentId}
            />
          )}
        </>
      )}
    </Layout>
  );
};

export { InteractionsLibrary };
